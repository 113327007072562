import React, {useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Input from "../UI/Input";
import Button from "../UI/Button";
import CountryState from "./CountryState";
import useDataForm from "../../Hooks/useDataForm";
import {useRecoilState, useRecoilValue} from "recoil";
import {onBoardingAtom, userAtom, userModeAtom} from "../../Recoil/atoms";
import {useHistory} from "react-router-dom";

export default function GU3({back, next, title, subtitle, updateUser, handleFinish}){
    let history = useHistory();
    const user = useRecoilValue(userAtom);
    const [mode, setMode] = useRecoilState(userModeAtom);

    const [form, setForm] = useState({
        country: user.country ? user.country : '',
        state: user.state ? user.state : '',
        address1: user.address1 ? user.address1 : '',
        address2: user.address2 ? user.address2 : ''
    });

    const updateData = ({target: {name, value}}) => {
        setForm({...form, [name] : value});
    }

    const save= async ()=>{
        await updateUser(form);
        history.push("/onboarding/b/6")
    }

    const handleFinishButton= async () => {
        await updateUser(form);
        setMode("patient");
        history.push("/dashboard");
    }

    return(
        <div className="pb-2">

            <AuthHeader back={back ? back : "/onboarding/g/2"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "General User"}
                    subtitle={subtitle ? subtitle : "Step 3/3"}
                />

                <CountryState data={form} updateData={updateData}/>

                <Input name ="address1" label="Address 1" className="mb" onChange={updateData}
                       defaultValue={form.address1 || ''}
                />
                <Input name ="address2" label="Address 2" className="mb" onChange={updateData}
                       defaultValue={form.address2 || ''}
                />
                <Button
                    type="button" to={next ? next : "/dashboard"}
                    onClick = { title? save : handleFinishButton }
                    className="w-100">
                    {title ? 'Continue' : 'Finish'}
                </Button>

            </div>

        </div>
    )
}
