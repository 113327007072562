import React, {useEffect, useRef, useState} from "react";
import Input from "../UI/Input";
import SearchResults from "./SearchResults";

export default function HeaderSearch(){

    const [results, setResults] = useState(false);
    const checkFocus = (e) => {
        if(e.target.value.length > 2){
            setResults(true)
        }
        else{
            setResults(false)
        }
    }

    const checkBlur = (e) => {
        console.log(e.target)
    }

    /**
     * Reference container div for clicking outside
     * @type {React.MutableRefObject<null>}
     */
    const ref = useRef(null);


    /**
     * Trigger clicking outside
     * @type {React.MutableRefObject<null>}
     */
    const closeSearchResults = () => {
        setResults(false)
    }

    /**
     * Listen when a click outside occurs to close the search results
     * @type {React.MutableRefObject<null>}
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeSearchResults && closeSearchResults();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ closeSearchResults ]);


    return(
        <div className="flex f-medium t-white align-center relative" ref={ref}>
            <Input
                placeholder="Search"
                onChange={checkFocus}
                onBlur={checkBlur}
                onFocus={checkFocus}
            />
            {results &&
                <div className="top-4 left-0 white p br-half z-2000 absolute sh-black10 b b-grey200">
                    <SearchResults closeSearchResults={closeSearchResults} />
                </div>
            }
        </div>
    )
}