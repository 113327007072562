import React, {useEffect, useState} from "react";
import Popup from "../../UI/Popup";
import {useRecoilState, useRecoilValue} from "recoil";
import {enterDataDocId, enterDataPopAtom, enterDataSearchState} from "../../../Recoil/atoms";
import SearchEnterData from "./SearchEnterData";
import FormEnterData from "./FormEnterData";
import IconX from "../../UI/Icons/IconX";
import {quat} from "../../../Recoil/selectors";

export default function EnterData({ switchView }) {

	const enterData = useRecoilValue(enterDataPopAtom);
	const [quater, setQuater] = useRecoilState(quat);
	const [search, setSearch] = useRecoilState(enterDataSearchState);
	const [id, setId] = useRecoilState(enterDataDocId);

	return (
		<div className="white p mhor-auto max-w-800px w-100 br">
			<div className="flex mb align-center p6  p-half br-quarter">
				<div className="grows flex align-center cursor-move">
					<h3 className="h6 grows t-white">Enter Data</h3>
					<a onClick={() => setQuater(false)}>
						<IconX />
					</a>
				</div>
			</div>
			{search ?
				<SearchEnterData setSearch={setSearch} setId={setId} />
				:
				<FormEnterData setSearch={setSearch} id={id} />
			}
		</div>
	);
}
