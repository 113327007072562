import React, {useEffect, useState} from "react";
import Button from "../../UI/Button";
import {getDocs} from "../../../Actions/DashboardApi";
import {useRecoilState} from "recoil";
import {userAtom} from "../../../Recoil/atoms";
import FileSharedUsers from "./FileSharedUsers";

export default function MyFiles({openDoc}) {

	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState([]);
	const [user, setUser] = useRecoilState(userAtom);

	useEffect(()=>{
		if(loading){
			getEntries();
		}
	},[loading]);

	const getEntries = async () => {
		const e = await getDocs({'filters[owner]' : user.id});
		setEntries(e);
	}

	return (
		<table>
			<thead>
				<tr className="b-bottom b-grey200">
					<th className="pver">
						DOC. ID
					</th>
					<th>
						TITLE
					</th>
					<th>
						Owner
					</th>
					<th>
						User Access
					</th>
					<th>
						Preview
					</th>
				</tr>
			</thead>
			<tbody>
				{entries.map(({id, attributes : {title, accesses}})=>{
					return(
						<tr key={`doc-${id}`} className="b-bottom b-grey200">
							<td>
								{id}
							</td>
							<td>
								{title}
							</td>
							<td className="pver">
								<div title={`${user.firstName} ${user.lastName}`} className="mr-quarter cursor-pointer w-3rem h-3rem p5 t-white br-50 flex align-center justify-center">
									{user.firstName?.charAt(0)}{user.lastName?.charAt(0)}
								</div>
							</td>
							<td>
								<FileSharedUsers file={id} access={accesses?.data} />
							</td>
							<td>
								<Button color="p4" onClick={()=> openDoc (id)}>
									Open
								</Button>
							</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}