import React, {useEffect, useState} from "react";
import Input from "../UI/Input";
import Button from "../UI/Button";
import {Link, useParams} from "react-router-dom";
import Error from "../UI/Error";
import {forgotPassword, getMe, loginPX6, resetPassword} from "../../Actions/UserApi";
import AuthHeader from "./AuthHeader";
import AuthTitle from "./AuthTitle";


export default function ResetPassword(){
    let { token } = useParams();
    const [newUser, setNewUser] = useState({"password" : "", "passwordConfirmation": "", code: token});
    const [requested, setRequested] = useState(false);
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const reset = async () => {
        setError("");
        try {
            const u = await resetPassword(newUser);
            setRequested(true);
        }
        catch (e){
            console.log("CATCHED", e.response);
            setError(e?.response?.data?.error?.message)
        }
    }


    return(
        <div className="max-w-600px mhor-auto pb-4">
            <AuthHeader />
            <AuthTitle title="Reset Password" subtitle="Please setup a new password below" />

            {!requested ?
                <div>
                    <Input label="Password" type="password" className="mb" name="password" value={newUser.password}
                           onChange={updateUser}/>
                    <Input label="New Password" type="password" className="mb" name="passwordConfirmation"
                           value={newUser.passwordConfirmation} onChange={updateUser}/>
                    <Button className="w-100" onClick={reset}>
                        Reset Password
                    </Button>
                </div>
                :
                <div>
                    <h4 className="center mb">Password reset success!</h4>
                    <Button type="Link" to="/login" className="w-100">
                        Login Now
                    </Button>
                </div>
            }

            <Error msg={error} className="t-red center pver f-small" />

            <div className="center f-titillium f-1rem mt-2">
                Back to <Link to="/login" className="t-p3 f-underline">Login</Link>
            </div>
        </div>
    )
}
