import React from "react";

export default function IconResize({className, color}){
    return(
        <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 0H18V14V18H14H0V14H14V0ZM25 3H21V21H3V25H21H25V21V3Z" className={color ? color : className} />
        </svg>

    )
}
