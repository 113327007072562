import React, {useState} from "react";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import {findDocs} from "../../../Actions/DashboardApi";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";

export default function SearchDoc({setSearch, setId, requestAccess}) {

	const [keyword, setKeyword] = useState("");
	const me = useRecoilValue(userAtom);
	const [results, setResults] = useState([]);
	const [noRes, setNoRes] = useState(false);

	const edit = (id) => {
		setId(id);
		setSearch(false);
	}

	const req = (id) => {
		setNoRes(false);
		setResults([]);
		requestAccess(id);
	}

	const search = async () => {
		setNoRes(false);
		let d = await findDocs(keyword);
		if(d.length > 0){
			setNoRes(false);
		}
		else {
			setNoRes(true);
		}
		setResults(d);
	}


	return (
		<div>
			<div className="flex align-end justify-center mb-2">
				<Input label="Search Documents" className="mr-half min-w-200px" value={keyword} onFocus={() => setNoRes(false)} onChange={(e)=>setKeyword(e.currentTarget.value)} />
				<Button className="min-w-100px" color='p4' onClick={search}>
					Search
				</Button>
			</div>

			<div className="mb-2 max-w-400px mhor-auto">
				{results.map(({id, attributes: {title, owner}})=> {
					return(
						<div key={`result${id}`} className="flex align-center pver b-bottom b-grey200">
							<h5 className="grows pr">
								{title}
							</h5>
							<div className="right mr">
								<strong className="f-small f-uppercase">
									Owner
								</strong>
								<br />
								{owner?.data?.attributes?.firstName} {owner?.data?.attributes?.lastName}
							</div>
							{setId &&
								<>
									<Button color="p5" className="mr-half" onClick={() => edit(id)}>
										View
									</Button>
									<Button color="p4" onClick={() => edit(id)}>
										Edit
									</Button>
								</>
							}
							{requestAccess && me.id !== owner?.data?.id &&
								<>
									<Button color="p5" className="mr-half" onClick={() => req(id)}>
										Request Access
									</Button>
								</>
							}
						</div>
					)
				})}
				{noRes &&
					<h5 className="center">
						No results for this search query "{keyword}"
					</h5>
				}

			</div>
		</div>
	)
}