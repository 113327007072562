import React, {useEffect, useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";
import Input from "../UI/Input";
import Select from "../UI/Select";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {useHistory} from "react-router-dom";

export default function GU2({back, next, title, subtitle, updateUser}){

    let history = useHistory();
    const user = useRecoilValue(userAtom);

    const [form, setForm] = useState({
        gender: user.gender ? user.gender : ''
    });

    const [userSaved, setUserSaved] = useState(false);

    const updateData = ({target: {name, value}}) => {
        setForm({...form, [name] : value});
    }

    const save= async ()=>{
        await updateUser(form);
        setUserSaved(true);

    }

    useEffect(()=>{
        if(userSaved) {
            if(user?.type?.id === 1) {
                history.push("/onboarding/b/4")
            }
            else{
                history.push("/onboarding/g/3");
            }
        }
    },[userSaved])

    return(
        <div className="pb-2">

            <AuthHeader back={back ? back : "/onboarding/g/1"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "General User"}
                    subtitle={subtitle ? subtitle : "Step 2/3"}
                />

                <Select
                    selected={form.gender}
                    value={form.gender}
                    name='gender'
                    onChange={updateData}
                    options={[
                        {label: "Female"},
                        {label: "Male"},
                        {label: "Non-Binary"}
                    ]}
                    label="Gender"
                    className="mb"
                />

                <Input type='date' label="Birthday" name='birthdate' placeholder="MM/DD/YYYY" className="mb" />
                <Input label="Phone" name = 'phone' placeholder="Phone" className="mb" />


                <div className="pver mt b-top b-grey300">
                    <h5 className='mb'>Social media</h5>
                    <div className="grid2 col-gap row-gap">
                        <Input label="Twitter" name='twitter' className="mb" />
                        <Input label="Facebook" name = 'facebook' className="mb" />
                        <Input label="Instagram" name = 'instagram' className="mb" />
                        <Input label="LinkedIn" name='linkedin' className="mb" />
                    </div>
                </div>
                <Button type="Button" onClick={save} className="w-100">
                    Continue
                </Button>

            </div>

        </div>
    )
}
