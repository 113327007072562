import React from "react";
import EnterData from "../DataModules/EnterData/EnterData";
import {useRecoilValue} from "recoil";
import {quatActive} from "../../Recoil/selectors";
import AppointmentForm from "../DataModules/Appointments/AppointmentForm";
import FileShare from "../DataModules/FileShare/FileShare";
import Notes from "../DataModules/Notes/Notes";
import AppointmentFormEdit from "../DataModules/Appointments/AppointmentFormEdit";

export default function Quaternary() {

	const qActive = useRecoilValue(quatActive);

	return (
		<div className={`grey200 p flex col-gap h-header`}>
			<div className={`sh-10-black10 br-half grows min-h-100px white flex direction-column max-w-40vw min-w-40vw overflow-y-auto`}>
				{!qActive &&
					<EnterData />
				}
				{qActive === "appForm" &&
					<AppointmentForm />
				}
				{qActive === "appFormEdit" &&
					<AppointmentFormEdit />
				}
				{qActive === "shareFile" &&
					<FileShare />
				}
				{qActive === "notes" &&
					<Notes />
				}
			</div>
		</div>
	)
}