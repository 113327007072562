import React, {useState} from "react";
import Button from "../UI/Button";
import Popup from "../UI/Popup";

export default function AuthMethods(){

    const [popup, setPopup] = useState(false);

    const switchView = ()=>{
        setPopup(!popup);
    }

    return(
        <>
            <Button className="w-100 grey800" onClick={switchView}>
                SIGNUP WITH FACE ID / TOUICH ID
            </Button>
            <p className="mver center f-1rem f-uppercase">
                Or Connect With
            </p>

            <div className="grid2 col-gap-half row-gap-half">
                <Button className="grey300" onClick={switchView}>
                    Google
                </Button>
                <Button className="grey300" onClick={switchView}>
                    Facebook
                </Button>
                <Button className="grey300" onClick={switchView}>
                    Twitter
                </Button>
                <Button className="grey300" onClick={switchView}>
                    SMS
                </Button>
            </div>

            {popup &&
            <Popup switchView={switchView}>
                <div className="white br max-w-600px mhor-auto p-2 center">
                    <h4>Info</h4>
                    <p className="f-1rem mver">
                        Everyone will be assigned a unique ID # which will be tied to the biometrics. All data in the system is Blockchain powered.
                    </p>

                    <Button className="w-100 grey800" onClick={switchView}>
                        Continue
                    </Button>

                </div>
            </Popup>
            }

        </>
    )
}
