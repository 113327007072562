import React, {useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";
import UserTypes from "../UI/UserTypes";
import {useHistory} from "react-router-dom";

export default function UserType({updateUser}){

    let history = useHistory();
    const [type, setType] = useState();

    const save= async ()=>{
        await updateUser({type})
        if(type === 1){
            history.push("/onboarding/b/1")
        }
        else{
            history.push("/onboarding/g/1")
        }
    }


    return(
        <div className="pb-2">

            <AuthHeader />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title="Select User Type"
                    subtitle="Select if you are a Patient or a Healthcare Professional"
                />

                <UserTypes typeId={type} setTypeId={setType} />
                <Button
                    color={`${!type ? "grey300" : "p3"}`}
                    className="w-100"
                    type="button"
                    onClick={save}>
                    Continue
                </Button>
            </div>
        </div>
    )
}
