import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {dragDropAtom, modulesAtom} from "../../../Recoil/atoms";
import {secCountSelector, tertCountSelector} from "../../../Recoil/selectors";
import IconResize from "../../UI/Icons/IconResize";

/**
 * Box Secondary
 * @param title
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function BoxSecondary({title, children}){

    const [modules, setModules] = useRecoilState(modulesAtom);
    const [dragAndDrop, setDragAndDrop] = useRecoilState(dragDropAtom);
    const index = modules.findIndex((m)=>m.name === title);

    /**
     * Drag Actions
     * @param event
     */
    const onDragStart = (event) => {

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: index,
            isDragging: true,
            originalOrder: modules
        });

    }
    const onDragOver = (event) => {

        event.preventDefault();

        let newModules = [...modules];

        const draggedFrom = dragAndDrop.draggedFrom;

        const draggedTo = Number(event.currentTarget.dataset.position);

        const itemDragged = newModules[draggedFrom];
        const remainingItems = newModules.filter((item, index) => index !== draggedFrom);

        newModules = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ];


        if (draggedTo !== dragAndDrop.draggedTo){
            setDragAndDrop({
                ...dragAndDrop,
                updatedOrder: newModules,
                draggedTo: draggedTo
            })
        }

    }
    const onDrop = (event) => {

        setModules(dragAndDrop.updatedOrder);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false
        });
    }
    const onDragLeave = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null
        });
    }


    /**
     * Resizable actions
     */
    const secCount = useRecoilValue(secCountSelector);
    const tertCount = useRecoilValue(tertCountSelector);
    const [resizing, setResizing] = useState("none");
    const [box, setBox] = useState({
        ow : 0,
        oh : 0,
        mx : 0,
        my : 0
    });
    const [div] = useState(title);

    const cb = (e) => {
        const element = document.getElementById(div);
        e.preventDefault()
        let b = {
            ow : element.offsetWidth,
            oh : element.offsetHeight,
            mx : e.pageX,
            my : e.pageY
        }
        setBox(b);
    }
    const md = (e) => {
        const element = document.getElementById(div);
        e.preventDefault()
        setResizing("start");
        let b = {
            ow : element.offsetWidth,
            oh : element.offsetHeight,
            mx : e.pageX,
            my : e.pageY
        }
        setBox(b);
        window.addEventListener('mouseup', stopResize)
        window.addEventListener('mousemove', resize)
    }
    const resize = (e) => {
        setResizing("resizing");
        const element = document.getElementById(div);

        const width = box.ow + (e.pageX - box.mx);
        const height = box.oh + (e.pageY - box.my);

        element.style.width = width + 'px'
        element.style.height = height + 'px'

    }
    const stopResize = () => {
        setResizing("end");
        window.removeEventListener('mousemove', resize);
    }

    useEffect(()=>{

        if(resizing === "end"){
            const element = document.getElementById(div);
            let pos = '';
            if(element.offsetWidth < box.ow){
                pos = 'primary'
            }
            else {
                pos = 'tertiary'
            }

            element.style.width = 'auto'
            element.style.height = 'auto'
            setResizing("none");
            makeSecondary(pos);
        }
    },[resizing])

    const makeSecondary = (pos) => {

        let mod = [...modules];
        const umod = mod.map(m => {

            if (m.name===title) {
                return {...m, position : pos }
            }
            else {
                if(1 + secCount + (tertCount * 3) > 4) {
                    return {...m, position : 'primary'}
                }
                else{
                    return m
                }

            }

        });
        setModules(umod);
    }


    return(
        <div
            id={title}
            className={`max-w-20vw min-w-20vw relative overflow-y-auto p sh-10-black10 br-half grows min-h-100px white flex direction-column ${dragAndDrop && dragAndDrop.draggedTo=== index ? "b-dashed b-p4" : "b-2 b-white"}`}
            draggable
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            data-position={index}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
        >
            {children}

            <div
                className="absolute bottom-0 right-0 cursor-nwse-resize w-1rem h-1rem m-quarter flex op-30 hover:op-70"
                onMouseDown={md}
                onMouseOver={cb}>
                <IconResize color="black" className="w-1rem h-1rem" />
            </div>
        </div>
    )
}
