import axios from "axios";

const url = process.env.REACT_APP_PX6_API_URL+'api/';
export const px6Api = axios.create({
	baseURL: `${url}`,
	withCredentials:true
});

export const px6ApiFiles = axios.create({
	baseURL: `${url}`,
	withCredentials:true,
	headers:{
		'Content-Type': 'multipart/form-data'
	}
});

export const environmentSettings = async() => {
	try{
		const res = await px6Api.get(
			`environment-settings`,
		);
		return res;
	}
	catch (e){
		throw e.response;
	}

}