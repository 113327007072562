import React from "react";

export default function IconBack({color, className, width, height}){
    return(
        <svg className={className} width={width || "40"} height={height || "40"} viewBox="0 0 40 40" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" className={color ? color : "p3"} />
            <path d="M20 10L10 20L20 30" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M11 20L30 20" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>

    )
}
