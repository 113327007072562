import React, {useEffect, useState} from "react";
import Button from "../../UI/Button";
import {getDataBlocks} from "../../../Actions/DashboardApi";
import EditDataBlocks from "./EditDataBlocks";
import NewDataBlocks from "./NewDataBlocks";

export default function DataBlocks({doc}) {

	const [addBlockForm, setAddBlockForm] = useState(false);
	const [loading, setLoading] = useState(true);
	const [blocks, setBlocks] = useState([]);
	useEffect(()=> {
		if(loading){
			getEntries();
		}
	}, [loading]);

	const getEntries = async () => {
	  	let b = await getDataBlocks({'filters[doc]': doc});
	  	setBlocks(b);
	  	setLoading(false);
	}

	return (
		<>
			{loading ?
				<>Loading...</>
				:

				(blocks.map(({id, attributes}) => {
						return (
							<EditDataBlocks doc={doc} id={id} attributes={attributes} setLoading={setLoading}/>
						)
					}
				))
			}

			{addBlockForm ?
				<NewDataBlocks doc={doc} setAddBlockForm={setAddBlockForm} setLoading={setLoading} />
			:
				<div className="b-grey200 b-bottom pver">
					<Button color="grey800" onClick={()=>setAddBlockForm(true)}>
						+ Add Data Block
					</Button>
				</div>
			}

		</>
	);
}
