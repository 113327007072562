import React from "react";
import {useRecoilState} from "recoil";
import {modulesAtom} from "../../../Recoil/atoms";
import BoxPrimary from "./BoxPrimary";
import BoxSecondary from "./BoxSecondary";
import BoxTertiary from "./BoxTertiary";

export default function ModuleBox({title, children}){

    const [modules, setModules] = useRecoilState(modulesAtom);
    const index = modules.findIndex((m)=>m.name === title);

    /**
     * Define width in Design for module
     */
    const m = modules.filter((mod) => mod.name === title);
    let width = "";
    if(m.length > 0){
        switch (m[0].position){
            case 'secondary' :
                width = "w-20vw max-w-20vw min-w-20vws"
                break;
            case 'tertiary' :
                width = "w-40vw max-w-40vw"
                break;
        };
    }


    /**
     * Render
     */
    if(modules[index].position === "primary"){
        return(
            <BoxPrimary title={title} children={children} />
        )
    }
    else if(modules[index].position === "secondary"){
        return (
            <BoxSecondary title={title} children={children} />
        )

    }
    else if(modules[index].position === "tertiary"){
        return (
            <BoxTertiary title={title} children={children} />
        )

    }
    else{
        return(
            <div
                id={title}
                className={`p sh-10-black10 br-half grows min-h-100px white ${width} flex direction-column`}>
                {width}
                {children}
            </div>
        )

    }

}
