import React from 'react'
import IconDrag from "../../UI/Icons/IconDrag";
import {useRecoilState, useRecoilValue} from "recoil";
import {dragTableAtom, notePopAtom, noteRecipientAtom, userModeAtom, videoPopAtom} from "../../../Recoil/atoms";
import {deptAppointments, selectedPatient} from "../../../Recoil/roomAtoms";
import UserActions from "../UserActions";

export default function AppointmentsRow({
    id,
    actions,
    attributes: {
        time,
        date,
        user : {
            data : {
                attributes: {
                    firstName,
                    lastName
                }
            }
        },
        professional,
        room
    }
}) {

    const [dragTable, setDragTable] = useRecoilState(dragTableAtom);
    const mode = useRecoilValue(userModeAtom);
    const entries = useRecoilValue(deptAppointments);
    const [selected, setSelected] = useRecoilState(selectedPatient);
    const [notePop, setNotePop] = useRecoilState(notePopAtom);
    const [videoPop, setVideoPop] = useRecoilState(videoPopAtom);
    const [noteRecipient, setNoteRecipient] = useRecoilState(noteRecipientAtom);

    const addNote = (patient) => {
        setNotePop(true);
        setNoteRecipient(`${firstName} ${lastName}`);
    }

    const startVideo = () => {
        setVideoPop(true);
        setNoteRecipient(`${firstName} ${lastName}`);
    }



    const onDragStart = () =>{
        setDragTable({...dragTable, draggedFromId : id});
    }

    const onDragLeave = () => {
        setDragTable({
            ...dragTable,
            draggedToState : null
        });
    };

    // useEffect(()=>{
    //     console.log(dragTable);
    // },[dragTable]);

    const selectPatient = () => {
        let patient = entries.find((e) => e.id === id);
        setSelected(patient);
    }

    return (
        <tr
            draggable
            onDragStart={onDragStart}
            onDragLeave={onDragLeave}
        >
            <td className="pver-half b-bottom b-grey200 pr f-1rem">
                <div className="flex align-center">
                    <IconDrag className="h-1rem mr-half " />
                    <a className="lh-100" onClick={selectPatient}>
                        <strong className={`block ${mode === 'professional' ? 't-p6' : 't-p3'}`}>
                            {firstName} {lastName}
                        </strong>
                        <span className="f-small"> {room?.data?.attributes?.title} </span>
                    </a>
                </div>
            </td>
            <td className="pver-half b-bottom b-grey200 pr">
                {professional?.data?.attributes?.pFirstName} {professional?.data?.attributes?.pLastName}
            </td>
            <td className="pver-half b-bottom b-grey200 pr">
                {time.substring(0, time.length - 3)} <br/>
                {date}
            </td>
            {actions &&
                <td className="pver-half b-bottom b-grey200 right">
                    <UserActions />
                </td>
            }
        </tr>
    )
}