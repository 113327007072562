import React from "react";

export default function IconNotice({className, color}){
	return(
		<svg width="48" height="40" className={className} viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 5.49806C0.05375 5.39063 0.11 5.28445 0.16 5.17451C0.5425 4.33504 1.17875 3.84909 2.115 3.81037C2.7075 3.78538 3.3025 3.79788 3.895 3.79663C9.6125 3.79663 15.33 3.79663 21.0487 3.79663H21.6437C21.1362 2.3975 21.3975 1.23823 22.635 0.409993C23.5225 -0.184636 24.6688 -0.115929 25.5338 0.518675C26.5163 1.23947 26.78 2.2326 26.3713 3.79663C26.5625 3.79663 26.7413 3.79663 26.92 3.79663C30.4813 3.79663 34.0437 3.79663 37.605 3.79663C37.73 3.79663 37.855 3.79413 37.98 3.79663C38.5375 3.81287 38.8263 4.06146 38.8175 4.51618C38.8088 4.96215 38.5075 5.20824 37.9475 5.20824C33.6825 5.20949 29.4175 5.20824 25.1525 5.20824C17.6388 5.20824 10.1237 5.20824 2.61 5.20824C1.62875 5.20824 1.40375 5.43435 1.40375 6.42124C1.40375 16.721 1.40375 27.0221 1.40375 37.3219C1.40375 38.3325 1.62 38.5474 2.6425 38.5474C16.8737 38.5474 31.1063 38.5474 45.3375 38.5474C46.3863 38.5474 46.595 38.3388 46.595 37.2932C46.595 27.0084 46.595 16.7235 46.595 6.43872C46.595 5.42686 46.3763 5.20824 45.36 5.20824C44.0638 5.20824 42.7663 5.20824 41.47 5.20824C41.0975 5.20824 40.7913 5.03585 40.7513 4.67608C40.725 4.43997 40.8625 4.15015 41.01 3.94528C41.0925 3.8316 41.3475 3.80287 41.5262 3.80162C42.9162 3.79038 44.3075 3.78413 45.6975 3.79912C46.8125 3.81037 47.5062 4.3138 47.905 5.34691C47.9262 5.40062 47.9675 5.4456 48 5.49556V38.2276C47.5375 39.548 46.5763 40.0015 45.215 39.999C31.0712 39.979 16.9288 39.979 2.785 39.999C1.42375 40.0015 0.46125 39.5493 0 38.2276C0 27.3182 0 16.4075 0 5.49806ZM24.0063 1.44934C23.35 1.44684 22.8425 1.93279 22.8275 2.58114C22.8125 3.23448 23.3425 3.78538 23.9925 3.79038C24.6388 3.79538 25.18 3.24697 25.1712 2.59488C25.1625 1.94278 24.6638 1.45309 24.0063 1.44934Z" className={color ? color : "black"}/>
			<path d="M43.3314 35.3355V8.4398H4.66635V35.3367C5.01885 35.3367 5.3576 35.3305 5.6951 35.338C6.18885 35.3492 6.5201 35.6341 6.5251 36.0425C6.5301 36.461 6.2176 36.7396 5.7101 36.7471C5.1326 36.7559 4.55385 36.7584 3.97635 36.7446C3.4876 36.7334 3.23635 36.4798 3.2051 35.9938C3.19635 35.8702 3.2026 35.744 3.2026 35.6203C3.2026 26.456 3.2026 17.2918 3.2026 8.12875C3.2026 7.18934 3.38885 7.00195 4.3151 7.00195C17.4389 7.00195 30.5639 7.00195 43.6876 7.00195C44.6114 7.00195 44.7989 7.19183 44.7989 8.13125C44.7989 17.3105 44.7989 26.491 44.7989 35.6703C44.7989 36.556 44.6089 36.7509 43.7414 36.7509C32.3039 36.7509 20.8676 36.7509 9.4301 36.7509C9.3051 36.7509 9.1801 36.7521 9.0551 36.7434C8.6451 36.7146 8.42135 36.471 8.4001 36.085C8.3776 35.6915 8.6076 35.4379 8.9901 35.3567C9.1701 35.318 9.36385 35.338 9.5501 35.338C20.5964 35.338 31.6426 35.338 42.6889 35.338H43.3314V35.3355Z" className={color ? color : "black"}/>
			<path d="M27.8449 18.7779C27.8449 16.9553 27.8449 15.1327 27.8449 13.3101C27.8449 12.628 28.0586 12.3932 28.7211 12.3919C31.7661 12.3869 34.8111 12.3857 37.8561 12.3919C38.4936 12.3932 38.7149 12.6343 38.7149 13.2801C38.7161 16.9403 38.7161 20.6018 38.7149 24.262C38.7149 24.8954 38.4774 25.1302 37.8374 25.1315C34.8074 25.134 31.7786 25.1327 28.7486 25.1315C28.0724 25.1315 27.8449 24.9079 27.8436 24.2445C27.8411 22.4219 27.8436 20.5993 27.8436 18.7767L27.8449 18.7779ZM37.2986 13.8435H29.2786V23.7011H37.2986V13.8435Z" className={color ? color : "black"}/>
			<path d="M19.2663 22.8807C20.7813 22.8807 22.2975 22.8782 23.8125 22.8819C24.4737 22.8844 24.72 23.123 24.72 23.7839C24.725 26.792 24.725 29.7989 24.72 32.807C24.72 33.4903 24.4663 33.7339 23.7838 33.7352C20.7688 33.7364 17.7538 33.7364 14.7388 33.7352C14.0588 33.7352 13.8087 33.4891 13.8075 32.8032C13.8037 29.8114 13.8037 26.8195 13.8075 23.8276C13.8075 23.1106 14.0412 22.8844 14.7662 22.8819C16.2662 22.8794 17.7662 22.8819 19.265 22.8819L19.2663 22.8807ZM23.2425 32.3048V24.3223H15.2775V32.3048H23.2425Z" className={color ? color : "black"}/>
			<path d="M17.1437 15.5043C17.1437 16.9846 17.1462 18.4649 17.1437 19.9453C17.1425 20.6473 16.8887 20.9022 16.1787 20.9034C13.1787 20.9072 10.18 20.9072 7.17996 20.9034C6.49496 20.9034 6.24621 20.6561 6.24496 19.9715C6.23996 16.9634 6.23996 13.9565 6.24496 10.9484C6.24496 10.2538 6.48371 10.0202 7.17746 10.019C10.1775 10.0152 13.1762 10.0165 16.1762 10.019C16.8912 10.019 17.1412 10.2676 17.1425 10.9696C17.145 12.4812 17.1425 13.9927 17.1425 15.5043H17.1437ZM15.6875 11.4968H7.69996V19.4331H15.6875V11.4968Z" className={color ? color : "black"}/>
		</svg>

	)
}