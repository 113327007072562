import {useRecoilState, useRecoilValue} from "recoil";
import {modulesAtom, searchResultsAtom} from "../../Recoil/atoms";
import IconPerson from "../UI/Icons/IconPerson";
import {secCountSelector, tertCountSelector} from "../../Recoil/selectors";


export default function SearchResults({padding, titleFont, closeSearchResults}){

    const results = useRecoilValue(searchResultsAtom)
    const [modules, setModules] = useRecoilState(modulesAtom);
    const secCount = useRecoilValue(secCountSelector);
    const tertCount = useRecoilValue(tertCountSelector);


    const switchPosition = (pos)=>{

        console.log("Switching position", pos)

        let mpos = 2;
        let mod = [...modules];

        /**
         * Secondary is 1/5, tertiary is 2/5. The sum of tertiary + secondary should not be greater than 4
         */
        if(mpos + secCount + (tertCount * 2) > 4){
            const tmod = mod.map(m => {

                if (m.position !== 'primary') {
                    return {...m, position : 'primary'}
                }
                else {
                    return m
                }
            });
            finalMap(tmod, pos);

        }
        else {
            finalMap(mod, pos);
        }
    }

    const finalMap = (mod, pos)=>{
        const umod = mod.map(m => {

            if (m.name === "Search") {
                return {...m, position : pos, hidden : false}
            }
            else {
                return m
            }

        });
        setModules(umod);
    }

    const sw = () => {
        switchPosition("tertiary");
        if(closeSearchResults) {
            closeSearchResults()
        }
    }

    return(
        <div className="t-black">
            {results.map(({id, title, type})=>{
                return(
                    <a key={id}
                         className="hover:grey100 block cursor-pointer"
                         onClick={sw}
                    >
                        <div className={`flex align-center ${padding ? padding : "pver"} b-bottom b-grey200`}>
                            {type==="user" &&
                            <div className="mr-half">
                                <IconPerson className="w-3rem h-3rem" color="grey500" />
                            </div>
                            }
                            <div>
                                <h4 className={`t-black ${titleFont ? titleFont : "h6"}`}>
                                    {title}
                                </h4>
                                <p className="f-uppercase t-p4 f-small">
                                    {type}
                                </p>
                            </div>
                        </div>

                    </a>
                )
            })}

        </div>
    )
}