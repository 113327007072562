import React, {useEffect, useState} from "react";
import Login from "../Components/Auth/Login";
import Signup from "../Components/Auth/Signup";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Tabs from "../Components/UI/Tabs";
import AuthTitle from "../Components/Auth/AuthTitle";
import AuthHeader from "../Components/Auth/AuthHeader";
import ForgotPassword from "../Components/Auth/ForgotPassword";

export default function Auth(){

    let history = useHistory();
    const jwt = window.localStorage.getItem('jwt');
    if (jwt){
        history.push("/dashboard");
    }

    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [tabs] = useState([
        {
            to : "/login",
            label : "Login"
        },
        {
            to : "/signup",
            label : "Signup"
        }
    ]);

    useEffect(()=>{
        if(loading){
            setLoading(false);
        }
    }, [loading])

    if(loading){
        return(
            <h2 className="center pver-6">
                Loading...
            </h2>
        )
    }

    else{
        return(
            <div>

                <AuthHeader />

                <div className="max-w-600px mhor-auto">
                    <AuthTitle
                        title="Welcome to PX6"
                        subtitle="Login to access your account or sign up to create a new account"
                    />
                    <Tabs className="mb-2" location={location.pathname} tabs={tabs} />
                </div>


                <Switch>
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                </Switch>
            </div>
        )
    }

}
