import React, {useEffect, useState} from "react";
import Input from "../UI/Input";
import Button from "../UI/Button";
import {Link, useHistory} from "react-router-dom";
import {getUser, login, refreshJWT, signup} from "../../Actions/Rest/auth";
import AuthMethods from "./AuthMethods";
import Error from "../UI/Error";
import {useSetRecoilState} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {getMe, signupPX6} from "../../Actions/UserApi";

export default function Signup(){
    const setUser=useSetRecoilState(userAtom);
    let history = useHistory();

    const [error, setError] = useState("");

    const [newUser, setNewUser] = useState(
        {
            "email": "",
            "password": "",
            "firstName": "",
            "lastName": ""
        }
    );
    const [loading, setLoading] = useState(true);

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const signupUser = async () => {
        try {
            let d= newUser;
            d.username = d.email;
            const u = await signupPX6(d);
            window.localStorage.setItem('jwt', u.jwt);
            const me = await getMe();
            setUser(me);
            history.push('/onboarding/user-type');
        }
        catch (e){
            console.log(e);
            setError(e?.response?.data?.error?.message)
            //history.push('/onboarding/user-type');
        }

    }

    return(
        <div className="max-w-600px mhor-auto pb-4">
            <div className="grid2 col-gap">
                <Input label="First Name" className="mb" name="firstName" value={newUser.firstName} onChange={updateUser} />
                <Input label="Last Name" className="mb" name="lastName" value={newUser.lastName} onChange={updateUser} />
            </div>
            <Input label="Email" className="mb" name="email" value={newUser.email} onChange={updateUser} />
            <Input label="Password" type="password" className="mb" name="password" value={newUser.password} onChange={updateUser} />
            <Button className="w-100" onClick={signupUser}>
                SIGNUP
            </Button>
            <Error msg={error} className="t-red center pver f-small" />
            <p className="mver center f-1rem f-uppercase">
                Or
            </p>

            <AuthMethods />

            <div className="center f-titillium f-1rem mt-2">
                Already have an account? <Link to="/login" className="t-p3 f-underline">Login</Link>
            </div>
        </div>
    )
}
