import React, {useEffect, useState} from "react";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";
import Select from "../../UI/Select";
import {getDoc, getFiles, saveDoc, saveSet, updateDoc} from "../../../Actions/DashboardApi";
import FormFile from "./FormFile";
import UsersList from "./UsersList";
import UserCircles from "./UserCircles";
import IconBack from "../../UI/Icons/IconBack";
import DataBlocks from "./DataBlocks";
import IconX from "../../UI/Icons/IconX";

const {io} = require("socket.io-client")

export default function FormEnterData({setSearch, id}) {

	const me = useRecoilValue(userAtom);
	const [options, setOptions] = useState([]);
	const [files, setFiles] = useState([]);
	const [file, setFile] = useState({});
	const [reviewer, setReviewer] = useState(false);
	const [reviewers, setReviewers] = useState([]);
	const [approver, setApprover] = useState(false);
	const [approvers, setApprovers] = useState([]);
	const [reader, setReader] = useState(false);
	const [readers, setReaders] = useState([]);
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [updateTimer, setUpdateTimer] = useState(false);
	const [updateAccessStatus, setUpdateAccessStatus] = useState(false);
	const [doc, setDoc] = useState({
		"title": "",
		"reference": "",
		"version": "",
		"number": "",
		"date": new Date(),
		"type": "",
		//"reviewer": [],
		//"approver": [],
		//"reader": [],
		"owner": me.id,
		"file": ""
	})

	const [socket, setSocket] = useState(null);

	//const url = "http://localhost:4000";
	const url = "https://px6-socket-server.herokuapp.com";

	//socket
	useEffect(()=> {
		if(socket === null) {
			setSocket(io.connect(url));
		}
		if(socket){
			socket.on("connect", () => {
				console.log("SocketConnected YES: ", socket.connected, socket.id);
			});

			socket.on('doc updated', function(msg) {
				console.log("Update received", msg);
				setRefresh(true);
			});
		}

		return () => {
			console.log("Should disconnect");
			socket?.disconnect();
		}
	}, [socket]);


	const updateForm = async () => {
		let d = await updateDoc(doc, id);
		setUpdateTimer(true);
		socket.emit('doc update', "yes");
	}

	const updateAccess = async () => {
		const r = reviewers.map(({id})=>id);
		const a = approvers.map(({id})=>id);
		const re = readers.map(({id})=>id);
		let d = await updateDoc({
			"reviewer": r,
			"approver": a,
			"reader": re,
		}, id);
		setUpdateTimer(true);
		setUpdateAccessStatus(false);
		socket.emit('doc update', "yes");
	}

	useEffect(()=> {
		if(updateAccessStatus) {
			updateAccess();
		}
	}, [updateAccessStatus])

	useEffect(()=> {
		if(updateTimer) {
			setTimeout(()=> setUpdateTimer(false), "3000");
		}
	}, [updateTimer])

	useEffect(() => {
		if(loading) {
			getOptions();
		}
	}, [loading])

	const loadEntry = async () => {
		console.log("Loading Entry");
		let entry = await getDoc(id);
		let e = {
			"title": entry?.attributes?.title,
			"reference": entry?.attributes?.reference,
			"version": entry?.attributes?.version,
			"number": entry?.attributes?.number,
			"date": new Date(entry?.attributes?.date),
			"type": entry?.attributes?.type,
			"file": entry?.attributes?.file?.data?.id
		}
		setDoc({...doc, ...e});
		setFile(entry?.attributes?.file?.data?.attributes);
		accessControl(entry);
	}

	const accessControl = (entry) => {

		console.log("Access control", entry);
		const rev = entry?.attributes?.reviewer?.data.map(({id, attributes})=>{
			return {id, firstName: attributes.firstName, lastName: attributes.lastName}
		});
		const apr = entry?.attributes?.approver?.data.map(({id, attributes})=>{
			return {id, firstName: attributes.firstName, lastName: attributes.lastName}
		});
		const rea = entry?.attributes?.reader?.data.map(({id, attributes})=>{
			return {id, firstName: attributes.firstName, lastName: attributes.lastName}
		});

		setReviewers(rev);
		setReaders(rea);
		setApprovers(apr);
		setUpdateAccessStatus(false);

	}

	const refreshEntry = async () => {
		await loadEntry();
		setRefresh(false);
	}

	useEffect(()=>{
		if(refresh) {
			console.log("Refreshing 1");
			refreshEntry();
		}
	}, [refresh]);

	const getOptions = async () => {
		let f = await getFiles();
		if(id){
			loadEntry();
		}
		let o = [];
		f.forEach(({id, attributes}) => {
			let op = {
				id,
				label: attributes.name
			};
			o.push({id, label: attributes.name});
		});
		setFiles(f);
		setOptions(o);

	}

	const changeText = (e) => {
		setDoc({...doc, [e.currentTarget.name]: e.currentTarget.value});
	}

	const changeSelect = (e) => {
		setDoc({...doc, [e.currentTarget.name]: e.currentTarget.value});
		updateForm();
	}

	useEffect(() => {
		if(doc.file){
			let f = files.filter((e) => {
				return e.id.toString() === doc.file
			});
			if(f.length > 0){
				setFile(f[0].attributes);
			};
		}

	}, [doc]);

	const action = (type, user) => {
		console.log("Type", type);
		if (type === "reviewer") {
			setReviewers([...reviewers, user]);
			setReviewer(false);
		}
		if (type === "reader") {
			setReaders([...readers, user]);
			setReader(false);
		}
		if (type === "approver") {
			setApprovers([...approvers, user]);
			setApprover(false);
		}
		setUpdateAccessStatus(true);

	}


	return (
		<div>

			<a
				onClick={()=>setSearch(true)}
				className="t-p4 f-bold flex align-center"
			>
				<IconBack color="p4" className="w-1rem h-1rem mr-half" /> Back to search
			</a>

			<div className="pver flex w-100 mb b-grey200 b-bottom">
				<div>
					<p className="f-uppercase bold">Professional connected</p>
					<h3>
						Professional {id}
					</h3>
				</div>
				<div className="grows"></div>
				<div>
					<p className="f-uppercase bold">Patient</p>
					<h3>
						Patient 1
					</h3>
				</div>
			</div>

			{/*<SocketsTest />*/}

			<div className="grid2 col-gap-2 b-grey200 b-bottom pver">
				<div>
					<Input label="Document Name" className="mb" value={doc.title} onChange={changeText} name="title" onBlur={updateForm} />
					<Input label="Document Reference" className="mb" value={doc.reference} onChange={changeText} name="reference" onBlur={updateForm} />
					<div className="grid3 col-gap">
						<Input label="Doc. Version" className="mb" value={doc.version} onChange={changeText} name="version" onBlur={updateForm}  />
						<Input label="Doc. Date" className="mb" placeholder="MM-DD-YYYY" value={doc.date} onChange={changeText} name="date" onBlur={updateForm} />
						<Input label="Doc. #" className="mb" value={doc.number} onChange={changeText} name="number" onBlur={updateForm} />
					</div>
					<Select
						label="Document Type / Category"
						className="mb"
						options={[
							{label: "category1"},{label: "category2"},{label: "category3"}
						]}
						value={doc.type} onChange={changeSelect} name="type"
					/>

				</div>


				<div>

					<Select label="Select file" className="mb" options={options} value={doc.file} onChange={changeText} name="file"  />

					{file?.name &&
						<FormFile file={file} />
					}

				</div>
			</div>

			<div className="b-grey200 b-bottom pver">
				<h5 className="mb">Document Access Control</h5>
				<div className="grid3">
					<div className="pr">
						<p className="f-bold mb-half">
							Reviewer <a className="f-underline t-p5" onClick={()=>setReviewer(!reviewer)}>(Add +)</a>
						</p>
						<div className="flex">
							<UserCircles users={reviewers} type={"rev"} />
						</div>
						{reviewer &&
							<UsersList title="reviewer" action={action} />
						}
					</div>

					<div className="pr">
						<p className="f-bold mb-half">
							Approver <a className="f-underline t-p5" onClick={()=>setApprover(!approver)}>(Add +)</a>

						</p>
						<div className="flex">
							<UserCircles users={approvers} type="app" />
						</div>
						{approver &&
							<UsersList title="approver" action={action} />
						}

					</div>

					<div>
						<p className="f-bold mb-half">
							Read  <a className="f-underline t-p5" onClick={()=>setReader(!reader)}>(Add +)</a>
						</p>
						<div className="flex">
							<UserCircles users={readers} type="rea" />
						</div>
						{reader &&
							<UsersList title="reader" action={action} />
						}
					</div>

				</div>
			</div>


			<div className="pver">
				<h5 className="mb">Data Blocks</h5>

				<DataBlocks doc={id} />

			</div>



			<div className="pver center">
				<Button color="p5" fontSize="f-large" height="h-4rem" className="min-w-200px" onClick={updateForm}>
					Save Document
				</Button>
			</div>

			{updateTimer &&
			<div className="p3 p br-half pver-half phor t-white fixed top-0 right-0 mt-6 mr sh-10-black50 f-medium">
				Document Updated
				<a onClick={()=>setUpdateTimer(false)}><IconX className="w-1rem h-1rem ml" /></a>
			</div>
			}

		</div>
	)
}