import React from "react";

export default function UserCircles({users, type}) {
	return (
		<div className="flex-wrap flex">
			{users.map(({id, firstName, lastName}, i)=> {
				return(
					<div key={`${type ? type : "u"}-${id}-${i}`} className="mr-quarter mb-quarter cursor-pointer w-3rem h-3rem p5 t-white br-50 flex align-center justify-center">
						{firstName?.charAt(0)} {lastName?.charAt(0)}
					</div>
				)
			})}
		</div>
	)
}