import React, {useState} from "react";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import {findDocs, saveDoc} from "../../../Actions/DashboardApi";
import IconX from "../../UI/Icons/IconX";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";
import SearchDoc from "./SearchDoc";

export default function SearchEnterData({setSearch, setId}) {

	const me = useRecoilValue(userAtom);
	const [newDoc, setNewDoc] = useState(false);
	const [doc, setDoc] = useState({
		"title": "",
		"owner": me.id
	});

	const edit = (id) => {
		setId(id);
		setSearch(false);
	}

	const createDoc = async () => {
		let d = await saveDoc(doc);
		edit(d.id);
	}

	return (
		<div>

			<SearchDoc setSearch={setSearch} setId={setId} />

			<div className="center pver-2">
				<p className="center bold">
					Or <a className="t-p5" onClick={()=>setNewDoc(true)}>Create a new document</a>
				</p>

			</div>

			{newDoc &&
			<div className="center p-2 b-grey300 b">
				<div className="flex">
					<h5 className="left bold f-uppercase grows">
						New Document
					</h5>
					<a  onClick={()=>setNewDoc(false)}>
						<IconX color="black" />
					</a>
				</div>


				<p className="left">
					Enter document name
				</p>
				<Input className="mver" placeholder="Document name" onChange={(e)=>setDoc({...doc, title: e.currentTarget.value})} />
				<Button className="mb-half" color='p5' onClick={createDoc}>
					CREATE NEW DOCUMENT
				</Button>
				<br />
				<Button color='p4' onClick={() => setNewDoc(false)}>
					Cancel
				</Button>
			</div>
			}
		</div>
	);
}