import React from "react";
import ModuleHead from "../Head/ModuleHead";
import ModuleComingSoon from "../ModuleComingSoon";
import ModuleBox from "../Box/ModuleBox";
import modu from "../../../Styles/Img/modules/clinic.jpg";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../../Recoil/atoms";
import GenericPrimary from "./GenericPrimary";

export default function Clinic (){

    const modules = useRecoilValue(modulesAtom);
    const mod = modules.filter(m => m.name === "Clinic");

    return(
        <ModuleBox title="Clinic">
            <ModuleHead title="Clinic" />
            {mod.length > 0
            && mod[0].position !== 'tertiary'
            && mod[0].position !== 'quaternary'
            &&
                <GenericPrimary />
            /*<img src={modu} alt="" />*/
            }
            <ModuleComingSoon name="Clinic" />
        </ModuleBox>
    )
}
