import {atom} from"recoil";

export const apiUrl= "https://demo-api.px6globalhealthhub.com/api/";
//export const apiUrl= "http://localhost:1337/api/"

export const userAtom = atom({
    key:'userAtom',
    default : undefined
})

export const dragDropAtom = atom({
    key : "dragDropAtom",
    default : {
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
        originalOrder: [],
        updatedOrder: []
    }
})

export const dragTableAtom = atom({
    key : "dragTableAtom",
    default : {
        draggedFromId: null,
        draggedTostatus: null
    }
})

export const userModeAtom = atom({
    key :"userModeAtom",
    default :"professional"
})

export const roomAtom = atom({
    key : "roomAtom",
    default : "Reception"
})

export const patientRoomAtom = atom({
    key : "patientRoomAtom",
    default : ""
})


export const modulesAtom = atom({
    key :"modulesAtom",
    default : [
        {
            name :"Scheduling",
            position :"primary",
            hidden: false
        },
        {
            name :"Notifications",
            position :"primary",
            hidden: false
        },
        {
            name :"Academy",
            position :"primary",
            hidden: false
        },
        {
            name :"InfoManagement",
            position :"primary",
            hidden: false
        },
        {
            name :"Store",
            position :"primary",
            hidden: false
        },
        {
            name :"Office",
            position :"primary",
            hidden: false
        },
        {
            name :"Clinic",
            position :"primary",
            hidden: false
        },
        {
            name :"Communication",
            position :"primary",
            hidden: false
        },
        {
            name :"Analytics",
            position :"primary",
            hidden: false
        },
        {
            name :"Ecommerce",
            position :"primary",
            hidden: false
        },
        {
            name :"Administration",
            position :"primary",
            hidden: false
        },
        {
            name :"Forum",
            position :"primary",
            hidden: false
        },
        {
            name :"Utilities",
            position :"primary",
            hidden: false
        },
        {
            name :"Search",
            position :"tertiary",
            hidden: true
        }
    ]
})


export const oneOnOnePatient = atom({
    key: "oneOnOnePatient",
    default: ""
})

export const selectedPatients = atom({
    key: "selectedPatients",
    default: 0
})


export const appointmentsAtom = atom({
    key:"appointmentsAtom",
    default : [
        {
            patient :"David Zapata",
            professional :"John Smith",
            department :"File Room",
            status :"Reception",
            date :"2021-11-11T08:00"
        },
        {
            patient :"Jerred Laurissa",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T09:00"
        },
        {
            patient :"Galen Kiaran",
            professional :"John Smith",
            department :"File Room",
            status :"Reception",
            date :"2021-11-11T09:30"
        },
        {
            patient :"Droan Mcguire",
            professional :"John Smith",
            department :"File Room",
            status :"Reception",
            date :"2021-11-11T10:00"
        },
        {
            patient :"Eliza Vaughn",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T10:30"
        },
        {
            patient :"Leonard Lana",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T11:00"
        },
        {
            patient :"Rhetta Vonda",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T12:00"
        },
        {
            patient :"Emmerson Gregg",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T13:00"
        },
        {
            patient :"Coleen Amilia",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T13:30"
        },
        {
            patient :"Bartholomew Tylor",
            professional :"John Smith",
            department :"File Room",
            status :"File Room",
            date :"2021-11-11T14:00"
        },
        {
            patient :"Alissa Hayleigh",
            professional :"John Smith",
            department :"File Room",
            status :"File Room",
            date :"2021-11-11T14:30"
        },
        {
            patient :"Talia Babs",
            professional :"John Smith",
            department :"File Room",
            status :"File Room",
            date :"2021-11-11T15:00"
        },
        {
            patient :"Warren Scarlett",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T15:30"
        },
        {
            patient :"Emmet Alana",
            professional :"John Smith",
            department :"File Room",
            status :"Waiting Room",
            date :"2021-11-11T15:45"
        },
        {
            patient :"Becky Curt",
            professional :"John Smith",
            department :"File Room",
            status :"Reception",
            date :"2021-11-11T16:00"
        }

    ]
})

export const ordersAtom = atom({
    key: "ordersAtom",
    default: [
        {
            user : "Sabah Dunlop",
            department : "Pediatrics",
            date : "09/08/2021",
            total : "$150.00"
        },
        {
            user : "Ultrasound, medical center",
            department : "File Room",
            date : "09/08/2021",
            total : "$350.00"
        },
        {
            user : "Ahmet Silva",
            department : "Neumology",
            date : "09/08/2021",
            total : "$1,200.00"
        }

    ]
})

export const encountersAtom = atom({
    key: "encountersAtom",
    default: [
        {
            user : "John Doe",
            id : "q12adlf45"
        },
        {
            user : "Heather Sass",
            id : "jgk2adlf45"
        },
        {
            user : "Jenny Doe",
            id : "jgk2adlf45"
        },
        {
            user : "Heather Camas",
            id : "jgk2adlf45"
        }

    ]
})

export const encountersInvolvedAtom = atom({
    key: "encountersInvolvedAtom",
    default: [
        {
            user : "John Doe",
            department : "Department A",
            speciality : "speciality A"
        },
        {
            user : "Jenny Doe",
            department : "Department B",
            speciality : "Speciality A"
        },
        {
            user : "Camilla Doe",
            department : "Department A",
            speciality : "Speciality B"
        },

    ]
})



export const actionsFileRoomAtom = atom({
        key:"actionsFileRoomAtom",
        default:[
            "Registration",
            "Special Notes",
            "Account Payable",
            "Submit an Evaluation",
            "Audit",
            "Appointment",
            "Refer Patient",
            "Place Order / Task",
            "Send / Share Document",
            "Consents & AD",
            "Document",
            "Write a Report",
            "Account Receivable",
        ]
    })

export const actionsInFileRoomAtom = atom({
    key:"actionsInFileRoomAtom",
    default:[
        "TALK TO DATA SPECIALIST",
        "SEARCH / Select File",
        "DATA MARKET",
        "SET DATA VALUES",
        "Crypto account",
        "FILE Sharing and Privileges"
    ]
})



export const reportsFileRoomAtom = atom({
        key:"reportsFileRoomAtom",
        default:[
            "Orders & Task Reports",
            "Compilance Report",
            "Departmental Records",
            "Policies Procedures",
            "Inventory Report",
            "Complaints Grievences",
            "Marketing Report",
            "Benchmarking & QA Report",
            "Surveys and Feedback",
            "Billing Report",
            "Analytics Report",
            "Encounters",
            "Human Resources",
            "Admin Report"
        ]
})

export const searchResultsAtom = atom({
    key:"searchResultsAtom",
    default:[
        {
            id: 1,
            title: "Test Search Result 1",
            type: "appointment"
        },
        {
            id: 2,
            title: "Test Search Result 2",
            type: "appointment"
        },
        {
            id: 3,
            title: "Hadiqa Cottrell",
            type: "user"
        },
        {
            id: 4,
            title: "Cottrell Hadiqa ",
            type: "user"
        },
        {
            id: 5,
            title: "Test Search Result 5",
            type: "user"
        }
    ]
})


export const notificationsAtom = atom({
    key:"notificationsAtom",
    default:[
        {
            id: 1,
            title: "Test Notification 1",
            type: "notification"
        },
        {
            id: 2,
            title: "Your next encounter starts in 10 minutes",
            type: "appointment"
        },
        {
            id: 3,
            title: "Hadiqa Cottrell sent you a message",
            type: "messages"
        },
        {
            id: 4,
            title: "New module available: File room",
            type: "platform"
        },
        {
            id: 5,
            title: "Test Notification 5",
            type: "user"
        }
    ]
})


export const environmentSettingsAtom = atom({
    key:'environmentSettingsAtom',
    default:undefined
})

export const onBoardingAtom = atom ({
    key:'onBoardingAtom',
    default : {
        data: undefined,
        files : undefined
    }
})

export const searchAtom = atom ({
    key:'searchAtom',
    default : false
})

export const adPopAtom = atom ({
    key:'adPopAtom',
    default : false
})

export const noticePopAtom = atom ({
    key:'noticePopAtom',
    default : false
})

export const notePopAtom = atom ({
    key:'notePopAtom',
    default : false
})

export const videoPopAtom = atom ({
    key:'videoPopAtom',
    default : false
})

export const encounterPopAtom = atom ({
    key:'encounterPopAtom',
    default : false
})

export const noteRecipientAtom = atom ({
    key:'noteRecipientAtom',
    default : []
})

export const enterDataPopAtom = atom ({
    key:'enterDataPopAtom',
    default : false
})

export const enterDataSearchState = atom ({
    key:'enterDataSearchState',
    default : true
})

export const enterDataDocId = atom ({
    key:'enterDataDocId',
    default : null
})