import React, {useEffect, useState} from "react";
//import '../../Styles/test.css';

export default function Resizable(){

    const [resizing, setResizing] = useState("none");
    const [box, setBox] = useState({
        ow : 0,
        oh : 0,
        mx : 0,
        my : 0
    });
    const [div] = useState('resizable')

    //const element = document.getElementById(div);
    const minimum_size = 20;

    const cb = (e) => {
        const element = document.getElementById(div);
        e.preventDefault()
        console.log("Mouse Over");
        let b = {
            ow : element.offsetWidth,
            oh : element.offsetHeight,
            mx : e.pageX,
            my : e.pageY
        }
        console.log("Starting B", b);
        setBox(b);
    }

    const md = (e) => {
        const element = document.getElementById(div);
        e.preventDefault()
        setResizing("start");
        console.log("Mouse Down", e.currentTarget);
        let b = {
            ow : element.offsetWidth,
            oh : element.offsetHeight,
            mx : e.pageX,
            my : e.pageY
        }
        console.log("B", b);
        setBox(b);
        window.addEventListener('mouseup', stopResize)
        window.addEventListener('mousemove', resize)
    }

    const resize = (e) => {
        setResizing("resizing");
        const element = document.getElementById(div);
        console.log(box);

        const width = box.ow + (e.pageX - box.mx);
        const height = box.oh + (e.pageY - box.my);

        if (width > box.ow) {
            element.style.width = width + 'px'
        }
        if (height > box.oh) {
            element.style.height = height + 'px'
        }

    }

    const stopResize = () => {
        console.log("Mouse Up");
        console.log(resizing);
        if(resizing === "resizing"){

        }
        setResizing("end");
        window.removeEventListener('mousemove', resize);
    }

    useEffect(()=>{

        console.log(resizing, box);

        if(resizing === "end"){
            const element = document.getElementById(div);
            console.log("Resizing", resizing);
            element.style.width = 'auto'
            element.style.height = 'auto'
            setResizing("none");
        }

    },[resizing])


    return(
        <div className='resizable' id="resizable">
            <div className='resizers'>
                Something is wrong with the resizer
                <div className='resizer top-left'></div>
                <div className='resizer top-right'></div>
                <div className='resizer bottom-left'></div>
                <div className='resizer bottom-right' onMouseDown={md} onMouseOver={cb}></div>
            </div>
        </div>
    )
}
