import React, {useEffect, useState} from "react";
import {deleteShare, getDocs, getShares, saveShare} from "../../../Actions/DashboardApi";
import UserCircles from "../EnterData/UserCircles";
import UsersList from "../EnterData/UsersList";
import AvataShared from "./AvataShared";

export default function FileSharedUsers({file}) {

	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState([]);
	const [userSelector, setUserSelector] = useState(false);

	useEffect(()=>{
		if(loading){
			getEntries();
		}
	},[loading]);

	const getEntries = async () => {
		const e = await getShares({'filters[doc][0]' : file, 'filters[accepted][1]' : true});
		console.log("SHARES", e);
		setEntries(e);
		setLoading(false);
	}

	const action = async (type, user) => {
		console.log(user);
		const d = await saveShare(
			{
				"accesses": [user.id],
				"doc": file,
				"accepted": true,
				"description": ""
			}
		);
		setLoading(true);
	}

	const removeShare = async (docId) => {
		const d = await deleteShare(docId);
		setLoading(true);
	}

	return (
		<div className="pver flex">
			{entries.length > 0 &&
				<div className="flex">
					{entries.map(({id:docId, attributes: {accesses}})=> {
						return(
							<div key={docId}>
								{accesses?.data.length > 0 && accesses.data.map(({id, attributes : {firstName, lastName}})=> {
									return(
										<AvataShared removeShare={removeShare} docId={docId} id={id} firstName={firstName} lastName={lastName} key={id} />
									)
								})}
							</div>
						)
					})}
				</div>
			}
			<div>
				<div className="flex">
					<div className="cursor-pointer w-3rem h-3rem grey300 t-black br-50 flex align-center justify-center" onClick={()=>setUserSelector(!userSelector)}>
						+
					</div>
				</div>
				{userSelector &&
					<UsersList title="shared" action={action} />
				}
			</div>
		</div>
	)
}