import React from "react";
import InfoManagementStates from "./InfoManagementStates";
import InfoManagementSearch from "./InfoManagementSearch";

export default function InfoManagementPrimary({tertiary}){

    return(
        <div>

            {/*<InfoManagementSearch />*/}

            {!tertiary &&
                <InfoManagementStates />
            }

        </div>
    )
}
