import React, {useEffect, useState} from "react";
import workroom from '../Styles/Img/Workroom1.png'
import x from '../Styles/Img/xmark.png'

import {useRecoilState, useRecoilValue} from "recoil";
import {
    primaryColumnsSelector,
    primaryModulesSelector,
    secondaryModulesSelector,
    tertiaryModulesSelector,
    quaternaryModulesSelector, quat
} from "../Recoil/selectors"
import ModuleBlock from "../Components/Dashboard/ModuleBlock";

/**
 * Tests modules
 */
import Popup from "../Components/UI/Popup";
import {
    adPopAtom,
    encounterPopAtom,
    enterDataPopAtom,
    notePopAtom,
    noticePopAtom,
    searchAtom,
    videoPopAtom
} from "../Recoil/atoms";
import NoteView from "../Components/Modules/Notes/NoteView";
import VideoConferenceView from "../Components/Modules/VideoConference/VideoConferenceView";
import InfoManagementSearch from "../Components/Dashboard/Modules/InfoManagement/InfoManagementSearch";
import xmark from "../Styles/Img/xmark.png";
import InfoManagementStates from "../Components/Dashboard/Modules/InfoManagement/InfoManagementStates";
import EnterData from "../Components/DataModules/EnterData/EnterData";
import ModuleHead from "../Components/Dashboard/Head/ModuleHead";
import ModuleBox from "../Components/Dashboard/Box/ModuleBox";
import Quaternary from "../Components/Dashboard/Quaternary";

export default function Dashboard (){

    const primaryModules = useRecoilValue(primaryModulesSelector);
    const secondaryModules = useRecoilValue(secondaryModulesSelector);
    const tertiaryModules = useRecoilValue(tertiaryModulesSelector);
    const quaternaryModules = useRecoilValue(quaternaryModulesSelector);
    const columns = useRecoilValue(primaryColumnsSelector);
    const quater = useRecoilValue(quat);

    const [search, setSearch] = useRecoilState(searchAtom);
    const [pop, setPop] = useRecoilState(adPopAtom);
    const [noticePop, setNoticePop] = useRecoilState(noticePopAtom);
    const [notePop, setNotePop] = useRecoilState(notePopAtom);
    const [videoPop, setVideoPop] = useRecoilState(videoPopAtom);
    const [encounterPop, setEncounterPop] = useRecoilState(encounterPopAtom);
    const [ed, setEd] = useRecoilState(enterDataPopAtom);

    const [cols, setCols] = useState(columns);

    useEffect(() => {
        console.log("QT", columns, primaryModules.length, secondaryModules.length, tertiaryModules.length);
        if(quater) {
            console.log("Quater", quater, columns);
            setCols(columns + 2);
        }
        else{
            console.log("False Quater", quater, columns);
            setCols(columns);
        }
    }, [columns, quater]);

    const switchEnter = () => {
        setEd(!ed);
    }

    const switchView = () => {
        setPop(!pop);
    }

    const switchNotice = () => {
        setNoticePop(!noticePop);
    }

    const switchSearch = () => {
        setSearch(!search);
    }

    return(
        <div className="flex grows direction-column relative overflow-hidden">
            {pop &&
                <Popup switchView={switchView}>
                    <div className="white p mhor-auto max-w-800px w-100 br">
                        <div className="min-h-40vh grey200 flex align-center justify-center">
                            <h3>
                                Advertising
                            </h3>
                        </div>
                    </div>
                </Popup>
            }

            {noticePop &&
                <Popup switchView={switchNotice}>
                    <div className="white p mhor-auto max-w-800px w-100 br">
                        <div className="min-h-40vh grey200 flex align-center justify-center">
                            <h3>
                                Notice Board
                            </h3>
                        </div>
                    </div>
                </Popup>
            }

            {search &&
                <Popup switchView={switchSearch}>
                    <div className="white p-2 mhor-auto max-w-400px w-100 br">
                        <div className="">
                            <div className='flex mb align-center'>
                                <h3 className='grows'>
                                    Search Facility
                                </h3>
                                <a onClick={()=>setSearch(!search)}>
                                    <img src={xmark} className='h-2rem' />
                                </a>
                            </div>
                            <InfoManagementSearch />

                        </div>

                    </div>
                </Popup>
            }

            {notePop &&
                <Popup switchView={()=>setNotePop(!notePop)} width={1600}>
                    <div className="white p mhor-auto max-w-1600px w-100 br">
                        <NoteView />
                    </div>
                </Popup>
            }

            {videoPop &&
                <VideoConferenceView />
            }

            {encounterPop &&
                <Popup switchView={()=>setEncounterPop(false)} width={1400}>
                    <div className="white p-2 mhor-auto max-w-1400px w-100 br">
                        <div className="flex justify-end">
                            <a onClick={()=>setEncounterPop(false)}>
                                <img src={x} alt="Work room" className="w-20px" />
                            </a>
                        </div>
                        <div className="">
                            <img src={workroom} alt="Work room" className="w-100" />
                        </div>

                    </div>
                </Popup>
            }

            <div className="flex grows">

                <div className={`p grows col-gap row-gap h-header  overflow-y-auto ${cols === 0 ? 'grid5' : cols === 1 ? 'grid4' : cols === 2 ? 'grid3' : cols === 3 ? 'grid2' : 'grid'}`}>
                    {primaryModules.map(({name, hidden}, i) => {
                            return(
                                <ModuleBlock key={i} name={name} hidden={hidden} />
                            )
                        })
                    }
                </div>

                {secondaryModules.length > 0 &&
                    <div className={`grey100 p flex col-gap h-header`}>
                        {secondaryModules.map(({name, hidden}, i) => {
                            return(
                                <ModuleBlock key={i} name={name} hidden={hidden} width="w-20vw max-w-20vw" />
                            )

                        })
                        }
                    </div>
                }

                {tertiaryModules.length > 0 &&
                <div className={`grey200 p flex col-gap h-header`}>
                    {tertiaryModules.map(({name, hidden}, i) => {
                        return (
                            <ModuleBlock key={i} name={name} hidden={hidden} />
                        )
                    })
                    }
                </div>
                }

                {quater &&
                    <Quaternary />
                }

                {quaternaryModules.length > 0 &&
                <div className="fixed z-1000 edges flex">
                    <div className="absolute edges black80">

                    </div>
                    <div className="p-2 overflow-y-scroll grows max-w-1600px mhor-auto">
                        {quaternaryModules.map(({name, hidden}, i) => {
                            return (
                                <ModuleBlock key={i} name={name} hidden={hidden} />
                            )
                        })
                        }
                    </div>

                </div>
                }

            </div>


        </div>
    )
}

/**
 * Test Modules
 */
{/*<DragToReorderList />*/}
{/*<div className="p-6 min-h-600px black relative">
    <Resizable />
</div>*/}
