import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {appointmentsAtom, oneOnOnePatient, selectedPatients, userModeAtom} from "../../../Recoil/atoms";
import AppointmentRow from "./AppointmentRow";
import Button from "../../UI/Button";

export default function AppointmentsList({icon, filter, user, actions, encounter, name}){

    const [appointments, setAppointments] = useRecoilState(appointmentsAtom);
    const mode = useRecoilValue(userModeAtom);
    const [patient, setPatient] = useRecoilState(oneOnOnePatient);
    const [length, setLength] = useRecoilState(selectedPatients);

    let filtered = [];
    let named = [];

    if(name){
        named = appointments.filter((a) => a.patient === name);
        setLength(named.length);
    }
    else{
        named = [...appointments];
        setLength(named.length);
    }
    if(filter){
        filtered = named.filter((a) => a.status === filter);
    }
    else if(user){
        filtered = named.filter((a) => a.patient === "David Zapata");
    }
    else {
        filtered = [...appointments];
    }

    if(filtered.length > 0){
        return(
            <>
                {filtered.length === 1 && mode === 'professional' &&
                    <a className={`${mode === 'professional' ? 't-p6' : 't-p3'} f-1rem mb-2 block`} onClick={()=>setPatient('')}>
                        {`<`} Back to all users
                    </a>
                }
                <table className="f-small">
                    <thead>
                    <tr className="left">
                        <th>User</th>
                        <th className="pr">Professional</th>
                        <th>Date</th>
                        {actions &&
                        <th className="right">Actions</th>
                        }
                    </tr>
                    </thead>

                    <tbody>
                    {filtered.map(
                        (appointment)=>{
                            return(
                                <AppointmentRow encounter={encounter} icon={icon} length={filtered.length} actions={actions} encounter={encounter} key={appointment.date} {...appointment} />
                            )
                        }
                    )}

                    </tbody>

                </table>

                {filtered.length === 1 && mode === 'professional' &&
                    <div className="grid2 col-gap p mt-2 white br-half">
                        <Button color='p4' fontSize='f-1rem'>
                            Enter Data
                        </Button>
                        <Button color='p5' fontSize='f-1rem'>
                            Talk to specialist
                        </Button>
                    </div>
                }
            </>

        )
    }
    else {
        return(
            <div>
                {filtered.length <= 1 && mode === 'professional' &&
                    <a className='t-p3 f-1rem mb-2 block' onClick={()=>setPatient('')}>
                        {`<`} Back to all users
                    </a>
                }
                <div className='pver-3'>
                    <h5 className="center mb">
                        No users in this room
                    </h5>
                    <p className='f-1rem center t-grey700 pb-2'>
                        Try different rooms or clicking back to all users
                    </p>
                </div>

            </div>

        )

    }

}
