import React from "react";

export default function IconNotifications({className, color, alertColor}){
    return(
        <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.15873 3.30283C8.0008 1.74365 10.2739 0.94043 11.9999 0.94043C13.6602 0.94043 15.9303 1.74816 17.7821 3.29861C19.6772 4.88532 21.2222 7.32529 21.2222 10.6242C21.2222 14.3402 22.0998 17.2552 22.9385 19.0873C23.6559 20.6543 23.3947 22.8329 21.7399 23.953C21.1761 24.3347 20.5108 24.5387 19.8299 24.5387H16.5785C16.5296 24.9846 16.4177 25.4222 16.2453 25.8382C16.0067 26.4143 15.657 26.9377 15.2161 27.3785C15.1919 27.4027 15.1675 27.4267 15.1428 27.4503C14.7176 27.8575 14.2202 28.1822 13.6758 28.4077C13.0998 28.6464 12.4824 28.7692 11.8589 28.7692C11.2354 28.7692 10.618 28.6464 10.042 28.4077C9.49753 28.1822 9.00013 27.8575 8.57497 27.4503C8.55028 27.4267 8.52584 27.4027 8.50164 27.3785C8.06076 26.9377 7.71103 26.4143 7.47243 25.8382C7.30011 25.4222 7.18818 24.9846 7.1393 24.5387H4.16997C3.48909 24.5387 2.82381 24.3347 2.25996 23.953C0.605161 22.8329 0.34398 20.6543 1.06137 19.0873C1.9001 17.2552 2.77773 14.3402 2.77773 10.6242C2.77773 7.33731 4.27594 4.89647 6.15873 3.30283ZM7.738 5.16863C9.19487 3.9355 10.9218 3.38487 11.9999 3.38487C12.9997 3.38487 14.7296 3.93099 16.2128 5.17285C17.6527 6.37844 18.7777 8.16924 18.7777 10.6242C18.7777 14.7224 19.7445 17.9829 20.7159 20.1048C21.0549 20.8453 20.8164 21.6263 20.3697 21.9288C20.2103 22.0366 20.0223 22.0943 19.8299 22.0943H11.9999H4.16997C3.97757 22.0943 3.78957 22.0366 3.63023 21.9288C3.18347 21.6263 2.94499 20.8453 3.28397 20.1048C4.2554 17.9829 5.22217 14.7224 5.22217 10.6242C5.22217 8.15722 6.32185 6.3673 7.738 5.16863Z" className={color ? color : "white"} />
            <circle cx="19.3332" cy="8.27409" r="4.66667" className={alertColor ? alertColor : "white"} stroke="b-grey800" strokeWidth="2"/>
        </svg>

    )
}
