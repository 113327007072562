import Button from "../UI/Button";
import {useRecoilState} from "recoil";
import {quat, quatActive} from "../../Recoil/selectors";
import {appointmentEdit, deptAppointments, preDate} from "../../Recoil/roomAtoms";

export default function PatientCalendar(){

	const [quater, setQuater] = useRecoilState(quat);
	const [qActive, setQActive] = useRecoilState(quatActive);
	const [entries, setEntries] = useRecoilState(deptAppointments);
	const [preD, setPreD] = useRecoilState(preDate);
	const [appEdit, setAppEdit] = useRecoilState(appointmentEdit);


	const hours = [
		{hour : "08:00", app:true, index:0, dr : 'Jhon Carpes'},
		{hour : "09:00"},
		{hour : "10:00", app:true, index:1, dr : 'Alisson Britz'},
		{hour : "11:00"},
		{hour : "12:00", app:true, index:2, dr : 'Jim Mathews'},
		{hour : "13:00"},
		{hour : "14:00", app:true, index:1, dr : 'Penny Tache'},
		{hour : "15:00", app:true, index:0, dr : 'Jason Guerra'},
		{hour : "16:00", app:true, index:3, dr : 'Penny Tache'},
		{hour : "17:00"},
		{hour : "18:00", app:true, index:3, dr : 'Jhon Carpes'}
	];

	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];

	const dates = [];

	let d = new Date();
	let d2 = new Date(d);
	d2.setDate(d2.getDate() + 3);


	while (d <= d2){
		dates.push(new Date(d));
		d.setDate(d.getDate() + 1);
	}

	const quaternary = () => {
		setPreD({date : "", time : ""});
		setQActive("appForm");
		setQuater(true);
	}

	const quaternaryEdit = (id) => {
		setAppEdit({...appEdit, id});
		setQActive("appFormEdit");
		setQuater(true);
	}

	const searchApp = (date, hour) => {
		const d =  date.toISOString().substring(0, 10);
		const h = `${hour}:00`;
		const res = entries.find((e) => e.attributes.date === d && e.attributes.time === h);
		if(res){
			return (<a onClick={()=>quaternaryEdit(res.id)}>{res.attributes?.user?.data?.attributes?.firstName} {res.attributes?.user?.data?.attributes?.lastName}</a>)
		}
		else{
			return false
		}
	}

	const presetDate = (date, hour) => {
		const d =  date.toISOString().substring(0, 10);
		const h = `${hour}:00.000`;
		setPreD({date : d, time : h});
		setQActive("appForm");
		setQuater(true);
	}
	return(
		<div className='b b-grey200 p br-half mb align-center justify-center'>
			<div className="flex align-center w-100">
				<h6 className='f-300 f-uppercase mb grows'>Calendar</h6>
				<Button color="p4" onClick={quaternary}>
					+ Schedule
				</Button>
			</div>


			<table className='f-1rem'>
				<thead className='f-uppercase'>
					<tr>
						<th className='left b-grey200 b-bottom'>
							Time
						</th>
						{dates.map((date, index)=>{
							return(
								<th key={`date-${index}`} className='center pver b-grey200 b-bottom'>
									{monthNames[date.getMonth()]} {date.getDate()}
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{hours.map(({hour, app, index, dr}, i)=>{
						return(
							<tr key={`hour-${i}`}>
								<td className='pver b-grey200 b-bottom b-right'>
									{hour}
								</td>
								{dates.map((date, ii)=> {
									return (
										<td key={`${date.toISOString()} ${hour}`} className={`b-right pver center b-grey200 b-bottom ${searchApp(date, hour) ? 'p3 t-white' : ''}`}>

											{searchApp(date, hour) ?
												searchApp(date, hour)
												:
												<div className="mhor">
													<Button color="white w-100" fontColor="t-grey300" onClick={()=>presetDate(date, hour)}>+</Button>
												</div>
											}
										</td>
									)
								})}
							</tr>
						)
					})
					}
				</tbody>
			</table>
		</div>
	)
}