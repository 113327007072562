import React, {useState} from "react";
import IconX from "../../UI/Icons/IconX";
import {useRecoilState} from "recoil";
import {quat, quatActive} from "../../../Recoil/selectors";
import Button from "../../UI/Button";
import MyFiles from "./MyFiles";
import SharedWithMe from "./SharedWithMe";
import AccessRequest from "./AccessRequest";
import AccessRequested from "./AccessRequested";

const tabs = [
	{title: "My Files"},
	{title: "Shared with Me"},
	{title: "Request Access"},
	{title: "Share notifications"}

]

const Tab = ({title, selected, onClick}) => {
	return (
		<a className={`h6 f-light f-uppercase mr inline-flex pver phor b-bottom-3 ${selected === title ? 'b-green' : 'b-white'}`} onClick={onClick} >
			{title}
		</a>
	)
}

export default function FileShare() {

	const [quater, setQuater] = useRecoilState(quat);
	const [tab, setTab] = useState("My Files");

	const [qActive, setQActive] = useRecoilState(quatActive);

	const openDoc = () => {
		setQActive("");
	}

	return (
		<div className="white p mhor-auto max-w-800px w-100 br">
			<div className="flex mb align-center p6  p-half br-quarter">
				<div className="grows flex align-center cursor-move">
					<h3 className="h6 grows t-white">
						File Share
					</h3>
					<a onClick={() => setQuater(false)}>
						<IconX />
					</a>
				</div>
			</div>

			<div className="flex b-bottom b-grey300 mt-3 mb-3">
				{tabs.map(({title})=><Tab key={title} title={title} selected={tab} onClick={()=>setTab(title)} />)}
			</div>

			<div>
				{tab === "My Files" &&
					<MyFiles openDoc={openDoc} />
				}
				{tab === "Shared with Me" &&
					<SharedWithMe openDoc={openDoc} />
				}
				{tab === "Request Access" &&
					<AccessRequested openDoc={openDoc} />
				}
				{tab === "Share notifications" &&
					<AccessRequest openDoc={openDoc} />
				}

			</div>
		</div>
	)
}