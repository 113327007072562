import React, {useState} from "react";
export default function useDataForm (){
	const [data,setData] = useState();
	const [error,setError]=useState();

	const updateData = (e) => {
		let name = e.target.name;
		let val = e.target.value;
		setData({...data, [name] : val});
	}
	return [data, updateData,error,setError];
}