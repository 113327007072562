import React from "react";

import Scheduling from "./Modules/Scheduling";
import Notifications from "./Modules/Notifications";
import Academy from "./Modules/Academy";
import Store from "./Modules/Store";
import Office from "./Modules/Office";
import Clinic from "./Modules/Clinic";
import Communication from "./Modules/Communication";
import Analytics from "./Modules/Analytics";
import Ecommerce from "./Modules/Ecommerce";
import Administration from "./Modules/Administration";
import InfoManagement from "./Modules/InfoManagement/InfoManagement";
import Forum from "./Modules/Forum";
import Utilities from "./Modules/Utilities";
import Search from "./Modules/Search/Search";

export default function ModuleBlock({name, hidden}){

    if(!hidden){
        switch (name){
            case "Scheduling" :
                return ( <Scheduling />)
            case "Notifications" :
                return ( <Notifications />)
            case "Academy" :
                return ( <Academy />)
            case "Store" :
                return ( <Store />)
            case "Office" :
                return ( <Office />)
            case "Clinic" :
                return ( <Clinic />)
            case "Communication" :
                return ( <Communication />)
            case "Analytics" :
                return ( <Analytics />)
            case "Ecommerce" :
                return ( <Ecommerce />)
            case "Administration" :
                return ( <Administration />)
            case "InfoManagement" :
                return ( <InfoManagement />)
            case "Forum" :
                return ( <Forum />)
            case "Utilities" :
                return ( <Utilities />)
            case "Search" :
                return ( <Search />)
            default:
                return null
        }
    }
    else{
        return null
    }

}
