import React, {useEffect, useState} from "react";
import Button from "../../UI/Button";
import {getShares} from "../../../Actions/DashboardApi";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";

export default function SharedWithMe() {

	const [loading, setLoading] = useState(true);
	const [docs, setDocs] = useState([]);
	const me = useRecoilValue(userAtom);

	useEffect(()=>{
		if(loading) {
			getEntries();
		}
	});

	const getEntries = async () => {
		const d = await getShares({'filters[accesses]': me.id, 'filters[accepted]' : true});
		console.log("WITH ME", d);
		setDocs(d);
		setLoading(false);
	}

	return (
		<table>
			<thead>
			<tr>
				<th>
					FILE OR DOC. ID
				</th>
				<th>
					TITLE
				</th>
				<th>
					Owner
				</th>
				<th>
					User Access
				</th>
				<th>
					Preview
				</th>
			</tr>
			</thead>
			<tbody>
			{docs.map(({id, attributes : { doc : { data : { id:docID, attributes : {title, owner}}}}})=>{
				return(
					<tr key={`${id}doc`}>
						<td>
							{docID}
						</td>
						<td>
							{title}
						</td>
						<td>
							{owner?.data?.attributes?.firstName} {owner?.data?.attributes?.lastName}
						</td>
						<td>
							Yes
						</td>
						<td>
							<Button color="p4">
								Open
							</Button>
						</td>
					</tr>
				)
			})}
			</tbody>
		</table>
	)
}