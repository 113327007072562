import React, {useState, useEffect} from "react";
import Input from "../../UI/Input";
import Textarea from "../../UI/Textarea";
import Button from "../../UI/Button";
import IconTrash from "../../UI/Icons/IconTrash";
import {deleteDataBlocks, updateDataBlocks} from "../../../Actions/DashboardApi";
import IconPencil from "../../UI/Icons/IconPencil";
import BlockFiles from "./BlockFiles";

export default function EditDataBlocks({doc, setLoading, id, attributes}) {

	const [fieldPop, setFieldPop] = useState(false);
	const [fieldName, setFieldName] = useState("");
	const [editMode, setEditMode] = useState(false);

	const [block, setBlock] = useState({
		"set": "",
		"type": "",
		"point": "",
		"attributes": "",
		"unit": "",
		"interactiveEvent": "",
		"subSet": "",
		"doc": doc,
		"customFields" : [
			// {
			// 	field: "Additional",
			// 	value: ""
			// }
		]
	});

	// useEffect(()=> {
	// 	console.log(block);
	// }, [block])

	useEffect(()=> {
		if(attributes){
			setBlock({...block, ...attributes, doc});
		}
	}, [attributes])

	const editField = (e) => {
		setBlock({...block, [e.target.name] : e.target.value});
	}

	const newField = () => {
		//addField(index, fieldName);
		setFieldPop(false);
		setFieldName("");
	}

	const remove = async (id) => {
		await deleteDataBlocks(id);
		setLoading(true);
	}

	const updateBlock = async () => {
		await updateDataBlocks(block, id);
	}

	return (
		<div className="p-2 b-grey200 b br-quarter mb-2 ">
			<div className="right">
				<a className="align-center inline-flex f-uppercase t-p5 f-bold mb" onClick={()=>setEditMode(true)}>
					Edit Block <IconPencil className="w-1halfrem ml-half" />
				</a>
			</div>
			<div key={`block-${id}`} className="grid3 col-gap-2 relative">

				<div className="col-span2">
					<div className="grid3 col-gap ">
						<Input label="Data Set" className="mb" value={block?.set} onChange={editField} name="set" />
						<Input label="Data Type" className="mb" value={block?.type} onChange={editField} name="type" />
						<Input label="Data Point" className="mb" value={block?.point} onChange={editField} name="point" />
					</div>

					<Textarea label="Data Attributes" className="mb" value={block?.attributes} onChange={editField} name="attributes" />
					<div className="grid3 col-gap">
						<Input label="Unit of observation" className="mb" value={block?.unit} onChange={editField} name="unit" />
						<Input label="Interactive event" className="mb" value={block?.interactiveEvent} onChange={editField} name="interactiveEvent" />
						<Input label="Sub Set" className="mb" value={block?.subSet} onChange={editField} name="subSet" />
					</div>

					{block?.customFields.length > 0 &&
						<div className="pver">
							{block?.customFields.map(({field, value}, ind)=>{
								return(
									<Input label={field} name={ind} value="" className="mb" />
								)
							})}
						</div>
					}

					<a className="block grey100 p br-quarter f-bold f-grey600 center" onClick={()=>setFieldPop(true)}>
						+ Add custom field to the block
					</a>

				</div>

				<div className="grey100 p relative">

					<BlockFiles files={block?.files?.data} id={id} setLoading={setLoading} />

					<a className="absolute bottom-1 right-1" onClick={()=>remove(id)}>
						<IconTrash color="grey300 hover:red border-0" />
					</a>

				</div>



				{fieldPop &&
					<div className="absolute edges br flex align-center justify-center">
						<div className="absolute black80 edges br-half" onClick={()=> setFieldPop(false)}>
						</div>
						<div className="white max-w-400px p br-half relative">
							<div className="p5 t-white p-half br-quarter mb">
								<h6>Add a field to this block</h6>
							</div>
							<div className="flex align-end">
								<Input className="mr-half" label="Field Name" onChange={(e)=>setFieldName(e.target.value)} />
								<Button color="p4" onClick={newField}>Save</Button>
							</div>
						</div>
					</div>
				}


			</div>
			{editMode &&
			<p className="w-100 mt">
				<Button className="p5" onClick={updateBlock}>
					Update Block
				</Button>
				<Button className="grey500 ml-half" onClick={()=>setEditMode(false)}>
					Cancel
				</Button>
			</p>
			}
		</div>
	)

}