import React from "react";

export default function IconOptions({className, color}){
    return(
        <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <circle cx="2.5" cy="2.5" r="2.5" fill={color ? color : "black"} />
            <circle cx="9.5" cy="2.5" r="2.5" fill={color ? color : "black"} />
            <circle cx="16.5" cy="2.5" r="2.5" fill={color ? color : "black"} />
        </svg>

    )
}
