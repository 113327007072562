import React, {useEffect, useState} from "react";
import Button from "../../UI/Button";
import SearchDoc from "../EnterData/SearchDoc";
import {getShares, saveShare} from "../../../Actions/DashboardApi";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";

export default function AccessRequested() {

	const me = useRecoilValue(userAtom);
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState([]);

	useEffect(()=>{
		if(loading){
			getEntries();
		}
	},[loading]);

	const getEntries = async () => {
		const d = await getShares({'filters[accesses]': me.id, 'filters[accepted]' : false});
		console.log("REQUESTED BY ME", d);
		setEntries(d);
		setLoading(false);
	}

	const requestAccess = async (doc) => {
		console.log("Access Requested", doc);
		const d = await saveShare(
			{
				"accesses": [me.id],
				doc,
				"accepted": false,
				"description": ""
			}
		);
		setLoading(true);
	};

	return (
		<div>
			<SearchDoc requestAccess={requestAccess} />
			<table className="mt-6">
				<thead>
				<tr>
					<th>
						FILE OR DOC. ID
					</th>
					<th>
						TITLE
					</th>
					<th>
						Owner
					</th>
					<th>
						REQUESTED BY
					</th>
					<th>
						APPROVAL
					</th>
				</tr>
				</thead>
				<tbody>
					{entries.map(({id, attributes : { description, doc : { data : { id:docID, attributes : {title, owner}}}}})=>{
						return(
							<tr key={`${id}doc`} className="pb b-bottom b-grey200">
								<td>
									{docID}
								</td>
								<td>
									{title}
								</td>
								<td>
									{owner?.data?.attributes?.firstName} {owner?.data?.attributes?.lastName}
								</td>
								<td>
									{me.firstName} {me.lastName}
								</td>
								<td className="pver">
									{description ?
										<div className="flex direction-column align-end">
											<h6 className="t-red">Rejected</h6>
											<div className="max-w-100px mt-half p-half b b-grey300 grey100 br-half">
												{description}
											</div>
										</div>
										:
										<h6>Pending</h6>
									}

								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}