import React from "react";
import ModuleHead from "../Head/ModuleHead";
import ModuleComingSoon from "../ModuleComingSoon";
import ModuleBox from "../Box/ModuleBox";
import modu from "../../../Styles/Img/modules/utilities.jpg";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../../Recoil/atoms";
import GenericPrimary from "./GenericPrimary";

export default function Utilities (){

    const modules = useRecoilValue(modulesAtom);
    const mod = modules.filter(m => m.name === "Utilities");

    return(
        <ModuleBox title="Utilities">
            <ModuleHead title="Utilities" />
            {mod.length > 0
                && mod[0].position !== 'tertiary'
                && mod[0].position !== 'quaternary'
                &&
                <GenericPrimary />
                /*<img src={modu} alt="" />*/
            }
            <ModuleComingSoon name="Utilities" />
        </ModuleBox>
    )
}
