import {atom} from"recoil";

export const deptAppointments = atom({
	key : "deptAppointments",
	default : []
});

export const selectedPatient = atom({
	key : "selectedPatient",
	default : null
});

export const preDate = atom({
	key : "preDate",
	default : {
		date : "",
		time : ""
	}
});

export const appointmentEdit = atom({
	key : "appEdit",
	default : {
		date : "",
		time : "",
		id: "",
		user: "",
		professional: "",
		subject: ""
	}
});