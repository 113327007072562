import axios from "axios";
import {apiUrl} from "../Recoil/atoms";

export const loginPX6 = async (data) => {
	console.log(data);
	try {
		const res = await axios.post(
			`${apiUrl}auth/local`,
			data
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const forgotPassword = async (data) => {
	console.log(data);
	try {
		const res = await axios.post(
			`${apiUrl}auth/forgot-password`,
			data
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const resetPassword = async (data) => {
	console.log(data);
	try {
		const res = await axios.post(
			`${apiUrl}auth/reset-password`,
			data
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const signupPX6 = async (data) => {
	console.log(data);
	try {
		const res = await axios.post(
			`${apiUrl}auth/local/register`,
			data
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getMe = async () => {
	try {
		const res = await axios.get(
			`${apiUrl}users/me`,
			{
				params: {
					populate: '*'
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		return  e;
	}
};

export const updateMe = async (data, id) => {
	console.log(data);
	try {
		const res = await axios.put(
			`${apiUrl}users/${id}`,
			data,
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		return e;
	}
};

export const getUserTypes = async (params) => {

	try {
		const res = await axios.get(
			`${apiUrl}user-types`,
			{
				params,
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const uploadFile = async (data) => {
	try {
		const res = await axios.post(
			`${apiUrl}upload`,
			data,
			{
				headers : {
					'Authorization' : `Bearer ${window.localStorage.getItem('jwt')}`,
					'Content-Type': 'multipart/form-data'
				}
			});
		return (res);
	}
	catch (e){
		throw e
	}
}