import React from 'react'
import Button from "../UI/Button";
import {useRecoilState, useRecoilValue} from "recoil";
import {notePopAtom, userModeAtom, videoPopAtom} from "../../Recoil/atoms";
import {quat, quatActive} from "../../Recoil/selectors";

export default function UserActions() {

	const [notePop, setNotePop] = useRecoilState(notePopAtom);
	const [videoPop, setVideoPop] = useRecoilState(videoPopAtom);
	const mode = useRecoilValue(userModeAtom);
	const [quater, setQuater] = useRecoilState(quat);
	const [qActive, setQActive] = useRecoilState(quatActive);

	const addNote = () => {
		//setNotePop(true);
		console.log("Q-ACTIVE NOTES");
		setQActive("notes");
		setQuater(true);
	}

	const startVideo = () => {
		setVideoPop(true);
	}

	return (
		<div className="inline-flex align-center">
			<Button height="h-1halfrem" fontSize="f-small" color={`${mode === 'professional' ? 'p6' : 'p3'}`} className="mr-half no-wrap" onClick={()=>addNote()}>
				Add Note
			</Button>
			<Button height="h-1halfrem" fontSize="f-small" color={`${mode === 'professional' ? 'p5' : 'p2'}`} onClick={()=>startVideo()}>
				Video Chat
			</Button>
		</div>
	)
}