import React from "react";
import Button from "../../../UI/Button";

export default function SearchInput(){
    return(
        <div className="align-center justify-center">
            <input className="f-400 h-3rem phor b b-grey500 br-quarter f-1rem w-100" placeholder="Search" />
            <Button className="mt-half w-100" height="h-2rem" color="p4" fontSize="f-small">
                Search
            </Button>
        </div>
    )
}