import React, {useState} from 'react'

export default function Input({label, children, className, placeholder, width,defaultValue, ...props}){

    const [defaultClass] = useState(`f-400 h-3rem phor b b-grey500 br-quarter f-1rem ${width ? width : "w-100"}`)

    return(
        <div className={className}>
            {label &&
                <label className="f-small mb-quarter block f-700">{label}</label>
            }
            <div className="relative flex">
                <input className={defaultClass} placeholder={placeholder ? placeholder : label} {...props} defaultValue={defaultValue}/>
                {children && children}
            </div>

        </div>
    )

}
