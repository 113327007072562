import React, {useState} from 'react'
import {useRecoilState, useRecoilValue} from "recoil";
import {selectedPatient} from "../../Recoil/roomAtoms";
import {
	enterDataPopAtom,
	userModeAtom
} from "../../Recoil/atoms";
import Button from "../UI/Button";
import IconDrag from "../UI/Icons/IconDrag";
import UserActions from "./UserActions";
import {quat, quatActive} from "../../Recoil/selectors";

export default function OneOnOnePatient() {

	const [selected, setSelected] = useRecoilState(selectedPatient);
	const mode = useRecoilValue(userModeAtom);
	const [ed, setEd] = useRecoilState(quat);
	const [qActive, setQActive] = useRecoilState(quatActive);

	const switchEnter = () => {
		setQActive("");
		setEd(true);
	}


	return (
		<div>
			<a className={`${mode === 'professional' ? 't-p6' : 't-p3'} f-1rem mb-2 block`} onClick={()=>setSelected(null)}>
				{`<`} Exit one on one view
			</a>
			<div className="flex align-center pb-2 b-bottom b-grey300">
				<IconDrag className="h-1rem mr-half" />
				<h5 className="t-p6 grows">
					{selected?.attributes?.user?.data?.attributes?.firstName} {selected?.attributes?.user?.data?.attributes?.lastName}
				</h5>
				<h6 className="phor-2">
					In {selected?.attributes?.room?.data?.attributes?.title || "Pending"}
				</h6>
				<UserActions />
			</div>


			<div className="grid2 col-gap pver white br-half">
				<Button color='p4' fontSize='f-1rem' onClick={switchEnter}>
					Enter Data
				</Button>
				<Button color='p5' fontSize='f-1rem'>
					Talk to specialist
				</Button>
			</div>
		</div>
	)
}