import React, {useEffect, useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Input from "../UI/Input";
import Button from "../UI/Button";
import FilePicker from "../UI/FilePicker";
import UploadPhoto from "./UpLoadPhoto";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import Error from "../UI/Error";
import {useHistory} from "react-router-dom";

export default function GU1({professional, back, next, title, subtitle, updateUser}){

    let history = useHistory();
    const user = useRecoilValue(userAtom);

    const [form, setForm] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        nationalId: user.nationalId ? user.nationalId : ''
    });

    const [userSaved, setUserSaved] = useState(false);

    const updateData = ({target: {name, value}}) => {
        setForm({...form, [name] : value});
    }

    const save= async ()=>{

        await updateUser(form);
        setUserSaved(true);
    }

    useEffect(()=>{
        if(userSaved) {
            console.log(form);

            if(user.type?.id === 1){
                history.push("/onboarding/b/2");
            }
            else{
                history.push("/onboarding/g/2");
            }
        }
    },[userSaved])

    const addFiles = file=>{

    }

    return(
        <div className="pb-2">

            <AuthHeader back={back ? back : "/onboarding/user-type"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "General User"}
                    subtitle={subtitle ? subtitle : "Step 1/3"}
                />

                <UploadPhoto />

                <Input label="First Name" name='firstName' className="mb" onChange={updateData}
                       value={form?.firstName || ''}/>
                <Input label="Last Name" name='lastName' className="mb" onChange={updateData}
                       value={form?.lastName || ''}/>
                {professional&&
                    <>
                        <Input label="National ID Number" name='nationalId' placeholder="ID Number"
                               defaultValue={form?.nationalId} onChange={updateData}/>
                        <FilePicker
                            id='nationalId'
                            addFiles={addFiles}
                            className='flex w-100  align-center mver'
                            style = {{justifyContent:'space-between'}}
                            text ='UPLOAD YOUR NATIONAL ID' secondaryText='Accepted file types: JPG, PNG, GIF'
                        />
                    </>
                }
                <Button
                    type="button"
                    onClick={save}
                    className="w-100">
                    Continue
                </Button>
            </div>

        </div>
    )
}
