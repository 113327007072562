import React from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";
import useDataForm from "../../Hooks/useDataForm";
import FilePicker from "../UI/FilePicker";
import {useRecoilState} from "recoil";
import {onBoardingAtom} from "../../Recoil/atoms";

export default function P3({back, next, title, subtitle,updateUser}){

    const addFiles = file=>{

    }
    return(
        <div className="pb-2">
            <AuthHeader back={back ? back : "/onboarding/p/2"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "Healthcare Professionals"}
                    subtitle={subtitle ? subtitle : "Step 3/5"}
                />

                <div>
                    <label className="f-small mb-half block f-700">Upload certificates and documents related to your role</label>
                    <div className="b b-grey300 p-half br-half mb">
                        <div className="min-h-200px grey100 flex align-center justify-center">
                            <div className="center">
                                <p className="center f-small t-grey500">
                                    Upload PDF, JPEG or PNG
                                </p>
                                <FilePicker
                                    id='certificate'
                                    addFiles={addFiles}
                                    className='center align-center'
                                    text ='SELECT FILES'
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <Button type="Link" to={next ? next : "/onboarding/p/4"} className="w-100">
                    Continue
                </Button>

            </div>
        </div>
    )
}
