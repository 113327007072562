import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {getMe} from "../../Actions/UserApi";
import {useRecoilState, useRecoilValue} from "recoil";
import {userAtom, userModeAtom} from "../../Recoil/atoms";

export default function CheckSession({children}){
	let history = useHistory();
	let { token } = useParams();
	var pathArray = window.location.pathname.split('/');

	const jwt = window.localStorage.getItem('jwt');
	const [user, setUser] = useRecoilState(userAtom);
	const [mode, setMode] = useRecoilState(userModeAtom);
	const [loading, setLoading] = useState(true);

	useEffect( ()=> {
		if (jwt){
			if(loading){
				const getUser = async () => {
					const u = await getMe();
					console.log('first Load', u);
					if(u?.type?.id === 2) {
						console.log("True patient")
						setMode("patient");
					}

					setUser(u);
					setLoading(false);
				}
				getUser();
			}
		}
		else{
			console.log("Checking Session not jwt")
			if (pathArray[1] === "reset-password" && pathArray[2]) {
				console.log("Yes token", token);
				setLoading(false);
			}
			else {
				history.push("/login");
				console.log("No token", token);
				setLoading(false);
			}
		}
	}, [loading]);

	if(loading){
		return (
			<div>
				<h2 className="center pver-8">
					Loading...
				</h2>
			</div>
		)
	}

	return(
		<>
			{children}
		</>
	)
}