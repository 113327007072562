import React, {useEffect, useState} from "react";
import {getNotes} from "../../../Actions/DashboardApi";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";
import UserCircle from "../EnterData/UserCircle";

export default function NotesList() {

	const user = useRecoilValue(userAtom);

	const [notes, setNotes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sentMode, setSentMode] = useState(false);
	
	useEffect(()=> {
		if(loading) {
			findNotes();
		}
	},[loading]);

	useEffect(()=> {
		setLoading(true);
	},[sentMode]);

	
	const findNotes = async () => {

		let params = {};

		if(sentMode){
			params = {"filters[from]" : user.id};
		}
		else{
			params = {"filters[to]" : user.id}
		}

		const n = await getNotes(params);
		setLoading(false);
		setNotes(n);
	}
	
	return (
		<div>
			<div className="flex">
				<div className={`p br-quarter f-uppercase cursor-pointer ${!sentMode && "f-bold t-white p4"}`} onClick={() => setSentMode(false)}>
					Received
				</div>
				<div className={`p br-quarter f-uppercase cursor-pointer ${sentMode && "f-bold t-white p4"}`} onClick={() => setSentMode(true)}>
					Sent
				</div>
			</div>

			<div className="b-top b-grey200 mt">
				{notes && notes.length > 0 ?
					notes.map(({id, attributes: {subject, message, from, createdAt, to}})=>{
						return (
							<div key={id} className="pver b-bottom b-grey200 flex align-start">
								<div className="w-100px shrinks-0">
									{sentMode ?
										<UserCircle id={from?.data?.id} action={()=>null} />
										:
										<UserCircle id={to?.data[0]?.id} action={()=>null} />
									}


								</div>
								<div className="ml-2 grows">
									<h6>{subject}</h6>
									<p>{message}</p>
								</div>
								<div className="ml-2">
									<b>{createdAt}</b>
								</div>
							</div>
						)
					})
					:
					<div>
						No notes has been received
					</div>
				}
			</div>

		</div>
	)
}