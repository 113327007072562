import React, {useEffect} from "react";
import IconDrag from "../../UI/Icons/IconDrag";
import Button from "../../UI/Button";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    appointmentsAtom,
    dragTableAtom,
    notePopAtom,
    noteRecipientAtom,
    oneOnOnePatient, roomAtom, userModeAtom,
    videoPopAtom
} from "../../../Recoil/atoms";
import IconReception from "../../UI/Icons/IconReception";
import IconWaitingRoom from "../../UI/Icons/IconWaitingRoom";
import IconFileRoom from "../../UI/Icons/IconFileRoom";
import {quat, quatActive} from "../../../Recoil/selectors";

export default function AppointmentRow({icon, length, patient, professional, status, date, actions, encounter}){

    const [dragTable, setDragTable] = useRecoilState(dragTableAtom);
    const mode = useRecoilValue(userModeAtom);

    const onDragStart = () =>{
        setDragTable({...dragTable, draggedFromId : date});
    }

    useEffect(()=>{
        //console.log(dragTable);
    },[dragTable]);

    const onDragLeave = () => {
        setDragTable({
            ...dragTable,
            draggedToState : null
        });
    }

    const [notePop, setNotePop] = useRecoilState(notePopAtom);
    const [videoPop, setVideoPop] = useRecoilState(videoPopAtom);
    const [noteRecipient, setNoteRecipient] = useRecoilState(noteRecipientAtom);
    const [onePatient, setOnePatient] = useRecoilState(oneOnOnePatient);
    const [room, setRoom] = useRecoilState(roomAtom);
    const [quater, setQuater] = useRecoilState(quat);
    const [qActive, setQActive] = useRecoilState(quatActive);

    const addNote = (patient) => {
        //setNotePop(true);
        //setNoteRecipient(patient);
        console.log("QACTOVE NOTES");
        setQActive("notes");
        setQuater(true);
    }

    const startVideo = (patient) => {
        setVideoPop(true);
        console.log("Starting video")
        setNoteRecipient(patient)
    }

    const getIcon = (location) => {
        switch (location){
            case "Reception":
                return(<IconReception color='p5' className='w-2rem' />)
            case "Waiting Room":
                return (<IconWaitingRoom color='p3' className='w-2rem' />)
            case "File Room":
                return (<IconFileRoom color='p2' className='w-2rem' />)
        }
    }

    const changePatientRoom = (patient, room) => {
        setRoom(room);
        setOnePatient(patient);
    }

    return(
        <tr
            className="left f-1rem hover:grey200"
            draggable
            onDragStart={onDragStart}
            onDragLeave={onDragLeave}
        >
            <td className="pver-half b-bottom b-grey200 pr">
                <div className="flex align-center">
                    <IconDrag className="h-1rem" />
                    <a onClick={()=>changePatientRoom(patient, status)}>
                        <strong className={`${length===1 ? 'f-large' : ''} block ml-half ${mode === 'professional' ? 't-p6' : 't-p3'}`}>
                            {encounter ?
                                "Test User 1"
                                :
                                patient
                            }
                        </strong>
                    </a>
                </div>
            </td>
            <td className="pver-half b-bottom b-grey200 pr">
                <div className='flex align-center'>
                    {icon &&
                        <div className='w-2rem mr-half'>
                            {getIcon(status)}
                        </div>
                    }

                    <p>
                        {professional} <br/>
                        <em>{status}</em>
                    </p>
                </div>

            </td>
            <td className="pver-half b-bottom b-grey200 pr">
                {`${new Date(date).getHours()}:${new Date(date).getMinutes() === 0 ? "00" : new Date(date).getMinutes()}`}
            </td>
            {actions &&
            <td className="pver-half b-bottom b-grey200 right">
                <div className="inline-flex align-center">
                    <Button height="h-1halfrem" fontSize="f-small" color={`${mode === 'professional' ? 'p6' : 'p3'}`} className="mr-half no-wrap" onClick={()=>addNote(patient)}>
                        Add Note
                    </Button>
                    <Button height="h-1halfrem" fontSize="f-small" color={`${mode === 'professional' ? 'p5' : 'p2'}`} onClick={()=>startVideo(patient)}>
                        Video Chat
                    </Button>
                </div>

            </td>
            }
            {encounter &&
                <td className="pver-half b-bottom b-grey200 right">
                    <div className="inline-flex align-center">
                        <Button height="1rem" fontSize="f-small" color="p4">
                            Start Encounter
                        </Button>
                    </div>

                </td>
            }
        </tr>
    )
}
