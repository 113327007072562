import React, {useState} from "react";

export default function AvataShared({removeShare, docId, id, firstName, lastName}) {

	const [sub, setSub] = useState(false);


	return (
		<div className="relative hover-display" key={id}>
			<a onClick={()=> setSub(!sub)}>
				<div title={`${firstName} ${lastName}`} className={`b-3 ${sub ? 'b-p4' : 'b-p6'} mr-quarter cursor-pointer w-3rem h-3rem p6 t-white br-50 flex align-center justify-center`}>
					{firstName?.charAt(0)}{lastName?.charAt(0)}
				</div>
			</a>
			<div className="top-3 left-0 absolute white p-half z-100 child b b-grey300">
				<a className="nowrap" onClick={() => removeShare(docId)}>Revoke access</a>
			</div>
		</div>
	)
}