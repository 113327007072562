import AppointmentsList from "../Components/DataModules/Appointments/AppointmentsList";
import React from "react";
import Input from "../Components/UI/Input";

export default function Encounters(){
    return(
        <div className="p-2">
            <div className="flex align-center">
                <h3 className="no-wrap mr-2">
                    Recent Encounters
                </h3>


                <div className="grows flex justify-end align-start">
                    {/*<h6 className="f-uppercase mr">
                        Filter encounters
                    </h6>*/}
                    <div className="mr">
                        <Input label="From" placeholder="MM / DD / YYYY" />
                    </div>
                    <div className="mr">
                        <Input label="To" placeholder="MM / DD / YYYY" />
                    </div>

                    <div className="mr">
                        <Input label="State" />
                    </div>
                    <div className="mr">
                        <Input label="Facility" />
                    </div>
                    <div className="mr">
                        <Input label="Department" />
                    </div>

                </div>
            </div>




            <div className="mhor-auto mt-2 br sh-10-black10 p-2 white">
                <AppointmentsList actions={false} encounter={true} />
            </div>


        </div>
    )
}