import React, {useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";
import Input from "../UI/Input";
import UploadPhoto from "./UpLoadPhoto";
import useDataForm from "../../Hooks/useDataForm";

export default function P2({back, next, title, subtitle,addData}){
    const [data,updateData] = useDataForm();

    const handleContinue =()=>{
        addData(`${next}` , {...data});
    }
    return(
        <div className="pb-2">
            <AuthHeader back={back ? back : "/onboarding/user-type"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "Healthcare Professionals"}
                    subtitle={subtitle ? subtitle : "Step 2/5"}
                />

               <UploadPhoto></UploadPhoto>

                <Input label="First Name" name='firstName' className="mb" onChange={updateData}/>
                <Input label="Last Name" name='lastName' className="mb" onChange={updateData}/>

                <Input label="National ID Number" name='nationalId' placeholder="ID Number" onChange={updateData}/>
                <div className="flex align-center mt-half mb">
                    <Button color="p4" fontSize="f-1rem" height="h-2rem" className="dark-blue mb-half">
                        Upload Your National ID
                    </Button>
                    <p className="grows f-small t-grey500 ml-2 right">
                        Accepted file types: JPG, PNG, PDF
                    </p>
                </div>

                <Button
                    type="button"
                    onClick={handleContinue}
                    className="w-100">
                    Continue
                </Button>

            </div>
        </div>
    )
}
