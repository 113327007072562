import {px6Api} from "./index";

export const signup = async(data) => {

	try{
		const res = await px6Api.post(
			`authentication/signup`,
			{...data},
		);
		console.log(res);
		return res;
	}
	catch (e){
		throw e.response;
	}

}


export const login = async(data) => {

	try{
		const res = await px6Api.post(
			`authentication/login`,
			{...data},
		);
		console.log(res);
		return res;
	}
	catch (e){
		throw e.response
	}

}

export const logout = async() => {

	try{
		const res = await px6Api.post(
			`authentication/logout`,
			'',
		);
		console.log(res);
		return res;
	}
	catch (e){
		console.log({e})
		if(e.response.status === 401){
			console.log('---retry---')
			try {
				await refreshJWT();
				const res = await px6Api.post(
					`authentication/logout`,
					'',
				);
				console.log(res,'retry');
				return res;
			}
			catch (e) {
				throw e.response
			}
		}
		throw e.response
	}

}

export const refreshJWT = async() => {

	try{
		const res = await px6Api.post(
			`authentication/refresh-jwt`,
			'',
			{
				withCredentials : true
			}
		);

		return res;
	}
	catch (e){
		throw e.response
	}

}

export const getUser = async ()=>{

	try{
		const res = await px6Api.get(
			`user/user-jwt`,
		);

		return res;
	}
	catch (e){
		throw e.response
	}
}
