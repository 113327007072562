import React from "react";
import IconBack from "../UI/Icons/IconBack";
import logo from "../../Styles/Img/logo-color.png";
import {Link} from "react-router-dom";

export default function AuthHeader({back}){
    return(
        <div className="pver-3 b-bottom b-grey200 mhor-4 center flex align-center mb-4">
            {back &&
            <div>
                <Link to={back}>
                    <IconBack />
                </Link>
            </div>
            }
            <div className="flex justify-center grows">
                <img src={logo} className="h-6rem w-auto obj-contain"  />
            </div>
        </div>
    )
}
