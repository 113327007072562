import React,{useState} from "react";

export default function FilePicker( {text,secondaryText,className,style,addFiles,id}){
	const [uploadFile, setUploadFile] = useState(null);
	const onChangeFile =e=>{
		console.log({[id]: e.target.files[0]});
		addFiles({[id]: e.target.files[0]});
	}

	return(
		<div className={className} style={style}>
			<label
				htmlFor={`upload-${id}`}
				className="br-quarter t-white center justify-center b-0 inline-flex phor align-center cursor-pointer f-700 f-titillium f-uppercase h-2rem dark-blue mb-half p4 f-1rem">
				{text}
			</label>
			<input id={`upload-${id}`} type='file' className='hidden'
				   onChange={onChangeFile}
			/>
			{secondaryText &&
			<p className="f-small">
				{secondaryText}
			</p>}

		</div>
	);
}