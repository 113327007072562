import React from "react";

export default function IconReception({className, color}){
    return(
        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M24.4448 23.4326H41.3401C41.5515 23.4326 41.763 23.4263 41.9744 23.4326C42.5421 23.4569 42.8963 23.7856 42.91 24.295C42.9248 24.8234 42.5612 25.2028 41.9818 25.2239C41.4225 25.2451 40.8633 25.2239 40.2342 25.2239V43.6631C40.2342 44.7485 39.9847 44.9989 38.9032 44.9989C27.2781 44.9989 15.6538 44.9989 4.03011 44.9989C2.93801 44.9989 2.68956 44.7538 2.68956 43.6769V25.2366C2.11973 25.2366 1.56575 25.2366 1.01283 25.2366C0.546599 25.2366 0.185036 25.0527 0.0444275 24.5814C-0.137413 23.9705 0.285472 23.4474 0.976887 23.44C2.13982 23.4284 3.30275 23.44 4.45934 23.44H5.06511C5.137 22.7172 5.17084 22.0197 5.28184 21.3338C5.87177 17.6202 7.55484 15.812 11.2572 15.0838C13.9066 14.5628 16.5739 14.5935 19.2 15.3068C22.1866 16.1174 23.7851 18.1412 24.2069 21.151C24.3106 21.8929 24.3634 22.6432 24.4448 23.4326ZM4.52699 25.2609V43.1685H38.3958V25.2651L4.52699 25.2609ZM20.1843 23.4326H22.6C22.5693 22.8968 22.5492 22.4424 22.5175 21.9901C22.3251 19.2054 21.1305 17.6117 18.5847 16.9861C15.9972 16.3306 13.2847 16.3491 10.7064 17.04C8.02107 17.7618 6.34328 20.7092 6.99135 23.3988H9.32884C9.32884 22.8937 9.32884 22.4223 9.32884 21.951C9.3373 21.282 9.67455 20.8878 10.2306 20.8878C10.7867 20.8878 11.1208 21.2767 11.1282 21.952C11.1282 22.435 11.1282 22.918 11.1282 23.4009H18.3796C18.3796 22.8852 18.3733 22.398 18.3796 21.9119C18.3912 21.2715 18.7211 20.8942 19.2613 20.8878C19.8016 20.8815 20.16 21.2619 20.1779 21.8886C20.1948 22.3758 20.1843 22.8662 20.1843 23.4326Z" className={color ? color : "black"}/>
            <path d="M34.8351 0.00302542C36.738 0.00302542 38.6347 0.00302542 40.5345 0.00302542C42.6605 0.00936628 43.9831 1.32826 43.9926 3.46196C44.0018 5.71156 44.0018 7.96186 43.9926 10.2129C43.9831 12.3434 42.6552 13.6559 40.5239 13.6581C38.198 13.6581 35.8806 13.6485 33.559 13.6729C33.2742 13.6867 33.003 13.7987 32.7915 13.9899C31.7628 14.9748 30.7648 15.9915 29.7604 17.0018C29.4147 17.3484 29.0722 17.6771 28.5214 17.4552C27.9463 17.2237 27.9346 16.7302 27.9378 16.2229C27.9431 15.4673 27.9294 14.7106 27.9442 13.955C27.9494 13.676 27.8723 13.5323 27.5953 13.4034C26.3002 12.8158 25.632 11.7833 25.6215 10.3661C25.6024 8.01047 25.5961 5.65378 25.6215 3.29921C25.6426 1.34095 27.0064 0.0157071 28.9771 0.00302542C30.935 -0.00648586 32.8834 0.00408223 34.8351 0.00302542ZM29.7467 14.4612C30.5142 13.6919 31.1813 13.0493 31.8167 12.3772C31.9739 12.2011 32.1683 12.0622 32.386 11.9708C32.6036 11.8794 32.839 11.8377 33.0748 11.8488C35.5878 11.8657 38.1018 11.8594 40.6148 11.8541C41.6001 11.8541 42.1689 11.3257 42.1774 10.3365C42.1964 7.98194 42.1964 5.62772 42.1774 3.27385C42.171 2.35231 41.6044 1.81017 40.6761 1.80806C36.756 1.80101 32.8362 1.80101 28.9168 1.80806C28.0393 1.80806 27.4526 2.32484 27.442 3.19036C27.411 5.61468 27.411 8.039 27.442 10.4633C27.4515 11.2676 27.9643 11.7442 28.7624 11.8456C29.4782 11.9365 29.7319 12.2176 29.7456 12.9532C29.753 13.4065 29.7467 13.8557 29.7467 14.4612Z" className={color ? color : "black"}/>
            <path d="M8.98201 7.8973C8.97811 7.14026 9.12372 6.3899 9.41048 5.68922C9.69725 4.98854 10.1195 4.35132 10.6531 3.8141C11.1867 3.27687 11.8212 2.8502 12.52 2.55854C13.2189 2.26687 13.9685 2.11596 14.7258 2.11444C15.4831 2.10845 16.2341 2.25194 16.9358 2.53668C17.6375 2.82142 18.276 3.24182 18.8149 3.77376C19.3537 4.3057 19.7822 4.93872 20.0757 5.63654C20.3693 6.33435 20.5222 7.08323 20.5257 7.84023C20.5325 8.59718 20.39 9.34806 20.1064 10.0499C19.8227 10.7518 19.4035 11.3909 18.8726 11.9307C18.3417 12.4705 17.7096 12.9005 17.0124 13.1959C16.3152 13.4914 15.5666 13.6466 14.8093 13.6527C14.0475 13.6616 13.2914 13.5194 12.5849 13.2342C11.8784 12.949 11.2356 12.5265 10.6936 11.9912C10.1516 11.4559 9.72123 10.8184 9.42743 10.1157C9.13363 9.41295 8.98224 8.65893 8.98201 7.8973V7.8973ZM10.7867 7.87088C10.7829 8.39019 10.8816 8.90516 11.077 9.38634C11.2724 9.86752 11.5608 10.3055 11.9257 10.6752C12.2905 11.0449 12.7247 11.3391 13.2033 11.541C13.682 11.7429 14.1957 11.8486 14.7153 11.8519C15.2391 11.858 15.7589 11.7603 16.2447 11.5645C16.7306 11.3686 17.1727 11.0784 17.5457 10.7107C17.9187 10.343 18.2151 9.905 18.4177 9.4221C18.6203 8.93921 18.7252 8.42096 18.7263 7.8973C18.729 7.37347 18.6275 6.85433 18.4279 6.37001C18.2282 5.88568 17.9343 5.44582 17.5632 5.07595C17.1922 4.70609 16.7513 4.41358 16.2662 4.21542C15.7811 4.01726 15.2615 3.91738 14.7375 3.92158C14.2182 3.92005 13.7038 4.02115 13.2237 4.21908C12.7437 4.41701 12.3076 4.70785 11.9404 5.07488C11.5732 5.44192 11.2823 5.87789 11.0843 6.35774C10.8863 6.83758 10.7851 7.35182 10.7867 7.87088V7.87088Z" className={color ? color : "black"}/>
            <path d="M34.8203 9.6794C33.3803 9.6794 31.9394 9.6868 30.4994 9.6794C29.7964 9.6794 29.3946 9.24506 29.4824 8.64162C29.5511 8.17134 29.9159 7.88811 30.5237 7.87966C31.4552 7.86698 32.3855 7.87966 33.3169 7.87966C35.1607 7.87966 37.0048 7.87966 38.8493 7.87966C39.0597 7.8719 39.2703 7.89036 39.4762 7.93461C39.9245 8.05086 40.1592 8.37002 40.1391 8.8255C40.119 9.28099 39.8653 9.59909 39.399 9.65087C38.9619 9.6856 38.5232 9.69653 38.0849 9.68363C36.9981 9.68152 35.9092 9.6794 34.8203 9.6794Z" className={color ? color : "black"}/>
            <path d="M32.5483 5.78342C31.8632 5.78342 31.1739 5.7961 30.492 5.78342C29.8471 5.76968 29.4771 5.43044 29.4739 4.88936C29.4707 4.34827 29.8418 3.99107 30.4804 3.98473C31.89 3.96993 33.2961 3.96993 34.6986 3.98473C35.3171 3.99107 35.7146 4.38103 35.7019 4.9031C35.6892 5.42516 35.3097 5.76545 34.7103 5.7813C33.9903 5.80033 33.2693 5.78342 32.5483 5.78342Z" className={color ? color : "black"} />
        </svg>

    )
}
