import React from "react";
import {useHistory} from "react-router-dom"

export default function Home(){

    let history = useHistory();
    history.push("/login")

    return(
        <>
            Loading...
        </>
    )
}
