import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../Recoil/atoms";

export default function ModuleComingSoon({name}){

    const modules = useRecoilValue(modulesAtom);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(()=>{

        if(loading){
            const result = modules.filter(m => m.name === name);

            if (result[0].position === 'secondary' || result[0].position === 'tertiary'  || result[0].position === 'quaternary') {
                setText("Coming Soon...");
            }
            else (
                setText("")
            )

            setLoading(false);
        }

    },[loading]);



    return(
        <div className="grows align-center justify-center flex">
            <h4>{text}</h4>
        </div>
    )

}
