import React, {useEffect, useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";
import Input from "../UI/Input";

export default function P5({back, next, title, subtitle,handleFinish}){

    return(
        <div className="pb-2">
            <AuthHeader back={back ? back : "/onboarding/p/4"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "Healthcare Professionals"}
                    subtitle={subtitle ? subtitle : "Step 5/5"}
                />

                <Input label="Organization" name = 'organization' placeholder="Organization" className="mb" />
                <Input label="Facility" name = 'facility' placeholder="Facility" className="mb" />
                <Input label="Department" name = 'department' placeholder="Department" className="mb" />

                <Button type="Link" to="/dashboard" className="w-100">
                    Finish
                </Button>

            </div>
        </div>
    )
}
