import React, {useState, useEffect} from "react";
import Button from "../../UI/Button";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../../Recoil/atoms";
import {getShares, saveShare, updateShare} from "../../../Actions/DashboardApi";
import Textarea from "../../UI/Textarea";
import RejectForm from "./RejectForm";

export default function AccessRequest() {

	const [loading, setLoading] = useState(true);
	const [docs, setDocs] = useState([]);
	const me = useRecoilValue(userAtom);

	useEffect(()=>{
		if(loading) {
			getEntries();
		}
	});

	const getEntries = async () => {
		const d = await getShares({'filters[doc][owner]': me.id, 'filters[accepted]' : false});
		console.log("TO ME", d);
		setDocs(d);
		setLoading(false);
	}

	const acceptAccess = async (id) => {
		const d = await updateShare(
			{
				"accepted": true
			},
			id
		);
		console.log(d);
		setLoading(true);
	}

	const rejectAccess = async (id, description) => {
		const d = await updateShare(
			{
				"accepted": false,
				description
			},
			id
		);
		console.log(d);
		setLoading(true);
	}

	return (
		<table>
			<thead>
			<tr>
				<th>
					FILE OR DOC. ID
				</th>
				<th>
					TITLE
				</th>
				<th>
					OWNER
				</th>
				<th className="pr">
					REQUESTED BY
				</th>
				<th>
					APPROVAL
				</th>
				<th>
					STATUS
				</th>
				<th>
					PREVIEW
				</th>
			</tr>
			</thead>
			<tbody>
			{docs.map(({id, attributes : { accesses, description, doc : { data : { id:docID, attributes : {title, owner}}}}})=>{
				return(
					<tr key={`${id}doc`} className="pb b-bottom b-grey200">
						<td>
							{docID}
						</td>
						<td>
							{title}
						</td>
						<td>
							{owner?.data?.attributes?.firstName} {owner?.data?.attributes?.lastName}
						</td>
						<td className="pr">
							{accesses?.data[0]?.attributes?.firstName} {accesses?.data[0]?.attributes?.lastName}
						</td>
						<td>
							<div className="pver flex">
								<Button color="p3 mr-half" onClick={()=> acceptAccess(id)}>
									Accept
								</Button>
								<RejectForm id={id} rejectAccess={rejectAccess} />
							</div>
						</td>
						<td className="p">
							{description ?
								<div className="flex direction-column align-start justify-center">

									<div className="max-w-100px mt-half p-half b b-grey300 grey100 br-half">
										<h6>Rejected</h6>
										{description}
									</div>
								</div>
								:
								<h6>Pending</h6>
							}
						</td>
						<td className="pb">
							<Button color="p4">
								Open
							</Button>
						</td>
					</tr>
				)
			})}
			</tbody>
		</table>
	)
}