import {atom, selector} from "recoil";
import {modulesAtom} from "./atoms";

/**
 * @type {RecoilValueReadOnly<unknown>}
 * Defines the amount of columns used by sec + tert modules. The limit of sec and tert columns in design is defined on the ModuleHead component.
 */
export const primaryColumnsSelector = selector({
    key: 'primaryColumns',
    get: ({get}) => {
        const secs = get(secCountSelector);
        console.log("Secs", secs)
        const terts = get(tertCountSelector) * 2;
        console.log("Terts", terts);
        return (secs + terts);

    }
});

/**
 * Filters primary modules
 * @type {RecoilValueReadOnly<*>}
 */
export const primaryModulesSelector = selector({
    key: 'primaryModules',
    get: ({get}) => {
        const list = get(modulesAtom);
        return list.filter((module) => module.position === 'primary');
    },
});

/**
 * Filters secondary modules
 * @type {RecoilValueReadOnly<*>}
 */
export const secondaryModulesSelector = selector({
    key: 'secondaryModules',
    get: ({get}) => {
        const list = get(modulesAtom);
        return list.filter((module) => module.position === 'secondary' && !module.hidden);
    },
});

/**
 * Filters tertiary modules
 * @type {RecoilValueReadOnly<*>}
 */
export const tertiaryModulesSelector = selector({
    key: 'tertiaryModules',
    get: ({get}) => {
        const list = get(modulesAtom);
        return list.filter((module) => module.position === 'tertiary' && !module.hidden);
    },
});

/**
 * Filters quaternary modules
 * @type {RecoilValueReadOnly<*>}
 */
export const quaternaryModulesSelector = selector({
    key: 'quaternaryModules',
    get: ({get}) => {
        const list = get(modulesAtom);
        return list.filter((module) => module.position === 'quaternary');
    },
});

/**
 * Counts secondary modules
 * @type {RecoilValueReadOnly<unknown>}
 */
export const secCountSelector = selector({
    key: 'secondaryCount',
    get: ({get}) => {
        return get(secondaryModulesSelector).length;
    },
});

export const quat = atom ({
    key:'quatAtom',
    default : false
})

export const quatActive = atom ({
    key:'quatActive',
    default : ""
})

/**
 * Counts tertiary modules
 * @type {RecoilValueReadOnly<unknown>}
 */
export const tertCountSelector = selector({
    key: 'tertiaryCount',
    get: ({get}) => {
        return get(tertiaryModulesSelector).length;
    },
});
