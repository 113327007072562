import AppointmentsList from "./Appointments/AppointmentsList";
import {useRecoilValue} from "recoil";
import {oneOnOnePatient, roomAtom} from "../../Recoil/atoms";
import Appointments from "./Appointments/Appointments";

export default function RoomEncounter(){

	const room = useRecoilValue(roomAtom);
	const patient = useRecoilValue(oneOnOnePatient);

	return(
		<div>
			<Appointments roomFilter={true} actions={true} />
			{/*<AppointmentsList filter={room} actions={true} name={patient} />*/}
		</div>

	)


}