import React, {useState} from "react";
import Button from "../../UI/Button";
import {updateDataBlocks, uploadFiles} from "../../../Actions/DashboardApi";
import IconX from "../../UI/Icons/IconX";

export default function BlockFiles({id, files, setLoading}) {

	const [uploading, setUploading] = useState(false);

	const uploadFile = async (e) => {
		setUploading(true);
		const file =  e.target.files[0];
		const formData = new FormData();
		formData.append("files", file);

		const upload = await uploadFiles(formData);
		const f = files && files.length > 0 ? files.map(({id}) => id) : [];
		await updateDataBlocks({files: [upload[0].id, ...f]}, id);
		setUploading(false);
		setLoading(true);
	}

	const removeFile = async (e) => {
		let f = files && files.length > 0 ? files.map(({id}) => id) : [];
		f = f.filter(item => item !== e);
		await updateDataBlocks({files: f}, id);
		setLoading(true);
	}

	return (
		<div>

			<div className="cursor-pointer relative p5 br-quarter p-half t-white center f-roboto f-uppercase">
				{uploading ? "Loading ..." : "Upload File"}
				{!uploading && <input className="absolute edges op-0 cursor-pointer" type="file" onChange={uploadFile} />}
			</div>

			{/*<FilePicker text="Upload File" onChange={uploadFile} />*/}

			<p className="mver">
				Uploaded Files: {" "}
				{files?.length}
			</p>
			{files && files.length > 0 && files.map(({attributes: {name}, id: fileId})=>{
				return (
					<div className="word-break-all p-half br-quarter mb-quarter f-bold f-underline t-p5 b b-grey300 flex">
						<div className="grows">
							{name}
						</div>

						<Button className="p-half ml-half" height="h-2rem" onClick={()=>removeFile(fileId)}>
							<IconX className="w-1rem h-1rem" />
						</Button>
					</div>
				)
			})}

		</div>
	)
}