import React from "react";
import {useRecoilState} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {getMe, uploadFile} from "../../Actions/UserApi";

export default function UpLoadPhoto (){

	const [user, setUser] = useRecoilState(userAtom);

	const savePicture = async (e) =>{
		const file = e.target.files[0];
		let formData = new FormData();
		formData.append('files', file);
		formData.append('refId', user.id);
		formData.append('ref', 'plugin::users-permissions.user');
		formData.append('field', 'profilePicture');
		//formData.append('source', 'users-permissions');

		try {
			await uploadFile(formData);
			console.log("File uploaded");
			const u = await getMe();
			setUser(u);
		}
		catch (e){
			console.log(e);
		}
	}

	return(
		<>
			<div className="flex align-center justify-center">
				<div className='w-150px h-150px grey200 br-50 overflow-hidden'>
					<img
						src={user?.profilePicture?.url || ''}
						className="w-100 h-100 obj-cover"
					/>
				</div>
			</div>
			<div className="flex align-center justify-center mt mb-3">
				<div className="center">
					<label
						htmlFor='upload-photo'
						className="br-quarter t-white center justify-center b-0 inline-flex phor align-center cursor-pointer f-700 f-titillium f-uppercase h-2rem dark-blue mb-half p4 f-1rem">
						Upload Your Photo
					</label>
					<input
						id='upload-photo'
						type='file'
						className='hidden'
						onChange={savePicture}
					/>
					<p className="f-small">
						Accepted file types: JPG, PNG, GIF
					</p>

				</div>
			</div>
		</>
	);
}