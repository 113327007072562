import React, {useEffect, useState} from "react";
import {getUser, getUsers} from "../../../Actions/DashboardApi";

export default function UserCircle({id, action}) {
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(()=> {
		if(id){
			getData();
			setLoading(false)
		}
	}, [loading]);

	useEffect(()=> {
		console.log("IUD changed", id);
		setLoading(true);
	}, [id]);

	const getData = async () => {
		const u = await getUser(id);
		console.log("Circle", id, u);
		setUser(u);
		setLoading(false);
	}

	if(loading){
		return "..."
	}

	return (
		<div className="flex align-center" title={`${user.firstName} ${user.lastName}`} onClick={action ? action : () => "" }>
			<div className="mr-quarter cursor-pointer w-3rem h-3rem p5 t-white br-50 flex align-center justify-center">
				{user.firstName?.charAt(0)} {user.lastName?.charAt(0)}
			</div>
			{user.firstName} {user.lastName}
		</div>
	)
}