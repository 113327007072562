import React, {useState} from "react";
import IconX from "../../UI/Icons/IconX";
import {useRecoilState, useRecoilValue} from "recoil";
import {quat, quatActive} from "../../../Recoil/selectors";
import {noteRecipientAtom, userAtom} from "../../../Recoil/atoms";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import Textarea from "../../UI/Textarea";
import Button from "../../UI/Button";
import IconBack from "../../UI/Icons/IconBack";
import UsersList from "../EnterData/UsersList";
import UserCircle from "../EnterData/UserCircle";
import {saveNote} from "../../../Actions/DashboardApi";
import NotesList from "./NotesList";

const tabs = [
	{title: "My Files"},
	{title: "Shared with Me"},
	{title: "Request Access"},
	{title: "Share notifications"}

]

const Tab = ({title, selected, onClick}) => {
	return (
		<a className={`h6 f-light f-uppercase mr inline-flex pver phor b-bottom-3 ${selected === title ? 'b-green' : 'b-white'}`} onClick={onClick} >
			{title}
		</a>
	)
}

export default function Notes() {

	const [quater, setQuater] = useRecoilState(quat);
	const [createMode, setCreateMode] = useState(false);
	const user = useRecoilValue(userAtom);

	const [qActive, setQActive] = useRecoilState(quatActive);

	const openDoc = () => {
		setQActive("");
	}

	const [error, setError] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [type, setType] = useState('');
	const [recipient, setRecipients] = useState('');

	const [recSelector, setRecSelector] = useState(false);

	const [org, setOrg] = useState({
		org : 'Mercy Hospital',
		fac : 'Miami',
		dep : 'Radiolgy'
	});

	const orgChange = (e) => {
		setOrg({...org, [e.target.name] : e.target.value});
	}

	const messageType = [
		{id: 'mt1', title: 'Urgent Notice'},
		{id: 'mt2', title: 'Video Message'},
		{id: 'mt3', title: 'Non Urgent email'},
		{id: 'mt4', title: 'Report'},
		{id: 'mt5', title: 'Memo'},
		{id: 'mt6', title: 'Voice Message'}
	]
	
	const addRecipient = (type, u) => {
		setRecipients(u.id);
		setRecSelector(false);
	}

	const saveMessage = async () => {
		setError('')
		if(!subject){
			setError("Please add a subject to your message");
			return
		}
		if(!message){
			setError("Please add a message");
			return
		}

		if(!recipient){
			setError("Please add a recipiento for the note");
			return
		}

		const data = {
			subject,
			from: user.id,
			to: [recipient],
			message,
			type
		}

		await saveNote(data);
		setCreateMode(false);

	}

	const newNote = () => {
		setCreateMode(true);
		setMessage("");
		setSubject("");
		setRecipients("");
		setType("");
		setRecSelector(false);
	}

	return (
		<div className="white p mhor-auto max-w-800px w-100 br">
			<div className="flex mb align-center p6  p-half br-quarter">
				<div className="grows flex align-center cursor-move">
					<h3 className="h6 grows t-white">
						Notes
					</h3>
					<a onClick={() => setQuater(false)}>
						<IconX />
					</a>

				</div>
			</div>

			{createMode ?
				<div>
					<div className='col-gap-3'>
						<div>
							<div className="pver">
								<a className="p p6 t-white br-quarter inline-flex align-center" onClick={()=>setCreateMode(false)}><IconBack width={15} height={15} className="mr-half" color="p4" /> Back to all notes</a>
							</div>
							<h4 className="mb mt">Create a new note</h4>
							<Input label='Subject*' className='mb' value={subject} onChange={(e)=>setSubject(e.target.value)} />

							<div className="mb">
								<label className="f-small mb-quarter block f-700">Recipient</label>
								<div className="flex">
									{recipient ?
										<UserCircle id={recipient} action={()=>setRecSelector(!recSelector)} />
										:
										<div className="cursor-pointer w-3rem h-3rem grey300 t-black br-50 flex align-center justify-center" onClick={()=>setRecSelector(!recSelector)}>
											+
										</div>
									}

								</div>
							</div>
							{recSelector &&
								<UsersList title="recipient" action={addRecipient} />
							}

							<Select options={messageType} onChange={(e)=>setType(e.target.value)} label='Message Type' className='mb' />
							{/*<Input label='Organization' className='mb' name='org' value={org.org} onChange={orgChange} />
							<Input label='Facility' className='mb' name='fac' value={org.fac} onChange={orgChange} />
							<Input label='Department' className='mb' name='dep' value={org.dep} onChange={orgChange} />*/}
						</div>
						<div className='col-span2 flex direction-column'>
							<div className='grey100 br grows mt'>
								{/*<div className='phor-2 pver grey300 flex'>
									<img src={tools} className='h-2rem' />
								</div>*/}
								<div className='p-2'>
									<Textarea value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Write a message...'  height='300' />
								</div>

							</div>
							<div className='flex justify-end mt align-center'>
								{error &&
									<span className='t-red f-medium mr'>
									{error}
								</span>
								}
								<Button onClick={saveMessage}>
									Save note
								</Button>
							</div>
						</div>


					</div>

				</div>
				:
				<div>
					<div className="pver right">
						<a className="p p6 t-white br-quarter" onClick={newNote}>Create a new note</a>
					</div>

					<div>

						<NotesList />

					</div>

				</div>
			}

		</div>
	)
}