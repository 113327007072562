import React, {useState} from "react";
import logo from "../../Styles/Img/logo.png";
import UserMode from "./UserMode";
import {useRecoilState, useRecoilValue} from "recoil";
import {userAtom, userModeAtom} from "../../Recoil/atoms";
import {Link, NavLink} from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import HeaderNotifications from "./HeaderNotifications";

export default function Header(){

    const [user, setUser] = useRecoilState(userAtom);
    const [userMenu, setUserMenu] = useState(false);
    const mode = useRecoilValue(userModeAtom);

    const logoutUser = async ()=>{
        setUser(null);
        window.localStorage.clear();
        window.location="/login";
    }

    return(
        <div>
            <header className={`${mode === 'patient' ? 'dark-green' : 'space-blue'} flex sh-20-black10 relative z-1000`}>
                {/*<div className="p flex align-center">
                    <IconMenu color="white" />
                </div>*/}
                <div className="flex align-center pver">
                    <Link to="/dashboard">
                        <img src={logo} alt="PX6 Logo" className="h-40px ml-2" />
                    </Link>
                </div>
                <div className="flex f-medium t-white phor-2">
                    <NavLink to="/dashboard" className={`t-white f-titillium f-700 flex align-center b-bottom-5 mr b-white10 f-1rem`} activeStyle={{borderColor : "white"}} exact>
                        HOME
                    </NavLink>
                    <NavLink to="/encounters" className="t-white f-titillium f-700 flex align-center b-bottom-5 b-white10 f-1rem" activeStyle={{borderColor : "white"}} exact>
                        ENCOUNTERS
                    </NavLink>
                </div>

                <HeaderSearch />

                <div className="grows">
                </div>
                <div className="phor flex align-center f-1rem f-titillium t-white relative cursor-pointer" onClick={()=>setUserMenu(!userMenu)}>
                    Welcome, {user?.firstName ? user?.firstName : user?.email}
                    <div className="f-uppercase h-2halfrem w-2halfrem br-50 white20 ml shrinks-0 flex justify-center align-center">
                        {user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
                    </div>
                    {userMenu &&
                    <div className="br-quarter white p-half f-small absolute top-4 right-1">
                        <Link to="/onboarding/user-type" className="block pver-half f-uppercase t-p3">Edit Profile</Link>
                        <a className="block pver-half f-uppercase t-p3" onClick={logoutUser}>Logout</a>
                    </div>
                    }
                </div>

                <HeaderNotifications />

                <UserMode />

            </header>
            {/*<div className="p5 p flex align-center justify-start f-1rem t-white">
                <span className='mr-2'>
                    <strong>Organization:</strong> Mercy Hospital
                </span>
                <span className='mr-2'>
                    <strong>Facility:</strong> Miami
                </span>
                <span>
                    <strong>Department:</strong> Radiology
                </span>
            </div>*/}
        </div>
    )
}
