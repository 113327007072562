import React, {useEffect} from "react";
import {Route, Switch} from "react-router-dom";

import UserType from "../Components/Onboarding/UserType";
import P1 from "../Components/Onboarding/P1";
import P2 from "../Components/Onboarding/P2";
import P3 from "../Components/Onboarding/P3";
import P4 from "../Components/Onboarding/P4";
import P5 from "../Components/Onboarding/P5";
import GU1 from "../Components/Onboarding/GU1";
import GU2 from "../Components/Onboarding/GU2";
import GU3 from "../Components/Onboarding/GU3";
import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {getMe, updateMe} from "../Actions/UserApi";

export default function Onboarding(){

    const [user, setUser] = useRecoilState(userAtom);

    const updateUser= async (data)=>{
        try {
            await updateMe(data, user.id);
            const u = await getMe();
            setUser(u);
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(()=>{


    },[user]);

    const addData =(url,data)=>{

    }
    const uploadData = async (mutData,filesData)=>{

    }
    const handleFinish = async (data) =>{

    }

    return(
        <div>

            <Switch>
                <Route path="/onboarding">
                    <Route exact path="/onboarding/user-type">
                        <UserType updateUser={updateUser}/>
                    </Route>
                    <Route path="/onboarding/g">
                        <Route exact path="/onboarding/g/1" >
                            <GU1 next="g/2" updateUser={updateUser}/>
                        </Route>
                        <Route exact path="/onboarding/g/2" >
                            <GU2 next="g/3" updateUser={updateUser}/>
                        </Route>
                        <Route exact path="/onboarding/g/3" >
                            <GU3 handleFinish={handleFinish}  updateUser={updateUser}/>
                        </Route>
                    </Route>

                    <Route path="/onboarding/b">
                        <Route exact path="/onboarding/b/1">
                            <GU1 professional title="Professional" subtitle="Step 1/6" next="b/2" updateUser={updateUser}/>
                        </Route>
                        <Route exact path="/onboarding/b/2">
                            <GU2 title="Professional" subtitle="Step 2/6" back="/onboarding/b/1" next="b/3" updateUser={updateUser} />
                        </Route>
                        <Route exact path="/onboarding/b/3">
                            <P1 title="Professional" subtitle="Step 3/6" back="/onboarding/b/2" next="b/4" updateUser={updateUser}/>
                        </Route>
                        <Route exact path="/onboarding/b/4">
                            <P3 title="Professional" subtitle="Step 4/6" back="/onboarding/b/3" next="/onboarding/b/5"/>
                        </Route>
                        <Route exact path="/onboarding/b/5">
                            <GU3 title="Professional" subtitle="Step 5/6" back="/onboarding/b/4" next="b/6" updateUser={updateUser}/>
                        </Route>
                        <Route exact path="/onboarding/b/6">
                            <P5 title="Professional" subtitle="Step 6/6" back="/onboarding/b/5" next="/dashboard" handleFinish={handleFinish}/>
                        </Route>
                    </Route>

                </Route>

            </Switch>

        </div>
    )
}
