import React, {useEffect, useRef, useState} from "react";
import Input from "../UI/Input";
import SearchResults from "./SearchResults";
import IconNotifications from "../UI/Icons/IconNotifications";
import NotificationsList from "./NotificationsList";

export default function HeaderNotifications(){

    const [results, setResults] = useState(false);

    /**
     * Reference container div for clicking outside
     * @type {React.MutableRefObject<null>}
     */
    const ref = useRef(null);


    /**
     * Trigger clicking outside
     * @type {React.MutableRefObject<null>}
     */
    const closeElement = () => {
        setResults(false)
    }

    /**
     * Listen when a click outside occurs to close the search results
     * @type {React.MutableRefObject<null>}
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeElement && closeElement();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ closeElement ]);


    return(
        <div className="flex align-center relative" ref={ref}>

            <a onClick={()=>setResults(true)}>
                <IconNotifications alertColor="red" />
            </a>


            {results &&
                <div className="top-4 right-0 white p br-half z-2000 absolute sh-black10 b b-grey200">
                    <NotificationsList closeElement={closeElement} />
                </div>
            }
        </div>
    )
}