import React from "react";
import Input from "../../UI/Input";

export default function FormFile({file}) {

	const date = () => {
		console.log(file)
		if (file){
			let d = new Date(file?.createdAt);
			return d.toLocaleDateString()
		}

	}

	return(
		<div>
			<Input label="File Name" className="mb" value={file?.name} disabled />
			<div className="grid2 col-gap">
				<Input label="File Date" className="mb"  value={date()} disabled />
				<Input label="File. #" className="mb" value={file?.number} disabled />
			</div>
			<Input label="File Type / Category" className="mb" value={file?.type} disabled />
		</div>
	)
}