import React from "react";
import IconReception from "../../../UI/Icons/IconReception";
import IconWaitingRoom from "../../../UI/Icons/IconWaitingRoom";
import IconFileRoom from "../../../UI/Icons/IconFileRoom";
import {useRecoilState, useRecoilValue} from "recoil";
import {adPopAtom, modulesAtom, notePopAtom, noticePopAtom, roomAtom, searchAtom} from "../../../../Recoil/atoms";
import {secCountSelector, tertCountSelector} from "../../../../Recoil/selectors";
import IconTarget from "../../../UI/Icons/IconTarget";
import IconDirectory from "../../../UI/Icons/IconDirectory";
import IconNotice from "../../../UI/Icons/IconNotice";

export default function InfoManagementStates({simple}){

    const [modules, setModules] = useRecoilState(modulesAtom);
    const secCount = useRecoilValue(secCountSelector);
    const tertCount = useRecoilValue(tertCountSelector);
    const [room, setRoom] = useRecoilState(roomAtom);
    const [pop, setPop] = useRecoilState(adPopAtom);
    const [noticePop, setNoticePop] = useRecoilState(noticePopAtom);
    const [search, setSearch] = useRecoilState(searchAtom);

    let na = "InfoManagement";

    /**
     * @param pos
     * Changes position of a module. If the amount of secondary and tertiary is > 4 (80% of the screen) the count will reset and only allow the new big module.
     */
    const maketertiary = (room)=>{

        setRoom(room);
        setSearch(false);

        const pos = "tertiary";
        let mpos = 2;
        let mod = [...modules];

        /**
         * Secondary is 1/5, tertiary is 2/5. The sum of tertiary + secondary should not be greater than 4
         */
        if(mpos + secCount + (tertCount * 2) > 4){
            const tmod = mod.map(m => {

                if (m.position==='tertiary') {
                    return {...m, position : 'primary'}
                }
                if (m.position==='secondary') {
                    return {...m, position : 'primary'}
                }
                else {
                    return m
                }
            });
            finalMap(tmod, pos);

        }
        else {
            finalMap(mod, pos);
        }
    }

    /**
     * Maps array and sets modules atom
     * @param mod
     * @param pos
     */
    const finalMap = (mod, pos)=>{
        const umod = mod.map(m => {

            if (m.name===na) {
                return {...m, position : pos}
            }
            else {
                return m
            }

        });
        setModules(umod);
    }

    return(
        <div>
            <div className="grid3 center col-gap row-gap pb">
                <a className="f-1rem f-titillium center" onClick={()=>maketertiary("Reception")}>
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconReception className="h-3rem block b-0" color="p3" />
                    </div>
                    Reception
                </a>
                {!simple &&
                    <a className="f-1rem f-titillium center" onClick={() => setSearch(true)}>
                        <div className="p br-half flex justify-center min-w-50px">
                            <IconDirectory className="h-3rem block b-0" color="p2"/>
                        </div>
                        Directory
                    </a>
                }
                <a className="f-1rem f-titillium center mhor lh-100" onClick={()=>maketertiary("Waiting Room")}>
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconWaitingRoom className="h-3rem block b-0" color="p3" />
                    </div>
                    Waiting Room
                </a>
                {simple &&
                    <a className="f-1rem f-titillium center mhor lh-100" onClick={()=>maketertiary("File Room")}>
                        <div className="p br-half flex justify-center min-w-50px">
                            <IconFileRoom className="h-3rem block b-0" color="p3" />
                        </div>
                        File Room
                    </a>
                }
            </div>
            {!simple &&
                <div className="grid2 center col-gap row-gap pb">
                    <a className="f-1rem f-titillium center" onClick={()=>setNoticePop(true)}>
                        <div className="p br-half flex justify-center min-w-50px">
                            <IconNotice className="h-3rem block b-0" color="p2" />
                        </div>
                        Notice Board
                    </a>
                    <a className="f-1rem f-titillium center" onClick={()=>setPop(true)}>
                        <div className="p br-half flex justify-center min-w-50px">
                            <IconTarget className="h-3rem block b-0" color="p2" />
                        </div>
                        Ads
                    </a>

                </div>
            }
        </div>
    )
}
