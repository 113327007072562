import React, {useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {modulesAtom, userModeAtom} from "../../../Recoil/atoms";
import {quat, secCountSelector, tertCountSelector} from "../../../Recoil/selectors";
import PositionIcon from "./PositionIcon";
import IconExpand from "../../UI/Icons/IconExpand";
import IconCollapse from "../../UI/Icons/IconCollapse";
import IconDrag from "../../UI/Icons/IconDrag";
import IconOptions from "../../UI/Icons/IconOptions";
import IconPrimary from "../../UI/Icons/IconPrimary";
import IconSecondary from "../../UI/Icons/IconSecondary";
import IconTertiary from "../../UI/Icons/IconTertiary";
import IconQuaternary from "../../UI/Icons/IconQuaternary";


export default function ModuleHead ({title, name}){

    const [showList, setShowList] = useState(false);
    const [modules, setModules] = useRecoilState(modulesAtom);
    const secCount = useRecoilValue(secCountSelector);
    const tertCount = useRecoilValue(tertCountSelector);
    const mode = useRecoilValue(userModeAtom);
    const [ed, setEd] = useRecoilState(quat);

    let na = name ? name : title;
    const currentPosition = modules.filter((m) => m.name === na);

    /**
     * @param pos
     * Changes position of a module. If the amount of secondary and tertiary is > 4 (80% of the screen) the count will reset and only allow the new big module.
     */
    const switchPosition = (pos)=>{

        let mpos = (pos === 'secondary' ? 1 : pos === 'tertiary' ? 2 : 0);
        let mod = [...modules];

        if(pos === "primary"){
            setEd(false);
        }

        /**
         * Secondary is 1/5 of screen, tertiary = 2/5 of screen. The sum of tertiary + secondary should not be greater than 4
         */
        if(mpos + secCount + (tertCount * 2) > 4){
            const tmod = mod.map(m => {

                if (m.position==='tertiary') {
                    return {...m, position : 'primary'}
                }
                if (m.position==='secondary') {
                    return {...m, position : 'primary'}
                }
                else {
                    return m
                }
            });
            finalMap(tmod, pos);

        }
        else {
            finalMap(mod, pos);
        }
    }

    /**
     * Maps array and sets modules atom
     * @param mod
     * @param pos
     */
    const finalMap = (mod, pos)=>{
        const umod = mod.map(m => {

            let n = name ? name : title;
            if (m.name===n) {
                return {...m, position : pos}
            }
            else {
                return m
            }

        });
        setModules(umod);
    }

    return(
        <div className={`flex mb align-center ${mode === 'patient' ? 'p3' : 'p6'}  p-half br-quarter`}>
            <div className="grows flex align-center cursor-move">
                <h3 className="h6 grows t-white">{title}</h3>
            </div>

            <div className="f-small">

                <a onClick={()=> {setShowList(!showList)}}>
                    <IconOptions color="white" className="b-0" />
                </a>

                {showList &&
                <div className=" p-half b-grey300 br-half b absolute top right-1 white">
                    <p className="mb-half pb-half b-bottom b-grey200">
                        VIEW MODE
                    </p>
                    <a className='flex align-center pver-half' onClick={()=>{switchPosition('primary')}}>
                        <IconPrimary className="w-1rem h-1rem mr-half" /> Organization View
                    </a>
                    <a className='flex align-center pver-half' onClick={()=>{switchPosition('secondary')}}>
                        <IconSecondary className="w-1rem h-1rem mr-half" /> Facility View
                    </a>
                    <a className='flex align-center pver-half' onClick={()=>{switchPosition('tertiary')}}>
                        <IconTertiary className="w-1rem h-1rem mr-half" /> Department View
                    </a>
                    <a className='flex align-center pver-half' onClick={()=>{switchPosition('quaternary')}}>
                        <IconQuaternary className="w-1rem h-1rem mr-half" /> Office View
                    </a>
                </div>
                }
            </div>

        </div>
    )
}
