import React, {useEffect, useState} from "react";
import Input from "../UI/Input";
import Button from "../UI/Button";
import {Link, useHistory} from "react-router-dom";
import Error from "../UI/Error";
import {useRecoilState, useSetRecoilState} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {forgotPassword, getMe, loginPX6} from "../../Actions/UserApi";


export default function ForgotPassword(){
    const [newUser, setNewUser] = useState({"email": ""});
    const [requested, setRequested] = useState(false);
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const forgot = async () => {

        try {
            const u = await forgotPassword(newUser);
            console.log(u);
            setRequested(true);
        }
        catch (e){
            console.log("CATCHED", e.response);
            setError(e?.response?.data?.error?.message)
        }
    }


    return(
            <div className="max-w-600px mhor-auto pb-4">
                <p className="center mb-2">
                    If you forgot your password please add your email below. We will send you reset instructions.
                </p>
                {!requested ?
                    <div>
                        <Input label="Email" className="mb" name="email" value={newUser.email} onChange={updateUser}/>
                        <Button className="w-100" onClick={forgot}>
                            Forgot Password?
                        </Button>
                    </div>
                    :
                    <div>
                        <h3 className="center">
                            Thank you! We have sent you an email with instructions.
                        </h3>
                    </div>
                }

                <Error msg={error} className="t-red center pver f-small" />

                <div className="center f-titillium f-1rem mt-2">
                    Back to <Link to="/login" className="t-p3 f-underline">Login</Link>
                </div>
            </div>
            )
}
