import React from "react";

export default function AuthTitle({title, subtitle}){
    return(
        <hgroup className="center mb-2">
            <h1 className="h2 mb">
                {title}
            </h1>
            <p className="f-medium f-300">
                {subtitle}
            </p>
        </hgroup>
    )
}
