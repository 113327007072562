import React, {useState} from "react";
import Button from "../../UI/Button";
import Textarea from "../../UI/Textarea";

export default function RejectForm({rejectAccess, id}) {

	const [d, setD] = useState("");
	const [e, setE] = useState("");
	const [dv, setDv] = useState(false);

	const checkReject = () => {
		console.log("Checking e");
		if(d){
			rejectAccess(id, d);
			setDv(false);
		}
		else{
			console.log("ERR");
			setE("Please add a reject reason to continue");
		}

	}

	const updateD = (e) => {
		setD(e.currentTarget.value);
		setE("");
	}

	return (

		<div className="relative">
			<Button color="p5" onClick={()=>setDv(!dv)}>
				Reject
			</Button>
			{dv &&
				<div className="absolute top-3 left-0 w-150px p-half grey100 b-grey300 b br-half z-100">
					<label>Confirm rejection</label>
					<Textarea placeholder="Reject Reason" onChange={updateD} />
					<Button color="p5 mr-half" onClick={checkReject}>
						Reject Access
					</Button>
					<p className="pver-half t-red">
						{e}
					</p>
				</div>
			}
		</div>

	);
}