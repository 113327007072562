import axios from "axios";
import {apiUrl} from "../Recoil/atoms";

export const getRooms = async (params) => {

	try {
		const res = await axios.get(
			`${apiUrl}rooms`,
			{
				params,
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getAppointment = async (params, id) => {

	try {
		const res = await axios.get(
			`${apiUrl}appointments/${id}`,
			{
				params : {
					populate: '*',
					sort: 'time',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getAppointments = async (params) => {

	try {
		const res = await axios.get(
			`${apiUrl}appointments`,
			{
				params : {
					populate: '*',
					sort: 'time',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const updateAppointments = async (id, room) => {

	try {
		const res = await axios.put(
			`${apiUrl}appointments/${id}`,
			{
				data : {
					room
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const editAppointments = async (data, id) => {

	try {
		const res = await axios.put(
			`${apiUrl}appointments/${id}`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const deleteAppointments = async (id) => {

	try {
		const res = await axios.delete(
			`${apiUrl}appointments/${id}`,
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveAppointments = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}appointments`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveNote = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}notes`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getNotes = async (params) => {

	try {
		const res = await axios.get(
			`${apiUrl}notes`,
			{
				params : {
					populate: '*',
					sort: 'createdAt',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const getUsers = async (params) => {
	try {
		const res = await axios.get(
			`${apiUrl}users`,
			{
				params : {
					populate: '*',
					limit:10,
					sort: 'createdAt',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const getUser = async (id) => {
	try {
		const res = await axios.get(
			`${apiUrl}users/${id}`,
			{
				params : {
					populate: '*'
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const getFiles = async (params) => {
	try {
		const res = await axios.get(
			`${apiUrl}data-files`,
			{
				params : {
					populate: '*',
					sort: 'createdAt',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const findDocs = async (keyword) => {
	try {
		const res = await axios.get(
			`${apiUrl}data-documents`,
			{
				params : {
					'filters[title][$containsi]': keyword,
					populate : '*',
					sort: 'createdAt'
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getDocs = async (params) => {
	try {
		const res = await axios.get(
			`${apiUrl}data-documents`,
			{
				params : {
					...params,
					sort: 'createdAt'
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getDoc = async (id) => {
	try {
		const res = await axios.get(
			`${apiUrl}data-documents/${id}`,
			{
				params : {
					populate: '*'
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveDoc = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}data-documents`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const updateDoc = async (data, id) => {

	try {
		const res = await axios.put(
			`${apiUrl}data-documents/${id}`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveSet = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}data-sets`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const getShares = async (params) => {

	try {
		const res = await axios.get(
			`${apiUrl}document-shares`,
			{
				params : {
					'populate[0]': 'accesses',
					'populate[1]': 'doc.owner',
					sort: 'createdAt:asc',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveShare = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}document-shares`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const updateShare = async (data, id) => {

	try {
		const res = await axios.put(
			`${apiUrl}document-shares/${id}`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const deleteShare = async (id) => {

	try {
		const res = await axios.delete(
			`${apiUrl}document-shares/${id}`,
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};


export const getDataBlocks = async (params) => {

	try {
		const res = await axios.get(
			`${apiUrl}data-sets`,
			{
				params : {
					'populate[0]': 'customFields',
					//'populate[1]': 'doc.owner',
					'populate[2]': 'doc',
					'populate[3]': 'files',
					sort: 'createdAt:asc',
					...params
				},
				headers : {
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveDataBlocks = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}data-sets`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const updateDataBlocks = async (data, id) => {

	try {
		const res = await axios.put(
			`${apiUrl}data-sets/${id}`,
			{
				data : {
					...data
				}
			},
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const deleteDataBlocks = async (id) => {

	try {
		const res = await axios.delete(
			`${apiUrl}data-sets/${id}`,
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};



export const uploadFiles = async (data) => {

	try {
		const res = await axios.post(
			`${apiUrl}upload`,
			data,
			{
				headers : {
					withCredentials : true,
					Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
				}
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};