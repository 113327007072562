import React from "react";
import ModuleHead from "../../Head/ModuleHead";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../../../Recoil/atoms";

import ModuleBox from "../../Box/ModuleBox";
import InfoManagementPrimary from "./InfoManagementPrimary";
import InfoManagementSecondary from "./InfoManagementSecondary";
import InfoManagementTertiary from "./InfoManagementTertiary";


export default function InfoManagement (){
    const modules = useRecoilValue(modulesAtom);

    const result = modules.filter(m => m.name === "InfoManagement");

    if (result[0].position === 'primary') {
        return (
            <ModuleBox title="InfoManagement">
                <ModuleHead name="InfoManagement" title="Information Management" />
                <InfoManagementPrimary />
            </ModuleBox>
        )
    }
    else if (result[0].position === 'secondary') {
        return (
            <ModuleBox title="InfoManagement">
                <ModuleHead name="InfoManagement" title="Information Management" />
                <div className="b b-grey200 phor pver br-half mb ">
                    <InfoManagementPrimary />
                </div>
                <div className="b b-grey200 grey100 phor pver br-half mb ">
                    <InfoManagementSecondary />
                </div>
            </ModuleBox>
        )
    }
    else {
        return (
            <ModuleBox title="InfoManagement">

                <ModuleHead name="InfoManagement" title="Information Management" />
                <div className="mb-2 flex">
                    <h3 className="mb-half grows">Test Department <br /> <span className="h6">ID: WER2345</span> </h3>
                    <h3 className="mb-half right">Test Professional <br /> <span className="h6">ID: PRO2345</span> </h3>
                </div>

                <div className="flex col-gap ">
                    <div className="flex direction-column">
                        <div className="b b-grey200 grey100 phor pver br-half mb grows">
                            <InfoManagementSecondary />
                        </div>
                    </div>
                    <div className="grows">
                        <InfoManagementTertiary />
                    </div>
                </div>

            </ModuleBox>
        )
    }
}
