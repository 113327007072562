import React, {useState} from 'react'

export default function Select({label, options, className, width, name, selected, ...props}){

    const [defaultClass] = useState(`f-400 h-3rem phor b b-grey500 br-half f-1rem ${width ? width : "w-100"}`)

    return(
        <div className={className}>
            {label &&
                <label className="f-small block f-700">{label}</label>
            }
            <div className="relative">
                <select className={defaultClass} placeholder={label} name={name} {...props}>
                    <option value="">
                        Select one...
                    </option>
                    {options?.map( ({label, title, id}) => {
                        return(
                            <option
                                value={id ? id : label}
                                key={id || label}
                                selected={id === selected}
                            >
                                {title ? title : label}
                            </option>
                        )
                    })}
                </select>
            </div>

        </div>
    )

}
