import React from "react";

export default function IconSecondary({className, color}){
    return(
        <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4286 1.35714H2.71429C1.96476 1.35714 1.35714 1.96476 1.35714 2.71429V16.2857C1.35714 17.0352 1.96476 17.6429 2.71428 17.6429H24.4286C25.1781 17.6429 25.7857 17.0352 25.7857 16.2857V2.71429C25.7857 1.96475 25.1781 1.35714 24.4286 1.35714ZM2.71429 0C1.21523 0 0 1.21523 0 2.71429V16.2857C0 17.7848 1.21522 19 2.71428 19H24.4286C25.9276 19 27.1429 17.7848 27.1429 16.2857V2.71429C27.1429 1.21523 25.9276 0 24.4286 0H2.71429Z" className={color ? color : 'grey800'} />
            <path d="M9.5 4.0715C9.5 3.32197 10.1076 2.71436 10.8571 2.71436H17.6429C18.3924 2.71436 19 3.32197 19 4.0715V14.9286C19 15.6782 18.3924 16.2858 17.6429 16.2858H10.8571C10.1076 16.2858 9.5 15.6782 9.5 14.9286V4.0715Z" className={color ? color : 'grey800'} />
        </svg>

    )
}
