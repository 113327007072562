import React from "react";

export default function IconQuaternary({color, className}){
    return(
        <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4286 1.35714H2.71429C1.96476 1.35714 1.35714 1.96476 1.35714 2.71429V16.2857C1.35714 17.0352 1.96476 17.6429 2.71428 17.6429H24.4286C25.1781 17.6429 25.7857 17.0352 25.7857 16.2857V2.71429C25.7857 1.96475 25.1781 1.35714 24.4286 1.35714ZM2.71429 0C1.21523 0 0 1.21523 0 2.71429V16.2857C0 17.7848 1.21522 19 2.71428 19H24.4286C25.9276 19 27.1429 17.7848 27.1429 16.2857V2.71429C27.1429 1.21523 25.9276 0 24.4286 0H2.71429Z"  className={color ? color : "black"} />
            <path d="M3 4.35714C3 3.60761 3.60761 3 4.35714 3H22.6429C23.3924 3 24 3.60761 24 4.35714V14.6429C24 15.3924 23.3924 16 22.6429 16H4.35714C3.60761 16 3 15.3924 3 14.6429V4.35714Z" className={color ? color : "black"}/>
        </svg>

    )
}
