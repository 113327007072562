import React from "react";

export default function IconCollapse({color, className}){
    return(
        <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M3.54373 0.00117385L43.448 0.00117733C43.4811 0.028361 43.5179 0.0508745 43.5572 0.0680883C45.1728 0.520025 45.997 1.59411 45.997 3.27039C45.997 16.0827 45.997 28.8958 45.997 41.7097C46.0038 41.9451 45.9909 42.1807 45.9583 42.414C45.6788 43.9999 44.4436 45 42.7435 45L4.26347 45C4.16132 45 4.05917 45 3.95702 45C2.66547 44.9143 1.75669 44.2863 1.23067 43.1066C1.13674 42.8964 1.07334 42.6722 0.995846 42.4551L0.99585 2.54963C1.2706 2.02609 1.49134 1.46498 1.83184 0.987221C2.23574 0.419071 2.90265 0.214817 3.54373 0.00117385ZM26.3056 2.81375L4.40789 2.81375C3.80556 2.81375 3.80556 2.81374 3.80556 3.43237L3.80556 41.5676C3.80556 42.1863 3.80556 42.1874 4.40554 42.1874L42.5873 42.1874C43.1803 42.1874 43.1814 42.1874 43.1814 41.6005C43.1814 34.454 43.1814 27.3076 43.1814 20.1611L43.1814 19.6833L29.6307 19.6833C27.5771 19.6833 26.3079 18.4214 26.3079 16.3801C26.3079 12.0313 26.3079 7.68175 26.3079 3.33142L26.3056 2.81375ZM36.1073 2.81375L29.6918 2.81375C29.127 2.81375 29.1176 2.82197 29.1176 3.37251C29.1176 7.6935 29.1176 12.0145 29.1094 16.3343C29.1094 16.7628 29.2468 16.8848 29.6659 16.8837C33.9868 16.8711 38.3072 16.8711 42.6272 16.8837C43.0417 16.8837 43.1897 16.7663 43.1885 16.339C43.1744 12.0027 43.1744 7.66728 43.1885 3.3326C43.1885 2.92527 43.0558 2.80319 42.6554 2.80554C40.4739 2.82197 38.2912 2.81375 36.1096 2.81375L36.1073 2.81375Z" className={color ? color : "black"}/>
            <path d="M13.5567 34.4538L14.0663 34.4538C15.5598 34.4538 17.0533 34.4455 18.5468 34.4538C18.7342 34.4468 18.921 34.4774 19.0963 34.5439C19.2716 34.6103 19.4318 34.7112 19.5674 34.8405C19.7031 34.9699 19.8114 35.1251 19.886 35.297C19.9607 35.4689 20.0001 35.6541 20.002 35.8415C20.0039 36.0289 19.9682 36.2148 19.897 36.3881C19.8258 36.5615 19.7206 36.7189 19.5876 36.8509C19.4546 36.983 19.2965 37.0871 19.1225 37.157C18.9486 37.2269 18.7624 37.2613 18.575 37.2581C15.822 37.2667 13.0687 37.2632 10.3149 37.2475C10.0086 37.2522 9.70527 37.1864 9.42847 37.055C8.93651 36.7968 8.72399 36.3343 8.72399 35.7767C8.72399 33.0228 8.72399 30.2701 8.72399 27.5162C8.72399 26.6511 9.31105 26.0243 10.1095 26.0137C10.9243 26.0031 11.5302 26.6382 11.5337 27.5233C11.5337 28.9871 11.5337 30.4579 11.5337 31.917L11.5337 32.4288C11.7086 32.268 11.8249 32.1682 11.9341 32.0603C15.4095 28.5942 18.8811 25.1255 22.3487 21.654C22.7843 21.2173 23.2645 20.9755 23.8856 21.1574C24.1092 21.2202 24.3134 21.3378 24.4799 21.4996C24.6464 21.6614 24.7699 21.8621 24.8391 22.0837C24.9082 22.3053 24.9209 22.5406 24.876 22.7684C24.8311 22.9961 24.73 23.209 24.5819 23.3878C24.4765 23.5109 24.3649 23.6284 24.2473 23.7399L13.9336 34.0558C13.8244 34.1568 13.7235 34.273 13.5567 34.4538Z" className={color ? color : "black"}/>
        </svg>


    )
}
