import React from 'react'

export default function ProfessionalInRoom(){
	return (
		<div className="b b-grey200 white p br-half mb">

			<div>
				<h6 className="f-uppercase">
					Professionals in the room
				</h6>
				<p className="f-1rem t-grey500">
					Drag and drop user below to assign to professional
				</p>
				<div className="grid2 col-gap">
					<div className="f-medium t-black left pt grey100 br-quarter b b-grey200 p mt">
						<h5 className="t-p6">Dr. Jhon Stone</h5>
						<h6>Radiology</h6>
					</div>
					<div className="f-medium t-black left pt b br-quarter b-grey200 p mt">
						<h5 className="t-p6">Armand Klativa</h5>
						<h6>Assistant</h6>
					</div>

				</div>

			</div>

		</div>
	)
}