import React, {useState} from "react";
import Button from "../UI/Button";
import IconCaret from "../UI/Icons/IconCaret";
import {useRecoilState, useRecoilValue} from "recoil";
import {oneOnOnePatient, userAtom, userModeAtom} from "../../Recoil/atoms";

export default function UserMode(){

    const [openModes, setOpenModes] = useState(false);
    const [mode, setMode] = useRecoilState(userModeAtom);
    const user = useRecoilValue(userAtom);

    const changeMode = (m)=> {
        setMode(m);
        setOpenModes(false)

    }

    return(
        <div className="phor flex align-center">
            <div className="relative flex align-center">
                <Button height="h-2halfrem" className={`${mode === 'patient' ? 'p3' : 'p6'} f-uppercase z-900 relative`} onClick={()=>{setOpenModes(!openModes)}}>
                    {mode}
                    <IconCaret className="ml-half" />
                </Button>
                {openModes &&
                <div className="absolute top-2half white right-0 z-500 sh-4-black10 f-1rem f-titillium f-uppercase">
                    <a className="block t-grey700 left nowrap phor pver-half hover:t-grey800 hover:grey100" onClick={()=>{changeMode('patient')}}>
                        My Personal View
                    </a>
                    {user?.type?.id === 1 &&
                        <a className="block t-grey700 left nowrap phor pver-half hover:t-grey800 hover:grey100"
                           onClick={() => {
                               changeMode('professional')
                           }}>
                            My Professional View
                        </a>
                    }
                </div>
                }
            </div>

        </div>
    )
}
