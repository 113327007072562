export default function IconConference({className, color}){
	return(
		<svg width="58" height="46" viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M3.48669 45.5738C20.9559 45.6817 38.3002 45.7883 55.7769 45.8975C55.5556 45.5876 55.4072 45.373 55.2527 45.1648C49.9579 38.0436 44.6607 30.9249 39.3708 23.8C39.1297 23.475 38.8899 23.3144 38.4609 23.3157C32.5295 23.3332 26.5968 23.3345 20.6654 23.3194C20.1894 23.3194 19.8853 23.4537 19.5923 23.8489C14.3482 30.9299 9.0893 37.9997 3.83408 45.0719C3.73889 45.1999 3.65235 45.3329 3.48669 45.5738Z" className={color ? color : "black"}/>
			<path d="M30.1338 14.5068C29.8952 13.0577 29.0793 11.6714 29.9187 10.137H28.2226C28.9557 11.64 28.1695 13.0377 27.8641 14.5357C27.7887 14.3475 27.7034 14.1643 27.6404 13.9723C27.2559 12.8056 26.8677 11.6413 26.5018 10.4695C26.3992 10.142 26.2508 10.0015 25.8948 10.0228C24.8786 10.083 24.0899 10.5573 23.4841 11.3515C22.149 13.1016 20.8337 14.8681 19.5047 16.6233C19.2797 16.9207 19.0263 17.1967 18.7865 17.4828C18.0002 18.4225 18.0435 19.6219 18.9323 20.4537C19.4763 20.963 20.0573 21.4323 20.6396 21.8965C20.7706 22.0006 20.9746 22.0558 21.1452 22.0558C25.5313 22.0621 29.9163 22.0583 34.3024 22.0558C34.342 22.0558 34.3828 22.037 34.5101 22.0056V17.5731L34.6547 17.5292C35.1876 18.2318 35.7092 18.9431 36.2569 19.6332C37.3287 20.9856 39.2189 21.3344 40.5083 20.3997C40.9719 20.0635 41.3761 19.6419 41.7915 19.2731C40.5726 17.7337 40.4045 17.3435 40.0385 15.2809C39.5156 15.7301 39.0248 16.1516 38.4821 16.6196C37.6897 15.5732 36.9158 14.552 36.1444 13.532C35.5807 12.7867 35.0367 12.0239 34.4495 11.2988C33.9031 10.6238 33.2207 10.1483 32.3442 9.98267C31.9041 9.89987 31.6705 9.99271 31.5258 10.4582C31.1673 11.6099 30.7594 12.7466 30.3687 13.8883C30.297 14.0965 30.2142 14.3011 30.1363 14.5081L30.1338 14.5068ZM23.3951 17.1779V19.6859C22.9686 19.322 22.6237 19.0272 22.243 18.7035C22.6225 18.2016 22.9624 17.75 23.3951 17.1779Z" className={color ? color : "black"}/>
			<path d="M50.5156 15.0827C50.4983 12.3866 48.3695 10.2675 45.6918 10.2813C43.0772 10.2951 40.9744 12.5158 41.0028 15.232C41.03 17.8228 43.212 19.9556 45.8093 19.9331C48.44 19.9092 50.5317 17.7525 50.5144 15.0827H50.5156Z" className={color ? color : "black"}/>
			<path d="M53.2178 30.8497C55.8399 30.866 57.965 28.7407 57.9996 26.0658C58.0342 23.4349 55.8795 21.2105 53.2871 21.2017C50.6539 21.1929 48.509 23.317 48.4757 25.9655C48.4423 28.6553 50.5562 30.8334 53.2191 30.8497H53.2178Z" className={color ? color : "black"}/>
			<path d="M5.7478 20.4425C3.11463 20.46 0.977183 22.6293 0.998199 25.2627C1.02045 27.9777 3.19004 30.1268 5.87513 30.0942C8.42548 30.0641 10.532 27.8597 10.5283 25.2276C10.5246 22.5866 8.36614 20.4262 5.7478 20.4425Z" className={color ? color : "black"}/>
			<path d="M13.248 19.0925C15.8799 19.0812 17.9988 16.9308 17.9939 14.2773C17.9877 11.5911 15.8305 9.41563 13.1924 9.43445C10.5617 9.45327 8.47119 11.6438 8.48726 14.3663C8.50333 16.9772 10.6457 19.105 13.2492 19.0937L13.248 19.0925Z" className={color ? color : "black"}/>
			<path d="M1.7535 45.6576C1.96366 45.4267 2.11943 45.2837 2.24429 45.1156C4.60178 41.9489 6.95804 38.781 9.31059 35.6106C10.2575 34.3334 9.97198 32.6573 8.63437 31.7803C8.31542 31.572 7.92478 31.3751 7.55885 31.36C6.34982 31.3098 5.12842 31.2534 3.92927 31.3713C2.6745 31.4942 1.69416 32.6171 1.68056 33.8943C1.63977 37.6971 1.65831 41.501 1.65707 45.305C1.65707 45.3778 1.6954 45.4506 1.7535 45.6551V45.6576Z" className={color ? color : "black"}/>
			<path d="M57.3594 45.6542C57.3594 43.6932 57.3594 41.7322 57.3594 39.7713C57.3594 38.2256 57.3594 36.6812 57.3532 35.1355C57.3445 33.3163 56.154 32.1144 54.3739 32.1156C53.5505 32.1156 52.7272 32.1043 51.9051 32.1194C50.9322 32.137 50.1422 32.5623 49.6156 33.379C49.1446 34.1092 48.8961 34.606 49.628 35.5608C52.0275 38.6898 54.3529 41.8765 56.7079 45.0407C56.876 45.2665 57.0503 45.4886 57.2209 45.7119L57.3594 45.6529V45.6542Z" className={color ? color : "black"}/>
			<path d="M24.6844 4.50266C24.7042 6.96798 26.676 8.90635 29.1448 8.89004C31.5122 8.87373 33.4666 6.82746 33.4431 4.391C33.4184 1.93948 31.412 -0.0302583 28.9729 -0.00014761C26.5882 0.0299631 24.6647 2.04989 24.6844 4.50266Z" className={color ? color : "black"}/>
			<path d="M9.80751 20.9946C10.9041 22.2529 11.4974 23.4762 11.5679 24.9027C11.6668 26.895 10.8855 28.5135 9.40821 29.7994C8.80493 30.3239 8.85808 30.5397 9.39832 31.1168C9.99171 31.7491 10.464 32.4981 10.9968 33.207C11.7113 32.2585 12.5198 31.1883 13.3258 30.1156C14.4842 28.5712 15.6289 27.0154 16.8021 25.4823C17.6316 24.3996 17.3992 23.2165 17.037 22.1049C16.6773 21.0008 15.7488 20.44 14.6115 20.3735C13.6472 20.3171 12.6706 20.292 11.7138 20.3861C11.113 20.445 10.5357 20.7524 9.80875 20.9946H9.80751Z" className={color ? color : "black"}/>
			<path d="M48.1124 33.458C48.6761 32.8344 49.1879 32.2523 49.7182 31.6852C50.0075 31.3766 49.9556 30.9927 49.555 30.6464C48.0641 29.3579 47.3447 27.7081 47.4361 25.7321C47.5017 24.3081 48.0913 23.0773 49.0606 21.987C48.5352 21.4626 47.9232 21.2167 47.2235 21.2054C46.2778 21.1904 45.3296 21.1828 44.3839 21.2054C42.6148 21.2481 41.2673 22.9732 41.6432 24.6983C41.6728 24.8338 41.7569 24.9642 41.8422 25.0784C43.929 27.8724 46.0194 30.6639 48.1111 33.4555C48.1346 33.4868 48.1779 33.5031 48.1136 33.4592L48.1124 33.458Z" className={color ? color : "black"}/>
		</svg>

	)
}