import React from "react";

export default function IconCaret({className, color}){
    return(
        <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.25755 1.25773C1.6676 0.847683 2.33242 0.847683 2.74247 1.25773L9.00001 7.51527L15.2576 1.25773C15.6676 0.847683 16.3324 0.847683 16.7425 1.25773C17.1525 1.66778 17.1525 2.33261 16.7425 2.74266L9.00001 10.4851L1.25755 2.74266C0.8475 2.33261 0.8475 1.66778 1.25755 1.25773Z" className={color ? color : "white"} />
        </svg>


    )
}
