import React, {useEffect, useState} from "react";
import Input from "../UI/Input";
import Button from "../UI/Button";
import {Link, useHistory} from "react-router-dom";
import AuthMethods from "./AuthMethods";
import Error from "../UI/Error";
import {useRecoilState, useSetRecoilState} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {getMe, loginPX6} from "../../Actions/UserApi";


export default function Login(){
    const setUser=useSetRecoilState(userAtom);
    let history = useHistory();

    const [newUser, setNewUser] = useState(
        {
            "identifier": "",
            "password": ""
        }
    );
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const loginUser = async () => {
        setError("");
        try {
            const u = await loginPX6(newUser);
            window.localStorage.setItem('jwt', u.jwt);
            const me = await getMe();
            setUser(me);
            history.push("/dashboard");
        }
        catch (e){
            console.log("CATCHED", e.response);
            setError(e?.response?.data?.error?.message)
        }
    }


    return(
        <div className="max-w-600px mhor-auto pb-4">
            <Input label="Email" className="mb" name="identifier" value={newUser.identifier} onChange={updateUser} />
            <Input label="Password" type="password" className="mb" name="password" value={newUser.password} onChange={updateUser} />
            <Button className="w-100" onClick={loginUser}>
                LOGIN
            </Button>
            <div className="center f-titillium f-1rem mt-2">
                <Link to="/forgot-password" className="t-p3 f-underline">Forgot Password?</Link>
            </div>
            <Error msg={error} className="t-red center pver f-small" />
            <p className="mver center f-1rem f-uppercase">
                Or
            </p>

            <AuthMethods />

            <div className="center f-titillium f-1rem mt-2">
                Don't have an account? <Link to="/signup" className="t-p3 f-underline">Signup</Link>
            </div>
        </div>
    )
}
