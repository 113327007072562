import React from "react";
import ModuleHead from "../../Head/ModuleHead";
import ModuleBox from "../../Box/ModuleBox";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../../../Recoil/atoms";
import SearchPrimary from "./SearchPrimary";
import SearchResults from "../../../Header/SearchResults";
import SearchInput from "./SearchInput";
import globe from "../../../../Styles/Img/search-globe.jpg"

export default function Search (){

    const modules = useRecoilValue(modulesAtom);
    const result = modules.filter(m => m.name === "Search");

    if (result[0].position === 'primary') {
        return(
            <ModuleBox title="Search">
                <ModuleHead title="Search" />
                <SearchPrimary />
            </ModuleBox>
        )
    }

    if (result[0].position === 'secondary') {
        return(
            <ModuleBox title="Search">
                <ModuleHead title="Search" />
                <SearchInput />
                <SearchResults />
            </ModuleBox>
        )
    }

    if (result[0].position === 'tertiary') {
        return(
            <ModuleBox title="Search">
                <ModuleHead title="Search" />
                <div className="flex col-gap grows">
                    <div className="flex direction-column min-w-200px">
                        <SearchInput />
                        <div className="mt b b-grey200 grey100 phor pver br-half mb grows">
                            <SearchResults padding="pver" titleFont="f-1rem" />
                        </div>
                    </div>
                    <div className="grows br-half">
                        <img src={globe} alt="Search Results" />
                    </div>
                </div>

            </ModuleBox>
        )
    }

    else{
        return(
            <ModuleBox title="Search">
                <ModuleHead title="Search" />
                {result[0].position}
            </ModuleBox>


        )
    }


}
