import React from "react";

export default function IconPrimary({className, color}){
    return(
        <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4286 1.35714H2.71429C1.96476 1.35714 1.35714 1.96476 1.35714 2.71429V16.2857C1.35714 17.0352 1.96476 17.6429 2.71428 17.6429H24.4286C25.1781 17.6429 25.7857 17.0352 25.7857 16.2857V2.71429C25.7857 1.96475 25.1781 1.35714 24.4286 1.35714ZM2.71429 0C1.21523 0 0 1.21523 0 2.71429V16.2857C0 17.7848 1.21522 19 2.71428 19H24.4286C25.9276 19 27.1429 17.7848 27.1429 16.2857V2.71429C27.1429 1.21523 25.9276 0 24.4286 0H2.71429Z" className={color ? color : 'grey800'} />
            <path d="M2.71436 4.0715C2.71436 3.32197 3.32197 2.71436 4.0715 2.71436H10.8572C11.6067 2.71436 12.2144 3.32197 12.2144 4.0715V10.8572C12.2144 11.6067 11.6067 12.2144 10.8572 12.2144H4.0715C3.32197 12.2144 2.71436 11.6067 2.71436 10.8572V4.0715Z"  className={color ? color : 'grey800'} />
        </svg>
    )
}
