import React from "react";
import IconPrimary from "../../UI/Icons/IconPrimary";
import IconSecondary from "../../UI/Icons/IconSecondary";
import IconTertiary from "../../UI/Icons/IconTertiary";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../../Recoil/atoms";
import IconQuaternary from "../../UI/Icons/IconQuaternary";

export default function PositionIcon({name, title}){

    const modules = useRecoilValue(modulesAtom);

    let n = name ? name : title;
    const result = modules.filter(m => m.name === n);

    switch (result[0].position) {
        case 'primary' :
            return <IconPrimary />
        case 'secondary' :
            return <IconSecondary />
        case 'tertiary' :
            return <IconTertiary />
        case 'quaternary' :
            return <IconQuaternary />
    }


}
