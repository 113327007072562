import React from "react";

export default function IconExpand({color, className}){
    return(
        <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M43.4509 44.9998H3.54667C3.51349 44.9726 3.47675 44.9501 3.43747 44.9329C1.82186 44.481 0.99761 43.4069 0.99761 41.7306C0.99761 28.9183 0.99761 16.1052 0.99761 3.29131C0.990799 3.05587 1.00376 2.82027 1.03636 2.58699C1.3158 1.00111 2.551 0.000976562 4.25115 0.000976562H42.7312C42.8333 0.000976562 42.9355 0.000976562 43.0376 0.000976562C44.3292 0.0866685 45.2379 0.714682 45.764 1.89441C45.8579 2.10453 45.9213 2.32874 45.9988 2.54591V42.4513C45.724 42.9749 45.5033 43.536 45.1628 44.0138C44.7589 44.5819 44.092 44.7862 43.4509 44.9998ZM20.6891 42.1872H42.5867C43.1891 42.1872 43.1891 42.1872 43.1891 41.5686V3.43335C43.1891 2.81472 43.1891 2.81355 42.5891 2.81355H4.40731C3.81437 2.81355 3.8132 2.81355 3.8132 3.40048C3.8132 10.547 3.8132 17.6934 3.8132 24.8399V25.3177H17.3639C19.4175 25.3177 20.6867 26.5796 20.6867 28.6209C20.6867 32.9697 20.6867 37.3192 20.6867 41.6696L20.6891 42.1872ZM10.8874 42.1872H17.3029C17.8676 42.1872 17.877 42.179 17.877 41.6285C17.877 37.3075 17.877 32.9865 17.8852 28.6667C17.8852 28.2382 17.7479 28.1161 17.3287 28.1173C13.0079 28.1298 8.68743 28.1298 4.36739 28.1173C3.95292 28.1173 3.80498 28.2347 3.80615 28.662C3.82024 32.9982 3.82024 37.3337 3.80615 41.6684C3.80615 42.0757 3.93883 42.1978 4.33921 42.1954C6.52076 42.179 8.70348 42.1872 10.885 42.1872H10.8874Z" className={color ? color : "black"} />
            <path d="M33.4379 10.5472H32.9283C31.4348 10.5472 29.9413 10.5554 28.4478 10.5472C28.2605 10.5542 28.0736 10.5236 27.8984 10.4571C27.7231 10.3907 27.5629 10.2898 27.4272 10.1605C27.2916 10.0311 27.1832 9.87589 27.1086 9.70397C27.0339 9.53205 26.9945 9.34692 26.9926 9.15951C26.9907 8.97211 27.0264 8.78623 27.0976 8.61284C27.1688 8.43946 27.274 8.28209 27.407 8.15004C27.54 8.01798 27.6982 7.91391 27.8721 7.84398C28.046 7.77404 28.2322 7.73966 28.4196 7.74286C31.1726 7.73425 33.9259 7.73778 36.6797 7.75343C36.986 7.74873 37.2894 7.8146 37.5662 7.94594C38.0581 8.20419 38.2706 8.66669 38.2706 9.22428C38.2706 11.9782 38.2706 14.7309 38.2706 17.4847C38.2706 18.3499 37.6836 18.9767 36.8852 18.9873C36.0703 18.9978 35.4645 18.3628 35.4609 17.4777C35.4609 16.0139 35.4609 14.543 35.4609 13.0839V12.5721C35.286 12.733 35.1697 12.8327 35.0606 12.9407C31.5851 16.4067 28.1136 19.8755 24.6459 23.347C24.2103 23.7837 23.7301 24.0255 23.109 23.8436C22.8855 23.7808 22.6812 23.6632 22.5147 23.5014C22.3482 23.3396 22.2247 23.1388 22.1556 22.9173C22.0864 22.6957 22.0737 22.4603 22.1186 22.2326C22.1635 22.0049 22.2646 21.792 22.4127 21.6132C22.5181 21.4901 22.6298 21.3726 22.7474 21.2611L33.061 10.9452C33.1702 10.8442 33.2712 10.728 33.4379 10.5472Z" className={color ? color : "black"} />
        </svg>

    )
}
