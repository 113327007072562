import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import IconX from "../../UI/Icons/IconX";
import {quat} from "../../../Recoil/selectors";
import Input from "../../UI/Input";
import UsersList from "../EnterData/UsersList";
import UserCircle from "../EnterData/UserCircle";
import {
	deleteAppointments,
	editAppointments,
	getAppointment,
	getAppointments,
	saveAppointments
} from "../../../Actions/DashboardApi";
import Button from "../../UI/Button";
import {appointmentEdit, deptAppointments, preDate} from "../../../Recoil/roomAtoms";
import Error from "../../UI/Error";
import {userAtom, userModeAtom} from "../../../Recoil/atoms";

export default function AppointmentFormEdit() {

	const [appEdit, setAppEdit] = useRecoilState(appointmentEdit);
	const [quater, setQuater] = useRecoilState(quat);
	const [pro, setPro] = useState(false);
	const [us, setUs] = useState(false);
	const [entries, setEntries] = useRecoilState(deptAppointments);
	const preD = useRecoilValue(preDate);
	const [err, setErr] = useState("");
	const userType = useRecoilValue(userModeAtom);
	const user = useRecoilValue(userAtom);

	const [loading, setLoading] = useState(true);

	const [appointment, setAppointment] = useState({
		"subject": "",
		"user": userType === 'patient' ? user.id : '',
		"professional": "",
		"time": preD.time,
		"date": preD.date,
		"room": 3,
		"department": 1
	});

	const loadAppointment = async () => {
		const a = await getAppointment({}, appEdit.id);
		setLoading(false);
		console.log("Time", a.attributes?.time);
		setAppointment({
			...appointment,
			subject: a.attributes?.subject,
			professional: a.attributes?.professional?.data?.id,
			time: a.attributes?.time+".000",
			date: a.attributes?.date,
		});
	};

	useEffect(()=>{
		if(loading){
			loadAppointment();
		}
	},[loading]);

	useEffect(()=>{
		setLoading(true);
	},[appEdit]);

	useEffect(()=>{
		console.log(appointment);
	},[appointment]);





	const calTimes = [
		"08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00"
	]

	// useEffect(()=> {
	// 	setAppointment({...appointment, "time": preD.time, "date": preD.date});
	// },[preD])

	const changeText = (e)=> {
		setAppointment({...appointment, [e.currentTarget.name]: e.currentTarget.value})
	}
	
	const action = (type, user) => {
		setAppointment({...appointment, [type] : user.id});
		setPro(false);
		setUs(false);
	}

	const deleteApp = async () => {
	  	await deleteAppointments(appEdit.id);
		await getEntries();
		setQuater(false);
	}
	const save = async () => {
		if(!appointment.time){
			setErr("Please add a Time for the appointment");
			return
		}
		if(!appointment.date){
			setErr("Please select a Date for the appointment");
			return
		}
		if(!appointment.professional){
			setErr("Please choose a professional for the appointment");
			return
		}
		if(!appointment.user){
			setErr("Please choose a patient for the appointment");
			return
		}
		await editAppointments(appointment, appEdit.id);
		await getEntries();
	}

	const getEntries = async () => {
		const now = new Date;
		const date = now.toISOString().substring(0, 10);

		let filter = {"filters[date][$gte]" : date};
		if(userType==="patient"){
			console.log('UT', userType);
			filter["filters[user]"] = user.id;
		}

		const res = await getAppointments(filter);
		setEntries(res);
	}

	return (
		<div className="white p mhor-auto max-w-800px w-100 br">
			<div className="flex mb align-center p6  p-half br-quarter">
				<div className="grows flex align-center cursor-move">
					<h3 className="h6 grows t-white">Edit Appointment {appEdit.id}</h3>
					<a onClick={() => setQuater(false)}>
						<IconX />
					</a>
				</div>
			</div>
			<div>
				<Input label="Subject" name="subject" className="mb" onChange={changeText} value={appointment.subject} />
				<div className="grid2 col-gap-2">
					<div>
						<label className="f-small mb-quarter block f-700">
							Time
						</label>
						<select className="f-400 h-3rem phor b b-grey500 br-quarter f-1rem w-100" onChange={changeText} name="time" value={appointment.time} >
							<option value=""> Select time</option>
							{calTimes.map((hour)=>{
								return(
									<option key={hour} value={`${hour}:00.000`}>
										{hour}
									</option>
								)
							})}
						</select>
					</div>

					<Input label="Date" name="date" type="date" onChange={changeText} value={appointment.date} />

					<div className="flex align-start mt-2">
						<div className="mr-3">
							<p className="f-bold mb-half">
								Professional
							</p>
							<div className="flex">
								{appointment.professional ?
									<UserCircle id={appointment.professional} action={()=>setPro(!pro)} />
									:
									<div className="cursor-pointer w-3rem h-3rem grey300 t-black br-50 flex align-center justify-center" onClick={()=>setPro(!pro)}>
										+
									</div>
								}

							</div>
							{pro &&
								<UsersList title="professional" action={action} />
							}
						</div>

						{userType === "patient" ?
							<div>
								<p className="f-bold mb-half">
									User
								</p>
								<b>
									{user.firstName} {user.lastName}
								</b>
							</div>
							:
							<div className="mr-3">
								<p className="f-bold mb-half">
									User
								</p>
								<div className="flex">
									{appointment.user ?
										<UserCircle id={appointment.user} action={() => setUs(!us)}/>
										:
										<div
											className="cursor-pointer w-3rem h-3rem grey300 t-black br-50 flex align-center justify-center"
											onClick={() => setUs(!us)}>
											+
										</div>
									}

								</div>
								{us &&
									<UsersList title="user" action={action}/>
								}
							</div>
						}

					</div>

				</div>

				<div className="pt-2 mt-2 b-grey200 b-top">
					<Button className="p5" onClick={save}>
						Save Appointment
					</Button>
					<Button className="red ml-2" onClick={deleteApp}>
						Delete Appointment
					</Button>
				</div>
				{err &&
					<Error msg={err} className="t-red pver-2" />
				}

			</div>
		</div>
	);
}


//
/*"data": {
	"subject": "string",
		"user": "string or id",
		"professional": "string or id",
		"time": "12:54",
		"date": "2022-12-15",
		"room": "string or id",
		"department": "string or id"
}*/
//