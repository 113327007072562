import React from "react";
import ModuleHead from "../Head/ModuleHead";
import ModuleComingSoon from "../ModuleComingSoon";
import ModuleBox from "../Box/ModuleBox";
import modu from "../../../Styles/Img/modules/ecommerce.jpg";
import {useRecoilValue} from "recoil";
import {modulesAtom} from "../../../Recoil/atoms";
import GenericPrimary from "./GenericPrimary";

export default function Ecommerce (){


    const modules = useRecoilValue(modulesAtom);
    const mod = modules.filter(m => m.name === "Ecommerce");

    return(
        <ModuleBox title="Ecommerce">
            <ModuleHead name="Ecommerce" title="E-commerce" />
            {mod.length > 0
            && mod[0].position !== 'tertiary'
            && mod[0].position !== 'quaternary'
            &&
                <GenericPrimary />
            // <img src={modu} alt="" />
            }

            <ModuleComingSoon name="Ecommerce" />
        </ModuleBox>
    )
}
