import React from "react";
import {useRecoilState} from "recoil";
import {appointmentsAtom, ordersAtom} from "../../Recoil/atoms";

export default function OrdersList({filter}){

    const [list, setList] = useRecoilState(ordersAtom);

    let filtered = [];

    if(filter){
        filtered = list.filter((a) => a.state === filter);
    }
    else {
        filtered = [...list];
    }


    return(

        <table className="f-small">
            <thead>
            <tr className="left f-1rem f-titillium f-700">
                <th>Pending Orders</th>
                <th className="pr">Date</th>
                <th className="right">Total</th>
            </tr>
            </thead>

            <tbody>
            {filtered.map(
                ({user, date, total})=>{
                    return(
                        <tr className="left f-small hover:grey200" key={user}>
                            <td className="pver-half b-bottom b-grey200 pr">
                                {user}
                            </td>
                            <td className="pver-half b-bottom b-grey200 pr">
                                <p>
                                    {date}
                                </p>
                            </td>
                            <td className="pver-half b-bottom b-grey200 right">
                                <strong>{total}</strong>
                            </td>
                        </tr>
                    )
                }
            )}

            </tbody>

        </table>

    )
}
