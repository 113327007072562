import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {encountersAtom, userModeAtom} from "../../Recoil/atoms";
import Button from "../UI/Button";

export default function EncountersList({one}){

    const [encounters, setEncounters] = useRecoilState(encountersAtom);
    const mode = useRecoilValue(userModeAtom);
    let enc = [];

    if(one){
        enc = [encounters[0]]
    }
    else{
        enc = [...encounters]
    }

    return(

        <table className="f-small">
            <thead>
            <tr className="left f-1rem f-titillium f-700">
                <th>Encounter</th>
                <th className="pr">ID</th>
                <th className="right">Actions</th>
            </tr>
            </thead>

            <tbody>
            {enc.map(
                ({user, id})=>{
                    return(
                        <tr className="left f-small hover:grey200" key={user}>
                            <td className="pver-half b-bottom b-grey200 pr">
                                {user}
                            </td>
                            <td className="pver-half b-bottom b-grey200 pr">
                                <p>
                                    {id}
                                </p>
                            </td>
                            <td className="pver-half b-bottom b-grey200 right">
                                <Button color={`${mode==='professional' ? 'p5' : 'p2'}`} height='h-2rem' fontSize='f-1rem' >
                                    Go to encounter
                                </Button>
                            </td>
                        </tr>
                    )
                }
            )}

            </tbody>

        </table>

    )
}
