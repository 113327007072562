import {useRecoilState, useRecoilValue} from "recoil";
import {noteRecipientAtom, roomAtom, userAtom, videoPopAtom} from "../../../Recoil/atoms";
import {useEffect, useState} from "react";

const Circle = ({color, children}) => {
	return(
		<div className={`b-3 b-${color ? color : 'p4'} t-${color ? color : 'p4'} w-10rem h-10rem br-50 flex align-center justify-center h3`}>
			{children}
		</div>
	)
}

export default function VideoConferenceView({load=true}){

	const user = useRecoilValue(userAtom);
	const [videoPop, setVideoPop] = useRecoilState(videoPopAtom);
	const [noteRecipient, setNoteRecipient] = useRecoilState(noteRecipientAtom);
	const [loading, setLoading] = useState(load);
	const room = useRecoilValue(roomAtom);

	function createFrameAndJoinRoom() {
		console.log("Target Room", room);
		let target;
		switch (room) {
			case 'Reception':
				target = "px6"
				break;
			case 'Waiting Room':
				target = "px6-waiting"
			case 'File Room':
				target = "px6-file"
				break;
			default:
				target = "px6"
		}

		window.callFrame = window.DailyIframe.createFrame({
			showLeaveButton: true,
			iframeStyle: {
				position: 'fixed',
				bottom: '10px',
				left: '10px',
				width: '40%',
				height: '50%',
				zIndex: 10000
			},
			userName: user?.firstName || ""
		});
		window.callFrame.join({ url: `https://px6.daily.co/${target}` });
		window.callFrame.on('left-meeting', (event) => {
			setVideoPop(!videoPop)
			window.callFrame.destroy();
		});
	}

	useEffect(() => {
		console.log("UU", user);
		if (loading && user?.firstName){
			createFrameAndJoinRoom();
			setLoading(false);
		}
	}, [loading, user]);


	return(
		<div>
		</div>
	)
}