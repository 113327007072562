import React, {useEffect, useState} from 'react'
import {getUsers} from "../../../Actions/DashboardApi";
import Input from "../../UI/Input";
import {useRecoilValue} from "recoil";
import {userAtom, userModeAtom} from "../../../Recoil/atoms";

export default function UsersList({action, title}){

	const [users, setUsers] = useState([]);
	const [keywords, setKeywords] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(()=> {
		if (loading){
			getData();
		}
	}, [loading]);

	const getData = async () => {
		let params = {};
		if (keywords) {
			params = {"filters[$or][0][firstName][$containsi]":keywords, "filters[$or][1][lastName][$containsi]":keywords}
		}
		const u = await getUsers(params);
		setUsers(u);
		setLoading(false);
	}

	const search = (e) => {
		setKeywords(e.currentTarget.value);
	}

	useEffect(()=>{
		if(!keywords || keywords.length > 3){
			setLoading(true);
		}
	},[keywords]);

	return(
		<div className="pver pr">
			<div className="f-bold">Select {title}:</div>
			<Input type="text" placeholder="Search name..." className="mt-half" value={keywords} onChange={search} />
			<div className="b-grey200 b">
				{users?.length > 0 && users.map(({id, firstName, lastName})=>{
					return(
						<div key={`${id}${firstName}`} onClick={() => action(title, {id, firstName, lastName})} className="p-half b-bottom b-grey200 hover:grey100 cursor-pointer">
							{firstName} {lastName}
						</div>
					)
				})}
			</div>
		</div>
	)
}