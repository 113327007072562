import React from "react";

export default function IconDirectory({className, color}){
	return(
		<svg width="31" height="39" viewBox="0 0 31 39" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.62024 8.12744C1.62024 7.09794 1.60275 6.12792 1.62642 5.15789C1.64392 4.44626 1.62024 3.7131 1.77979 3.02814C2.19565 1.24292 3.821 0.00526387 5.65531 0.00423847C12.7599 -0.00088852 19.8635 -0.00191392 26.9681 0.00423847C29.1513 0.00628927 30.9414 1.75972 30.9846 3.93459C31.0103 5.18763 30.9949 6.44169 30.9949 7.69575C30.9949 16.7028 30.9908 25.71 30.999 34.717C31.0011 36.369 30.3382 37.6292 28.9125 38.4751C28.3175 38.8279 27.6608 38.996 26.967 38.996C19.8624 38.9981 12.7589 39.0032 5.65428 38.993C3.44735 38.9899 1.64907 37.1842 1.62539 34.9806C1.61098 33.6506 1.62024 32.3197 1.62333 30.9887C1.62436 30.4309 1.94758 30.0679 2.43137 30.0607C2.91517 30.0535 3.2528 30.4083 3.25692 30.9661C3.26618 32.2458 3.25177 33.5245 3.26309 34.8042C3.27339 36.0347 3.93011 36.9483 5.03049 37.2559C5.31048 37.3339 5.61208 37.3667 5.90338 37.3677C12.8402 37.3739 19.778 37.3739 26.7148 37.3708C28.3392 37.3708 29.3685 36.3454 29.3695 34.7365C29.3706 24.5779 29.3706 14.4193 29.3695 4.26067C29.3695 2.64772 28.3392 1.61924 26.7231 1.61822C19.7863 1.61616 12.8484 1.61616 5.91162 1.61822C4.30068 1.61822 3.27442 2.62208 3.26206 4.21452C3.2528 5.39168 3.26824 6.56884 3.25383 7.746C3.25074 8.02695 3.32485 8.14795 3.62028 8.11719C3.79733 8.09873 3.98055 8.11104 4.15863 8.12949C4.58993 8.17564 4.88741 8.50889 4.88638 8.92828C4.88638 9.34357 4.57964 9.72296 4.15863 9.73117C3.01605 9.7527 1.87244 9.75167 0.728825 9.73117C0.309878 9.72809 0.00416005 9.35587 4.26372e-05 8.93956C-0.00407478 8.49454 0.29032 8.16538 0.75147 8.13155C1.0294 8.11104 1.30938 8.12744 1.62024 8.12744V8.12744Z" className={color ? color : "black"}/>
			<path d="M13.5052 13.5178C11.5783 11.5336 12.0682 8.92192 13.5474 7.57045C15.112 6.14104 17.5341 6.14002 19.108 7.60224C20.5851 8.97422 21.037 11.549 19.11 13.527C19.2264 13.5854 19.3334 13.6388 19.4394 13.6931C22.0694 15.0425 23.492 17.1805 23.6526 20.1285C23.6896 20.8114 23.3736 21.119 22.686 21.119C18.4337 21.1211 14.1825 21.1211 9.93026 21.119C9.2406 21.119 8.91738 20.7848 8.96267 20.0865C9.15413 17.1446 10.5757 15.0066 13.2221 13.6808C13.2911 13.6459 13.3621 13.6182 13.4311 13.5834C13.4517 13.5731 13.4671 13.5516 13.5042 13.5167L13.5052 13.5178ZM21.9284 19.4856C21.6927 17.1538 19.3838 14.3371 15.6833 14.658C13.2242 14.8713 10.6014 17.4009 10.728 19.4856H21.9284ZM18.7405 10.5544C18.7384 9.20596 17.6473 8.12929 16.2896 8.13442C14.9607 8.13955 13.8737 9.22544 13.8706 10.5533C13.8675 11.9048 14.9484 12.9846 16.3071 12.9846C17.6689 12.9846 18.7436 11.913 18.7405 10.5544V10.5544Z" className={color ? color : "black"}/>
			<path d="M16.3009 29.2539C14.1558 29.2539 12.0116 29.257 9.86645 29.2519C9.19017 29.2498 8.78151 28.693 9.03268 28.128C9.18193 27.7917 9.45162 27.6317 9.82734 27.6348C10.7002 27.641 11.5741 27.6358 12.447 27.6358C15.8758 27.6358 19.3056 27.6348 22.7344 27.6369C23.4179 27.6369 23.8286 28.1721 23.5929 28.7392C23.4354 29.1175 23.1358 29.257 22.7354 29.256C21.0143 29.2508 19.2933 29.2539 17.5722 29.2539C17.1481 29.2539 16.725 29.2539 16.3009 29.2539V29.2539Z" className={color ? color : "black"}/>
			<path d="M1.62126 14.6074C1.62126 13.4989 1.61714 12.452 1.62332 11.4051C1.62641 10.9652 1.88272 10.6586 2.28108 10.5837C2.67017 10.5109 3.0603 10.7016 3.18794 11.0595C3.24661 11.2225 3.2569 11.4092 3.25793 11.5855C3.26514 12.4428 3.26102 13.299 3.26102 14.1562C3.26102 14.2936 3.26102 14.431 3.26102 14.6074C3.53689 14.6074 3.76643 14.5992 3.99495 14.6094C4.53021 14.634 4.87916 14.9581 4.88534 15.4236C4.89151 15.9055 4.53021 16.2542 3.97951 16.2593C2.95221 16.2695 1.92492 16.2695 0.897625 16.2593C0.359273 16.2542 0.0113517 15.9291 2.87785e-05 15.4605C-0.0112941 14.9673 0.330451 14.6351 0.886302 14.6104C1.11482 14.6002 1.34436 14.6084 1.62023 14.6084L1.62126 14.6074Z" className={color ? color : "black"}/>
			<path d="M1.62335 27.6366C1.62335 26.5302 1.61923 25.4822 1.62541 24.4353C1.62747 24.0026 1.88892 23.6693 2.26464 23.5863C2.63829 23.5042 3.049 23.695 3.18179 24.0508C3.24664 24.2241 3.25693 24.425 3.25796 24.6137C3.26517 25.471 3.26105 26.3272 3.26105 27.1844C3.26105 27.3218 3.26105 27.4592 3.26105 27.6376C3.56059 27.6376 3.81484 27.6315 4.06806 27.6386C4.54774 27.654 4.87816 27.976 4.88743 28.4272C4.89669 28.8907 4.55804 29.2444 4.06086 29.2485C2.9821 29.2588 1.90333 29.2577 0.824571 29.2485C0.325335 29.2444 -0.00508749 28.9009 5.9279e-05 28.4303C0.00520604 27.9657 0.342834 27.6479 0.849276 27.6366C1.09117 27.6315 1.33204 27.6366 1.62232 27.6366H1.62335Z" className={color ? color : "black"}/>
			<path d="M3.26096 21.1203C3.56977 21.1203 3.81269 21.1121 4.05562 21.1224C4.53427 21.1429 4.86881 21.4659 4.88528 21.9089C4.90175 22.3559 4.59294 22.7282 4.11327 22.7384C2.99745 22.762 1.8806 22.762 0.765809 22.7384C0.277895 22.7282 -0.0134117 22.3723 0.000999197 21.9089C0.0143808 21.4659 0.34995 21.1408 0.82654 21.1214C1.07976 21.1111 1.33298 21.1193 1.62223 21.1193C1.62223 20.137 1.62223 19.1946 1.62223 18.2523C1.62223 18.1118 1.61502 17.9703 1.62635 17.8309C1.6634 17.394 2.01544 17.0618 2.43645 17.0567C2.85334 17.0526 3.23317 17.3797 3.24346 17.8206C3.26714 18.7527 3.25684 19.6858 3.25993 20.6189C3.25993 20.7696 3.25993 20.9204 3.25993 21.1193L3.26096 21.1203Z" className={color ? color : "black"}/>
			<path d="M16.2905 32.4981C15.2632 32.4981 14.2359 32.5012 13.2086 32.4971C12.5796 32.494 12.2255 32.1864 12.2338 31.6685C12.243 31.1722 12.6054 30.8687 13.2045 30.8687C15.2971 30.8667 17.3898 30.8667 19.4835 30.8687C19.9272 30.8687 20.2349 31.081 20.3451 31.4368C20.4521 31.7813 20.3399 32.1751 20.0311 32.3443C19.851 32.4427 19.6194 32.4878 19.4104 32.4909C18.3708 32.5053 17.3301 32.4971 16.2905 32.4971V32.4981Z" className={color ? color : "black"}/>
		</svg>

	)
}