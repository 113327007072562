import React, {useState} from "react";
import Button from "../../../UI/Button";
import {useRecoilValue} from "recoil";
import {userModeAtom} from "../../../../Recoil/atoms";
import InfoManagementStates from "./InfoManagementStates";
import {getUsers} from "../../../../Actions/DashboardApi";

export default function InfoManagementSearch(){
    const mode = useRecoilValue(userModeAtom);
    const [query, setQuery] = useState("");
    const [users, setUsers] = useState([]);

    const searchUsers = async () => {
        console.log(query);
        const params = {"filters[$or][0][firstName][$containsi]":query, "filters[$or][1][lastName][$containsi]":query}
        const u = await getUsers(params);
        setUsers(u);
    }

    return(
        <div>
            <div className="flex align-center justify-center mver-2">
                <input className="f-400 h-3rem phor b b-grey500 br-quarter f-1rem w-100" placeholder="Search" onChange={(e)=>setQuery(e.target.value)} />
                <Button className="ml-half" color={`${mode === 'professional' ? 'p4' : 'p2'}`} onClick={searchUsers}>
                    Search
                </Button>
            </div>
            {users.length > 0 &&
                <div className="pt pb-2">
                    <div className="mb-2">
                        <span className="br-quarter f-bold">{users.length} Results</span>
                    </div>
                    {users.map(({id, firstName, lastName}) => {
                        return (
                            <div key={`${id}${firstName}`}
                                 className="p-half b-bottom b-grey200 hover:grey100 cursor-pointer">
                                {firstName} {lastName}
                            </div>
                        )
                    })}
                </div>
            }
            <InfoManagementStates simple={true} />

        </div>

    )
}
