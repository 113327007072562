import React, {useState, useEffect} from "react";
import Input from "../../UI/Input";
import Textarea from "../../UI/Textarea";
import Button from "../../UI/Button";
import IconTrash from "../../UI/Icons/IconTrash";
import {saveDataBlocks} from "../../../Actions/DashboardApi";

export default function NewDataBlocks({setLoading, setAddBlockForm, doc}) {

	const [sets, setSets] = useState({
		"set": "",
		"type": "",
		"point": "",
		"attributes": "",
		"unit": "",
		"interactiveEvent": "",
		"subSet": "",
		"doc": doc,
		"customFields" : [
			// {
			// 	field: "Additional",
			// 	value: ""
			// }
		]
	});

	const [fieldPop, setFieldPop] = useState(false);
	const [fieldName, setFieldName] = useState("");


	const removeBlock = (i) => {
		let s = [...sets];
		s.splice(i, 1);
		setSets(s);
	}

	const addField = async (i, field) => {
		let s = {...sets[i]};
		let f = [...s.dataBlocks];
		f.push({field, value: "" });
		s.dataBlocks = f;
		let ss = [...sets];
		ss[i] = s;
		setSets(ss);
	}

	const remove = () => {
		//removeBlock(index);
	}

	const newField = () => {
		//addField(index, fieldName);
		setFieldPop(false);
		setFieldName("");
	}
	const editField = (e) => {
		setSets({...sets, [e.target.name] : e.target.value});
	}

	const saveBlock = async () => {
		await saveDataBlocks(sets);
		setLoading(true);
		setAddBlockForm(false);
	}

	return (
		<div className="p-2 grey200 br-quarter b b-grey300">
			<div className="grid3 col-gap-2 mb-2 relative">

				<div className="col-span2">
					<div className="grid3 col-gap ">
						<Input label="Data Set" className="mb" value={sets?.set} onChange={editField} name="set" />
						<Input label="Data Type" className="mb" value={sets?.type} onChange={editField} name="type" />
						<Input label="Data Point" className="mb" value={sets?.point} onChange={editField} name="point" />
					</div>

					<Textarea label="Data Attributes" className="mb" value={sets?.attributes} onChange={editField} name="attributes" />
					<div className="grid3 col-gap">
						<Input label="Unit of observation" className="mb" value={sets?.unit} onChange={editField} name="unit" />
						<Input label="Interactive event" className="mb" value={sets?.interactiveEvent} onChange={editField} name="interactiveEvent" />
						<Input label="Sub Set" className="mb" value={sets?.subSet} onChange={editField} name="subSet" />
					</div>

					{sets?.customFields.length > 0 &&
						<div className="pver">
							{sets?.customFields.map(({field, value}, ind)=>{
								return(
									<Input label={field} name={ind} value="" className="mb" />
								)
							})}
						</div>
					}

					<a className="block grey100 p br-quarter f-bold f-grey600 center" onClick={()=>setFieldPop(true)}>
						+ Add custom field to the block
					</a>

				</div>

				<div className="grey100 p relative">
					<Button color="p4" className="w-100">
						Upload Files
					</Button>

					<a className="absolute bottom-1 right-1" onClick={()=>setAddBlockForm(false)}>
						<IconTrash color="grey300 hover:red border-0" />
					</a>

				</div>



				{fieldPop &&
					<div className="absolute edges br flex align-center justify-center">
						<div className="absolute black80 edges br-half" onClick={()=> setFieldPop(false)}>
						</div>
						<div className="white max-w-400px p br-half relative">
							<div className="p5 t-white p-half br-quarter mb">
								<h6>Add a field to this block</h6>
							</div>
							<div className="flex align-end">
								<Input className="mr-half" label="Field Name" onChange={(e)=>setFieldName(e.target.value)} />
								<Button color="p4" onClick={newField}>Save</Button>
							</div>
						</div>
					</div>
				}

			</div>
			<p className="w-100">
				<Button className="p5" onClick={saveBlock}>
					Save Block
				</Button>
			</p>
		</div>
	)
}