import React, {useEffect, useState} from 'react'
import {getAppointments} from "../../../Actions/DashboardApi";
import {useRecoilState, useRecoilValue} from "recoil";
import {deptAppointments} from "../../../Recoil/roomAtoms";
import AppointmentsRow from "./AppointmentsRow";
import {patientRoomAtom, roomAtom, userAtom, userModeAtom} from "../../../Recoil/atoms";
import {useHistory} from "react-router-dom";

export default function Appointments({actions, roomFilter}) {

	const [entries, setEntries] = useRecoilState(deptAppointments);
	const [patientRoom, setPatientRoom] = useRecoilState(patientRoomAtom);
	const [loading, setLoading] = useState(true);
	const [filtered, setFiltered] = useState([]);
	const room = useRecoilValue(roomAtom);
	const userType = useRecoilValue(userModeAtom);
	const [user, setUser] = useRecoilState(userAtom);
	let history = useHistory();

	useEffect(() => {
		if (loading) {
			getEntries();
		}
	}, [loading]);

	useEffect(()=> {
		setLoading(true);
	}, [userType]);

	const getEntries = async () => {
		const now = new Date;
		const date = now.toISOString().substring(0, 10);
		let filter = {"filters[date][$gte]" : date};
		console.log("User type", userType);
		if(userType==="patient"){
			console.log("Filtering");
			filter["filters[user]"] = user.id;
		}

		try{
			console.log("Getting filter", filter);
			const res = await getAppointments(filter);
			console.log(res.length, res);
			if(userType==="patient"){
				setPatientRoom(res[0]?.attributes?.room?.data?.attributes?.title);
			}
			setEntries(res);
			setLoading(false);
		}
		catch (e){
			console.log("App Error", e.message);
			if(e.message === "Request failed with status code 401"){
				setUser(null);
				window.localStorage.clear();
				window.location="/login";
			}
		}

	}

	useEffect(()=>{
		console.log("Entires", entries);
	},[entries]);

	const filterEntries = () => {
		let r = 0;
		if(room === "File Room"){
			r = 3;
		}
		else if(room === "Waiting Room"){
			r = 2;
		}
		else if(room === "Reception"){
			r = 1;
		}
		let list = [...entries];
		let listroom = list.filter((e) => e?.attributes?.room?.data?.id === r);
		setFiltered(listroom);
	}

	useEffect(() => {
		if (entries.length > 0 && roomFilter ){
			filterEntries();
		}
		else if(entries.length > 0){
			setFiltered(entries);
		}
		else{
			setFiltered(entries);
		}
	}, [entries])

	useEffect(() => {
		if (entries.length > 0 && roomFilter ){
			filterEntries();
		}
		else if(entries.length > 0){
			setFiltered(entries);
		}
	}, [room])

	// Render
	if (loading) {
		return <div>Loading...</div>
	}
	return (
		<div>
			{filtered.length > 0 ?
			<table className="f-small">
				<thead>
					<tr className="left">
						<th>User</th>
						<th className="pr">Professional</th>
						<th>Date</th>
						{actions &&
							<th className="right">Actions</th>
						}
					</tr>
				</thead>

				<tbody>
					{filtered?.map(({id, attributes}) => {
						return(
							<AppointmentsRow key={id} id={id} actions={actions} attributes={attributes} />
						)
					})}

				</tbody>
			</table>
				:
				<div className="p-2">
					<h5 className="center">{userType==="patient" ? "You dont have any appointments yet" : "There are no users in this room"}</h5>
				</div>
			}
		</div>
	)
}