import React, {useEffect, useState} from 'react'
import {getUserTypes} from "../../Actions/UserApi";

export default function UserTypes({typeId, setTypeId}) {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState([]);

	useEffect(()=> {
		if(loading) {
			getEntries();
			setLoading(false);
		}
	}, [loading]);

	const getEntries = async () => {
		let t = await getUserTypes({});
		setEntries(t.data);
		console.log(t);
	}

	return(
		<>
			{entries?.map(({attributes,id})=>
				<div
					key={id}
					className={`${id===typeId ? "grey100" : ""} f-titillium f-bold f-large br-quarter b b-grey300 mb-half p flex align-center cursor-pointer hover:grey100`}
					onClick={()=>{setTypeId(id)}}
				>
					<div className="p-quarter b b-grey300 mr br-50 white">
						<div className={`${id===typeId ? "p3" : "grey300"} w-10px h-10px  br-50`}></div>
					</div>
					{attributes.title}
				</div>
			)}
		</>
	)
}