import React from "react";
import IconReception from "../../UI/Icons/IconReception";
import IconWaitingRoom from "../../UI/Icons/IconWaitingRoom";
import IconFileRoom from "../../UI/Icons/IconFileRoom";
import IconTarget from "../../UI/Icons/IconTarget";
import IconDirectory from "../../UI/Icons/IconDirectory";
import IconNotice from "../../UI/Icons/IconNotice";

export default function GenericPrimary(){

    return(
        <div>
            <div className="grid3 center col-gap row-gap pb">
                <a className="f-1rem f-titillium center">
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconReception className="h-3rem block b-0" color="p3" />
                    </div>
                    Reception
                </a>
                <a className="f-1rem f-titillium center mhor lh-100">
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconDirectory className="h-3rem block b-0" color="p2" />
                    </div>
                    Directory
                </a>
                <a className="f-1rem f-titillium center mhor lh-100">
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconWaitingRoom className="h-3rem block b-0" color="p3" />
                    </div>
                    Waiting Room
                </a>

            </div>

            <div className="grid2 center col-gap row-gap pb">
                <a className="f-1rem f-titillium center">
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconNotice className="h-3rem block b-0" color="p3" />
                    </div>
                    Notice Board
                </a>
                <a className="f-1rem f-titillium center">
                    <div className="p br-half flex justify-center min-w-50px">
                        <IconTarget className="h-3rem block b-0" color="p2" />
                    </div>
                    Ads
                </a>
            </div>
        </div>
    )
}
