import React, {useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";
import CountryState from "./CountryState";
import Input from "../UI/Input";

export default function P4({back, next, title, subtitle}){


    return(
        <div className="pb-2">
            <AuthHeader back={back ? back : "/onboarding/p/3"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "Healthcare Professionals"}
                    subtitle={subtitle ? subtitle : "Step 4/5"}
                />

                <CountryState />
                <Input label="Address 1" className="mb" />
                <Input label="Address 2" className="mb" />

                <Button type="Link" to={next ? next : "/onboarding/p/5"} className="w-100">
                    Continue
                </Button>

            </div>
        </div>
    )
}
