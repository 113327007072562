import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route, useHistory
} from "react-router-dom";
import {client} from "../Actions/Graph";
import Encounters from "../Containers/Encounters";

import DailyIframe from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react';


import '../Styles/index'


/**
 * Containers
 */
import Dashboard from "../Containers/Dashboard";
import Auth from "../Containers/Auth";
import Onboarding from "../Containers/Onboarding";
import Home from "../Containers/Home";
import Resizable from "../Components/Tests/Resizable";

/**
 * Components
 */
import Header from "../Components/Header/Header";
import CheckSession from "../Components/Auth/CheckSession";
import ResetPassword from "../Components/Auth/ResetPassword";

export default function App() {

    const co = DailyIframe.createCallObject();

    return (
        <DailyProvider callObject={co}>
            <div className="flex direction-column grows">
                <Router>
                    <CheckSession>
                        <Switch>
                            <Route exact path="/reset-password/:token" component={ResetPassword} />
                            <Route exact path="/login" component={Auth} />
                            <Route exact path="/signup" component={Auth} />
                            <Route exact path="/forgot-password" component={Auth} />
                            <Route path="/onboarding" component={Onboarding} />

                            <Route path="/resizable" component={Resizable} />

                            <Route path="/">
                                <Header />
                                <div className="grey100">
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/dashboard" component={Dashboard} />
                                    <Route path="/encounters" component={Encounters} />
                                </div>

                            </Route>

                        </Switch>
                    </CheckSession>
                </Router>
            </div>
        </DailyProvider>
    );
}
