import React from "react";
import {Link} from "react-router-dom";

export default function Tabs({tabs, location, className}){


    return(
        <div className={`${className} b-bottom b-grey300 grid${tabs.length} center f-titillium f-uppercase f-large`}>
            {tabs.map(({label, to})=>{
                return(
                    <Link key={to} to={to} className={`phor-2 pver br-0 white b-bottom-5  ${location === to ? "t-black b-p1" : "t-grey500 b-white"}`}>
                        {label}
                    </Link>
                )
            })}
        </div>
    )
}
