import React, {useEffect, useState} from "react";
import AuthHeader from "../Auth/AuthHeader";
import AuthTitle from "../Auth/AuthTitle";
import Button from "../UI/Button";

import FormAutoComplete from "../DataModules/FormAutoComplete";
import {query} from "../../Actions/Graph/queries";
import {refreshJWT} from "../../Actions/Rest/auth";
import useAutoCompleteAdd from "../../Hooks/useAutoCompleteAdd";

export default function P1({back, next, title, subtitle,addData}){
    const [skillTypes, setSkillTypes] = useState([]);
    const [skillType, setSkillType] = useState("");
    const [skillCategory, setSkillCategory,AutoCompleteSkillCategory] = useAutoCompleteAdd("");
    const [skill, setSkill,AutoCompleteSkill] = useAutoCompleteAdd("");
    const [roleCategory, setRoleCategory,AutoCompleteRoleCategory] = useAutoCompleteAdd("");
    const [role, setRole,AutoCompleteRole] = useAutoCompleteAdd("");

    const [data,updateData] = useAutoCompleteAdd();


    useEffect(()=>{
        setSkillCategory('');
    },[skillType]);

    useEffect(()=>{
        setSkill('');
    },[skillCategory]);

    useEffect(()=>{
        setRoleCategory('');
    },[skill]);

    useEffect(()=>{
        setRole('');
    },[roleCategory]);

    // Queries


    let getSkillCategories = {
        query: 'searchSkillCategories',
        variables:{
            skillTypes:[skillType],
        },
    }
    const getSkills = {
        query: 'searchSkills',
        variables:{
            skillCategories:[skillCategory?.id],
        }
    }

    const getRoleCategories = {
        query: 'searchRoleCategories',
        variables:{
            skills:[skill?.id],
        },
    }

    const getRoles = {
        query: 'getRoles',
    }

    //Mutations

    const addSkillCategory ={
        operation: 'createSkillCategory',
        arguments:{
            field:'skillTypes',
        }
    }
    const addSkill ={
        operation: 'createSkill',
        arguments:{
            field:'skillCategories',
        }
    }
    const addRoleCategory ={
        operation: 'createRoleCategory',
        arguments:{
            field:'skills',
        }
    }
    const addRole ={
        operation: 'createRole',
            arguments:{
            field:'roleCategories',
        }
    }

    const handleContinue =()=>{
        addData(`${next}` , {
            organizationRoles:role?[role.id]:null
        });
    }

return(
        <div className="pb-2">

            <AuthHeader back={back ? back : "/onboarding/user-type"} />

            <div className="max-w-600px mhor-auto mb">
                <AuthTitle
                    title={title ? title : "Healthcare Professionals"}
                    subtitle={subtitle ? subtitle : "Step 1/5"}
                />

                <div className="pver-half b-bottom b-grey300 f-1rem mb f-titillium f-uppercase">
                    What SKILLS describe you better?
                </div>

                <div className="grid2 col-gap-half">

                    {skillTypes?.map(({label,id})=>{
                        return(
                            <div
                                key ={id}
                                className={`${id===skillType ? "grey100" : ""} f-titillium f-bold f-medium br-quarter b b-grey300 mb-half p flex align-center cursor-pointer hover:grey100`}
                                onClick={()=>{setSkillType(id)}}
                            >
                                <div className="p-quarter b b-grey300 mr br-50 white">
                                    <div className={`${id===skillType ? "p3" : "grey300"} w-10px h-10px  br-50`}></div>
                                </div>
                                {label}
                            </div>
                        )
                    })}
                </div>

                {/*skill Category Input*/}
                { skillType &&
                    <AutoCompleteSkillCategory
                        label="Skill Category" placeholder="Enter category" buttonLabel=' Skill Category not listed?'
                        request ={getSkillCategories}
                    >
                        { setPop => <FormAutoComplete
                            label='Skill Category'
                            owner={{
                                label: 'Skill Type',
                                request: {
                                    query: 'getSkillTypes',
                                }
                            }}
                            mutation={addSkillCategory}
                            setPop={setPop}
                        />}
                    </AutoCompleteSkillCategory>
                }

                {/*Skill Input*/}
                { skillCategory &&
                    <AutoCompleteSkill
                        label="Skill" placeholder="Enter skill" buttonLabel='Skill not listed?'
                        request ={getSkills}
                    >
                        { setPop => <FormAutoComplete
                            label='Skill'
                            owner={{
                                label:'Skill Category',
                                request:getSkillCategories
                            }}
                            mutation={addSkill}
                            setPop={setPop}
                        />}
                    </AutoCompleteSkill>
                }

                {/*role Category Input*/}
                { skill &&
                    <AutoCompleteRoleCategory
                        label="Role Category" placeholder="Enter Role Category"  buttonLabel='Role Category not listed?'
                        request ={getRoleCategories}
                    >
                        { setPop => <FormAutoComplete
                            label='Role Category'
                            owner={{
                                label:'Skill',
                                request:getSkills
                            }}
                            mutation={addRoleCategory}
                            setPop={setPop}
                        />}
                    </AutoCompleteRoleCategory>
                }

                {/*role Input*/}
                { roleCategory &&
                    <AutoCompleteRole
                        label="Role" placeholder="Enter role"  buttonLabel='Role not listed?'
                        request ={getRoles}
                    >
                        { setPop => <FormAutoComplete
                            label='Role'
                            owner={{
                            label:'Role Category',
                            request:getRoleCategories
                        }}
                            mutation={addRole}
                            setPop={setPop}
                        />}
                    </AutoCompleteRole>
                }

                <Button
                    type="button"
                    onClick={handleContinue}
                    className="w-100">
                    Continue
                </Button>

            </div>


        </div>
    )
}
