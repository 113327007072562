import React from "react";

export default function Popup({children, width, switchView, scroll}){




    return(
        <div className={`fixed edges flex grows  justify-center z-1000 ${scroll ? 'overflow-y-scroll' : 'align-center'}`}>
            <div className={`${scroll ? 'fixed' : 'absolute'} black80 edges`} onClick={switchView}>
            </div>
            <div className={`z-1000 relative max-w-${width ? width : '700'}px w-100`}>
                {children}
            </div>
        </div>
    )
}
