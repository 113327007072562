import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {encountersAtom, encountersInvolvedAtom, userModeAtom} from "../../Recoil/atoms";
import Button from "../UI/Button";

export default function InvolvedEncountersList(){

    const [encounters, setEncounters] = useRecoilState(encountersInvolvedAtom);
    const mode = useRecoilValue(userModeAtom);

    return(

        <table className="f-small">
            <thead>
            <tr className="left f-1rem f-titillium f-700">
                <th>User</th>
                <th className="pr">Department</th>
                <th className="pr">Speciality</th>
                <th className="right">Actions</th>
            </tr>
            </thead>

            <tbody>
            {encounters.map(
                ({user, department, speciality})=>{
                    return(
                        <tr className="left f-small hover:grey200" key={user}>
                            <td className="pver-half b-bottom b-grey200 pr">
                                {user}
                            </td>
                            <td className="pver-half b-bottom b-grey200 pr">
                                <p>
                                    {department}
                                </p>
                            </td>
                            <td className="pver-half b-bottom b-grey200 pr">
                                <p>
                                    {speciality}
                                </p>
                            </td>
                            <td className="pver-half b-bottom b-grey200 right">
                                <Button color={`${mode==='professional' ? 'p5' : 'p2'}`} height='h-2rem' fontSize='f-1rem' >
                                    Contact
                                </Button>
                            </td>
                        </tr>
                    )
                }
            )}

            </tbody>

        </table>

    )
}
