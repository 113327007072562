import {useRecoilState, useRecoilValue} from "recoil";
import {modulesAtom, notificationsAtom, searchResultsAtom} from "../../Recoil/atoms";
import IconPerson from "../UI/Icons/IconPerson";
import {secCountSelector, tertCountSelector} from "../../Recoil/selectors";


export default function NotificationsList({padding, titleFont, closeElement}){

    const results = useRecoilValue(notificationsAtom)


    return(
        <div className="t-black min-w-200px">
            {results.map(({id, title, type})=>{
                return(
                    <a key={id}
                         className="hover:grey100 block cursor-pointer"
                         onClick={closeElement}
                    >
                        <div className={`flex align-center ${padding ? padding : "pver"} b-bottom b-grey200`}>
                            <div>
                                <h4 className={`t-black ${titleFont ? titleFont : "h6"}`}>
                                    {title}
                                </h4>
                                <p className="f-uppercase t-p4 f-small">
                                    {type}
                                </p>
                            </div>
                        </div>

                    </a>
                )
            })}

        </div>
    )
}