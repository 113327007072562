import Select from "../../UI/Select";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import tools from "../../../Styles/Img/editor-tools.png"
import xmark from "../../../Styles/Img/xmark.png"
import Textarea from "../../UI/Textarea";
import {useRecoilState} from "recoil";
import {notePopAtom, noteRecipientAtom} from "../../../Recoil/atoms";
import {useState} from "react";


export default function NoteView(){

	const [notePop, setNotePop] = useRecoilState(notePopAtom);
	const [noteRecipient, setNoteRecipient] = useRecoilState(noteRecipientAtom);
	const [error, setError] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	const [org, setOrg] = useState({
		org : 'Mercy Hospital',
		fac : 'Miami',
		dep : 'Radiolgy'
	});

	const orgChange = (e) => {
	  	setOrg({...org, [e.target.name] : e.target.value});
	}

	const messageType = [
		{id: 'mt1', title: 'Urgent Notice'},
		{id: 'mt2', title: 'Video Message'},
		{id: 'mt3', title: 'Non Urgent email'},
		{id: 'mt4', title: 'Report'},
		{id: 'mt5', title: 'Memo'},
		{id: 'mt6', title: 'Voice Message'}
	]

	const saveMessage = () => {
		setError('')
		if(!subject){
			setError("Please add a subject to your message");
			return
		}
		if(!message){
			setError("Please add a message");
			return
		}

		setNotePop(false);

	}

	return(
		<div className='p-2'>
			<div className='flex mb align-center'>
				<h3 className='grows'>
					Compose Message
				</h3>
				<a onClick={()=>setNotePop(!notePop)}>
					<img src={xmark} className='h-2rem' />
				</a>
			</div>

			<div className='grid3 col-gap-3'>
				<div>
					<Input label='Subject*' className='mb' value={subject} onChange={(e)=>setSubject(e.target.value)} />
					<Input label='Recipients*' value={`<${noteRecipient}>`} className='mb' disabled />
					<Select options={messageType} label='Message Type' className='mb' />
					<Input label='Organization' className='mb' name='org' value={org.org} onChange={orgChange} />
					<Input label='Facility' className='mb' name='fac' value={org.fac} onChange={orgChange} />
					<Input label='Department' className='mb' name='dep' value={org.dep} onChange={orgChange} />
					<div className="p">
						&nbsp;
					</div>
				</div>
				<div className='col-span2 flex direction-column'>
					<div className='grey100 br  grows mt'>
						<div className='phor-2 pver grey300 flex'>
							<img src={tools} className='h-2rem' />
						</div>
						<div className='p-2'>
							<Textarea value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Write a message...'  height='300' />
						</div>

					</div>
					<div className='flex justify-end mt align-center'>
						{error &&
							<span className='t-red f-medium mr'>
								{error}
							</span>
						}
						<Button onClick={saveMessage}>
							Save Message
						</Button>
					</div>
				</div>


			</div>

		</div>
	)
}