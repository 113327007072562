import React, {useState} from "react";
import Select from "../../../UI/Select";
import AppointmentsList from "../../../DataModules/Appointments/AppointmentsList";
import Button from "../../../UI/Button";
import {useRecoilValue} from "recoil";
import {userModeAtom} from "../../../../Recoil/atoms";
import IconConference from "../../../UI/Icons/IconConference";
import Appointments from "../../../DataModules/Appointments/Appointments";

export default function InfoManagementSecondary(){

    const mode = useRecoilValue(userModeAtom);

    const [department] = useState([
        {title : "View All", id : 'dep0'},
        {title : "Department A", id : 'dep2'},
        {title : "Department B", id : 'dep3'},
        {title : "Department C", id : 'dep4'},
    ])

    const [dates] = useState([
        {title : "View All", id : 'date0'},
        {title : "Past", id : 'date2'},
        {title : "Current", id : 'date3'},
        {title : "Future", id : 'date4'},
    ])

    const [professional] = useState([
        {title : "View All", id : 'pro0'},
        {title : "Professional A", id : 'pro1'},
        {title : "Professional B", id : 'pro2'},
        {title : "Professional C", id : 'pro3'},
    ])

    return(
        <div>

            <h4 className={`h5 mb ${mode === 'professional' ? 't-p4' : 't-p2'} br-quarter`}>
                Appointments
            </h4>

            <div className="grid1 row-gap mb-2">
                <Select label="Date" options={dates} />
                {/*<Select label="Filter by department" options={department} />
                <Select label="Filter By professional" options={professional} />*/}
            </div>

            <Appointments />
            {/*<AppointmentsList icon={true} user={mode === 'patient' ? true : false} />*/}

            <div className='mt-2'>
                <Button color={`${mode === 'professional' ? 'p4' : 'p2'}`} className='w-100'>
                    Main Conference room
                </Button>
                <div className='center mt'>
                    <IconConference className='w-10rem h-10rem' />
                </div>
            </div>



        </div>
    )
}
