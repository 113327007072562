import React, {useEffect, useState} from "react";
import IconReception from "../../../UI/Icons/IconReception";
import IconWaitingRoom from "../../../UI/Icons/IconWaitingRoom";
import IconFileRoom from "../../../UI/Icons/IconFileRoom";
import OrdersList from "../../../DataModules/OrdersList";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    actionsFileRoomAtom, actionsInFileRoomAtom,
    appointmentsAtom,
    dragTableAtom, encounterPopAtom, patientRoomAtom,
    reportsFileRoomAtom,
    roomAtom, selectedPatients, userAtom, userModeAtom
} from "../../../../Recoil/atoms";
import Button from "../../../UI/Button";
import EncountersList from "../../../DataModules/EncountersList";
import RoomEncounter from "../../../DataModules/RoomEncounter";
import PatientCalendar from "../../../DataModules/PatientCalendar";
import InvolvedEncountersList from "../../../DataModules/InvolvedEncountersList";
import dm from '../../../../Styles/Img/dm.jpg'
import {deptAppointments, selectedPatient} from "../../../../Recoil/roomAtoms";
import {getAppointments, updateAppointments} from "../../../../Actions/DashboardApi";
import ProfessionalInRoom from "../../../DataModules/ProfessionalInRoom";
import OneOnOnePatient from "../../../DataModules/OneOnOnePatient";
import {quat, quatActive} from "../../../../Recoil/selectors";
import Popup from "../../../UI/Popup";

const {io} = require("socket.io-client")

export default function InfoManagementTertiary(){

    const [socket, setSocket] = useState(null);
    const [pop, setPop] = useState(true);

    const [patientRoom, setPatientRoom] = useRecoilState(patientRoomAtom);
    const actions = useRecoilValue(actionsFileRoomAtom);
    const actionsfr = useRecoilValue(actionsInFileRoomAtom);
    const reports = useRecoilValue(reportsFileRoomAtom);
    const [room, setRoom] = useRecoilState(roomAtom);
    const [dragTable, setDragTable] = useRecoilState(dragTableAtom);
    const [appointments, setAppointments] = useRecoilState(appointmentsAtom);
    const length = useRecoilValue(selectedPatients);
    const [selected, setSelected] = useRecoilState(selectedPatient);
    const [entries, setEntries] = useRecoilState(deptAppointments);
    const [quater, setQuater] = useRecoilState(quat);
    const [qActive, setQActive] = useRecoilState(quatActive);
    const userType = useRecoilValue(userModeAtom);
    const user = useRecoilValue(userAtom);
    const [encounterPop, setEncounterPop] = useRecoilState(encounterPopAtom);

    const mode = useRecoilValue(userModeAtom)


    const onDragOver = (event) => {
        event.preventDefault();
        const status = event.currentTarget.dataset.status;
        setDragTable({...dragTable, draggedToState : status});
    }

    const onDrop = (event) => {

        let ap = [...appointments];
        const index = appointments.findIndex((a)=>a.date === dragTable.draggedFromId);
        if(index > -1){
            console.log(ap[index]);
            ap[index] = {...ap[index], status : dragTable.draggedToState};
            setAppointments(ap);
        }

        let room = 0;
        if(dragTable.draggedToState === "Reception"){
            room = 1;
        }
        else if(dragTable.draggedToState === "Waiting Room"){
            room = 2;
        }
        else if(dragTable.draggedToState === "File Room"){
            room = 3;
        }

        saveAppointment(dragTable.draggedFromId, room);

    }

    const loadAppointments = async () => {

        console.log("Loading appointments after update", userType);
        const now = new Date;
        const date = now.toISOString().substring(0, 10);

        let filter = {"filters[date][$gte]" : date};
        if(userType==="patient"){
            console.log('UT', userType);
            filter["filters[user]"] = user.id;
        }

        const res = await getAppointments(filter);
        if(userType==="patient"){
            setPatientRoom(res[0]?.attributes?.room?.data?.attributes?.title);
        };
        console.log(res);
        setEntries(res);
        if(selected){
            const sel = res.find((e) => e.id === selected.id);
            setSelected(sel);
        }
    }

    const saveAppointment = async (id, room) => {
        await updateAppointments(id, room);

        console.log("Emitting");
        socket.emit('list update', "yes");

    }

    const quaternary = () => {
        setQActive("");
        setQuater(true);
    }

    const shareFile = () => {
        setQActive("shareFile");
        setQuater(true);
    }


    const url = "https://px6-socket-server.herokuapp.com";

    //socket
    useEffect(()=> {
        if(socket === null) {
            setSocket(io.connect(url));
        }
        if(socket){
            socket.on("connect", () => {
                console.log("SocketConnected YES: ", socket.connected, socket.id);
            });

            socket.on('list updated', function(msg) {
                console.log("Update received list", msg);
                window.callFrame?.destroy();
                loadAppointments();
            });
        }

        return () => {
            console.log("Should disconnect");
            socket?.disconnect();
        }
    }, [socket]);

    return(
        <div>

            <div className="b b-grey200 white p br-half mb">

                <h5 className="mb pb b-grey200 b-bottom">{room}</h5>

                <div className="f-1rem">
                    <p className="f-small t-grey500 center f-italic pt p-half mt">
                        Drag and Drop Users to move between rooms
                    </p>

                    <div className="flex justify-center">
                        <div onDrop={onDrop} onDragOver={onDragOver} data-status="Reception" className={`relative p br-half grows b cursor-pointer ${room === 'Reception' ? 'grey100 b-grey500' : 'white b-grey200'}`} onClick={()=>setRoom("Reception")}>
                            {patientRoom === 'Reception' && userType === "patient" && <span className="absolute top-1 right-1 red w-1rem h-1rem br-50" />}
                            <div className="f-1rem f-titillium center   ">
                                <div className="p br-half b-0">
                                    <IconReception color="p5" />
                                </div>
                                Reception
                            </div>
                        </div>

                        <div onDrop={onDrop} onDragOver={onDragOver} data-status="Waiting Room" className={`relative p br-half grows b mhor cursor-pointer ${room === 'Waiting Room' ? 'grey100 b-grey500' : 'white b-grey200'}`} onClick={()=>setRoom("Waiting Room")}>
                            {patientRoom === 'Waiting Room' && userType === "patient" && <span className="absolute top-1 right-1 red w-1rem h-1rem br-50" />}
                            <div className="f-1rem f-titillium center mhor lh-100">
                                <div className="p br-half b-0">
                                    <IconWaitingRoom color="p3" />
                                </div>
                                Waiting <br/> Room
                            </div>
                        </div>

                        <div onDrop={onDrop} onDragOver={onDragOver} data-status="File Room" className={`relative p br-half grows b cursor-pointer ${room === 'File Room' ? 'grey100 b-grey500' : 'white b-grey200'}`} onClick={()=>setRoom("File Room")}>
                            {patientRoom === 'File Room' && userType === "patient" && <span className="absolute top-1 right-1 red w-1rem h-1rem br-50" />}
                            <div className="f-1rem f-titillium center">
                                <div className="p br-half b-0">
                                    <IconFileRoom color="p2" />
                                </div>
                                File Room
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {room === 'File Room' && selected && selected?.attributes?.room?.data?.attributes?.title === "File Room" &&
                <ProfessionalInRoom />
            }


            <div className="b b-grey200 phor pver br-half mb ">
                {selected ?
                    <OneOnOnePatient/>
                    :
                    <RoomEncounter/>
                }
            </div>

            {mode==='patient' && room == 'Waiting Room' &&
                <img src={dm} className='w-100 mb-2' />
            }

            {room == 'Reception' &&
                <PatientCalendar />
            }

            <div className="grid3 col-gap-half row-gap-half mb">
                <Button fontSize="f-small grey400" className="lh-100 hover:op-80" onClick={quaternary}>
                    Enter Data
                </Button>
                {room == 'File Room' &&
                    actionsfr.map((a) => {
                        return (
                            <Button key={a} fontSize="f-small grey400" className="lh-100 hover:op-80" onClick={a === "FILE Sharing and Privileges" ? shareFile : null}>
                                {a}
                            </Button>
                        )
                    })
                }


                {mode === 'professional' && room == 'Waiting Room' &&
                    <Button fontSize="f-small grey400" className="lh-100 hover:op-80">
                        Post to waiting room
                    </Button>
                }

                {mode === 'professional' && room !== 'File Room' &&
                    actions.map((a)=> {
                        return(
                            <Button key={a} fontSize="f-small grey400" className="lh-100 hover:op-80">
                                {a}
                            </Button>
                        )
                    })
                }
            </div>

            {length === 1 &&
            <div className="b b-grey200 p br-half mb align-center justify-center">
                <h6 className="f-300 f-uppercase mb">
                    Encounters
                </h6>
                <EncountersList one={true} />
            </div>
            }

            {length > 1 &&
                <div className="b b-grey200 p br-half mb align-center justify-center">
                    <h6 className="f-300 f-uppercase mb">
                        Encounters
                    </h6>
                    <EncountersList />
                </div>
            }

            <div className='mb'>

                {mode === 'professional' && room === 'File Room' &&
                    <div className="b b-grey200 grey100 p-2 br-half mb-half flex align-center justify-center cursor-pointer" onClick={()=>setEncounterPop(true)}>
                        <h5 className="f-300">
                            Start Encounter
                        </h5>
                    </div>
                }

                <div className="b b-grey200 grey100 p-2 br-half mb-half flex align-center justify-center">
                    <h5 className="f-300">
                        Highlight Notice & Notes
                    </h5>
                </div>
                <div className="b b-grey200 p-2 p4 t-white br-half mb-half flex align-center justify-center">
                    <h5 className="f-300">
                        Completed / Pending Tasks
                    </h5>
                </div>
                <div className="b b-grey200 p-2 br-half flex align-center justify-center" style={{background : 'red'}}>
                    <h5 className="f-300">
                        Alert / Quality Score
                    </h5>
                </div>
            </div>

            {length === 1 &&
                <div className="b b-grey200 p br-half mb align-center justify-center">
                    <h6 className="f-300 f-uppercase mb">
                        PEOPLE INVOLVED IN THIS ENCOUNTER
                    </h6>
                    <InvolvedEncountersList />
                </div>
            }


            <div className="grid3 col-gap-half row-gap-half mb">
                {reports.map((a)=> {
                    return(
                        <Button key={a} fontSize="f-small grey700" className="lh-100 hover:op-80">
                            {a}
                        </Button>
                    )
                })}
            </div>

            <div className="b b-grey200 grey100 phor pver br-half mb ">

                <h6 className="f-300 f-uppercase mb">
                    Orders & tasks
                </h6>

                <OrdersList />

            </div>

        </div>
    )
}
